import { createAction, props } from '@ngrx/store';
import { PublicationDto } from '../../core/api/dto/publication.dto';
import { Section } from '../../models/section';

const FETCH_PUBLICATIONS = '[Publication] Fetch All';
const FETCH_PUBLICATIONS_SUCCESS = '[Publication] Fetch All Success';

const CREATE_PUBLICATION = '[Publication] Create';
const CREATE_PUBLICATION_SUCCESS = '[Publication] Create Success';

const MARK_PUBLICATION_INVALID = '[Publication] Mark As Invalid';
const MARK_PUBLICATION_INVALID_SUCCESS =
    '[Publication] Mark As Invalid Success';

export const fetchAllPublications = createAction(FETCH_PUBLICATIONS);
export const fetchAllPublicationsSuccess = createAction(
    FETCH_PUBLICATIONS_SUCCESS,
    props<{ data: PublicationDto[] }>()
);

export const createPublication = createAction(
    CREATE_PUBLICATION,
    props<{
        submissionId: string;
        templateId: string;
        dictionaries: unknown;
        publication: unknown;
        additionalNotes: string;
        inputForm: Section[];
    }>()
);
export const createPublicationSuccess = createAction(
    CREATE_PUBLICATION_SUCCESS,
    props<{
        id: string;
        submissionName: string;
        additionalNotes: string;
        isActive: boolean;
        inputForm: Section[];
        createdAt: Date;
    }>()
);

export const markPublicationAsInvalid = createAction(
    MARK_PUBLICATION_INVALID,
    props<{ publicationId: string }>()
);
export const markPublicationAsInvalidSuccess = createAction(
    MARK_PUBLICATION_INVALID_SUCCESS,
    props<{
        id: string;
        submissionName: string;
        additionalNotes: string;
        isActive: boolean;
        inputForm: Section[];
        createdAt: Date;
    }>()
);

<div class="flex gap10">
    <app-input
        [value]="model.label"
        [label]="Component.LabelInputLabel"
        (valueChanged)="handleModelChanged($event, 'label')"
        class="full-width"
    ></app-input>
    <app-select
        [label]="Component.ContactTypeInputLabel"
        [value]="model.contactType"
        [options]="contactTypeOptions"
        (valueChanged)="handleModelChanged($event, 'contactType')"
    ></app-select>
</div>
<app-input
    [value]="model.name"
    [label]="Component.NameInputLabel"
    (valueChanged)="handleModelChanged($event, 'name')"
></app-input>
<app-input
    [value]="model.phone"
    [label]="Component.PhoneInputLabel"
    (valueChanged)="handleModelChanged($event, 'phone')"
></app-input>
<app-input
    [value]="model.email"
    [label]="Component.EmailInputLabel"
    (valueChanged)="handleModelChanged($event, 'email')"
></app-input>
<div class="mt-4">
    <button
        mat-raised-button
        color="primary"
        class="full-width"
        (click)="handleSave()"
    >
        {{ Component.SaveButtonLabel }}
    </button>
</div>

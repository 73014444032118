import { Action, createReducer, on } from '@ngrx/store';
import { LoadDataSuccess, LoadTypesSuccess } from './prepopulated-data.action';
import { PrepopulatedDataSelectResponseDataEntity } from '../../core/allowed-values.service';

export interface PrepulatedData {
    [key: string]: Array<PrepopulatedDataSelectResponseDataEntity>;
}

export interface PrepopulatedDataType {
    type: string;
    component: string;
}

export interface PrepopulatedDataState {
    prepopulatedData: {
        dataTypes: Array<PrepopulatedDataType>;
        data: PrepulatedData;
    };
}

const initialState: PrepopulatedDataState = {
    prepopulatedData: {
        dataTypes: [],
        data: {},
    },
};

const createPrepopulatedDataReducer = createReducer(
    initialState,
    on(LoadTypesSuccess, (state, action) => {
        return {
            ...state,
            prepopulatedData: {
                ...state.prepopulatedData,
                dataTypes: action.types,
            },
        };
    }),
    on(LoadDataSuccess, (state, action) => {
        return {
            ...state,
            prepopulatedData: {
                ...state.prepopulatedData,
                data: {
                    ...state.prepopulatedData.data,
                    [action.key]: action.data,
                },
            },
        };
    })
);

export function prepopulatedDataReducer(
    state: PrepopulatedDataState | undefined,
    action: Action
) {
    return createPrepopulatedDataReducer(state, action);
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentElementsService } from '../../core/component-elements.service';
import { FormValidatorService } from '../form-validator.service';
import { EditComponentModel } from '../../models/db-schema.model';

@Component({
    selector: 'app-analyst-form-yes-no-button',
    templateUrl: './analyst-form-yes-no-button.component.html',
    styleUrls: ['./analyst-form-yes-no-button.component.scss'],
})
export class AnalystFormYesNoButtonComponent {
    @Input()
    public label: string;

    @Input()
    value: string;

    @Input()
    public readOnly: boolean;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    handleModelChange($event: any) {
        this.valueChanged.emit($event);
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }
}

import { Injectable } from '@angular/core';
import { PrepopulatedData } from '../store/form-api/form-api.reducer';
import { HttpService } from './http.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface PrepopulatedDataSelectResponseDataEntity {
    id: string;
    value: string;
    model: any;
}

interface PrepopulatedDataSelectResponse {
    data: Array<PrepopulatedDataSelectResponseDataEntity>;
}

interface PrepopulatedDataTypeResponse {
    dataTypes: Array<{ type: string; component: string }>;
}

interface UpdateAllowedValuesBody {
    type: string;
    value: string;
    sort: number;
    model: any;
}

export type ComponentType = 'choice' | 'select';

@Injectable({
    providedIn: 'root',
})
export class AllowedValuesService {
    constructor(private httpService: HttpService) {}

    public parseAllowedValues(
        prepopulatedData: PrepopulatedData,
        values: string
    ): Observable<string[]> {
        if (values && values[0] == '$') {
            const source = values.split('$')[1];
            const options = prepopulatedData.selectData.find(
                (select) => select.id == source
            );
            return of(options?.options ?? ['Invalid Source']);
        } else {
            return of(values.split(','));
        }
    }

    loadData(dataType: string, componentType: ComponentType) {
        return this.httpService
            .get<PrepopulatedDataSelectResponse>(
                `prepopulatedData/${componentType}?type=${dataType}`
            )
            .send()
            .pipe(map((response) => response.data));
    }

    loadDataTypes() {
        return this.httpService
            .get<PrepopulatedDataTypeResponse>('prepopulatedData/type')
            .send();
    }

    addNewAllowedValue(type: string, value: string, sort: number, model: any) {
        return this.httpService
            .post<any, UpdateAllowedValuesBody>('prepopulatedData/select')
            .withBody({
                value,
                type,
                sort,
                model,
            })
            .send();
    }

    updateAllowedValue(
        type: string,
        value: string,
        sort: number,
        model: any,
        id: string
    ) {
        return this.httpService
            .put<any, UpdateAllowedValuesBody>(`prepopulatedData/select/${id}`)
            .withBody({
                value,
                type,
                sort,
                model,
            })
            .send();
    }

    deleteAllowedValue(id: string) {
        return this.httpService.delete(`prepopulatedData/select/${id}`);
    }
}

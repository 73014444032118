import { Action, createReducer, on } from '@ngrx/store';
import {
    DataSchemaModel,
    MongoComponent,
    MongoEntity,
    MongoFormEntity,
} from '../../models/db-schema.model';
import * as formActions from './form-api.actions';
import { loadLatestDataToForm } from './form-api.actions';

export interface PrepopulatedData {
    selectData: any[];
    addressData: any[];
    contactData: any[];
    originsData: any[];
}

export type Forms = Array<MongoEntity<{ formName: string; data: any }>>;

export interface FormApiState {
    forms?: Forms;
    isInitialized: boolean;
    //
    formError?: any;
    isLoading?: boolean;
    isLoadingSuccess?: boolean;
    isLoadingFailure?: boolean;

    currentForm?: DataSchemaModel;
    editedForm?: MongoFormEntity<DataSchemaModel>;

    isUploadingJSON?: boolean;
    isUploadingJSONSuccess?: boolean;
    isUploadingJSONFailure?: boolean;
    uploadJSONResult?: any;

    customComponents?: MongoEntity<MongoComponent>[];
    customComponentError?: any;
    isCustomComponentLoading?: boolean;
    isCustomComponentLoadingSuccess?: boolean;
    isCustomComponentLoadingFailure?: boolean;

    isCustomComponentDeleteSuccess?: boolean;
    isCustomComponentDeleteFailure?: boolean;

    prepopulatedData?: PrepopulatedData;
}

const initialCurrentForm: DataSchemaModel = {
    id: '',
    label: '',
    dataSchemaId: '',
    elements: [],
    editInput: [],
};

const initialPrepopulatedData: any = {
    selectData: [
        {
            id: 'us_states',
            options: [
                'AL',
                'AK',
                'AS',
                'AZ',
                'AR',
                'CA',
                'CO',
                'CT',
                'DE',
                'DC',
                'FM',
                'FL',
                'GA',
                'GU',
                'HI',
                'ID',
                'IL',
                'IN',
                'IA',
                'KS',
                'KY',
                'LA',
                'ME',
                'MH',
                'MD',
                'MA',
                'MI',
                'MN',
                'MS',
                'MO',
                'MT',
                'NE',
                'NV',
                'NH',
                'NJ',
                'NM',
                'NY',
                'NC',
                'ND',
                'MP',
                'OH',
                'OK',
                'OR',
                'PW',
                'PA',
                'PR',
                'RI',
                'SC',
                'SD',
                'TN',
                'TX',
                'UT',
                'VT',
                'VI',
                'VA',
                'WA',
                'WV',
                'WI',
                'WY',
            ],
        },
        {
            id: 'ca_provinces',
            options: [
                'AB',
                'BC',
                'MB',
                'NB',
                'NL',
                'NT',
                'NS',
                'NU',
                'ON',
                'PE',
                'QC',
                'SK',
                'YT',
            ],
        },
        {
            id: 'contact_types',
            options: ['Business', 'Shipping'],
        },
        { id: 'sample' },
    ],
    addressData: [
        {
            id: 'address_3',
            fields: {
                line1: '600 Travis St.',
                line2: 'Ste. 3200',
                line3: '',
                city: 'Houston',
                state: 'TX',
                postcode: '77002',
                country: 'US',
            },
        },
        {
            id: 'address_2',
            fields: {
                line1: '275 Rue Notre Dame E',
                line2: '',
                line3: '',
                city: 'Montreal',
                state: 'QC',
                postcode: 'H2Y 1C6',
                country: 'CA',
            },
        },
    ],
    contactData: [
        {
            id: 'contact_1',
            fields: [
                {
                    'contact-type': 'Business',
                    name: 'Alfred Wessex',
                    title: 'Dr',
                    phone: '212-524-2430',
                    email: 'alfred.wessex@mail.com',
                },
                {
                    'contact-type': 'Shipping',
                    name: 'Owain Glyndwr',
                    title: '',
                    phone: '212-524-2431',
                    email: 'owain.glyndwr@mail.com',
                },
            ],
        },
    ],
    originData: [
        {
            id: 'origin_1',
            fields: [
                {
                    nickname: 'Origin Name',
                    checkboxSame: false,
                    returnAddress: {
                        line1: '600 Travis St.',
                        line2: 'Ste. 3200',
                        line3: '',
                        city: 'Houston',
                        state: 'TX',
                        postcode: '77002',
                        country: 'US',
                    },
                    shippingAddress: {
                        line1: '275 Rue Notre Dame E',
                        line2: '',
                        line3: '',
                        city: 'Montreal',
                        state: 'QC',
                        postcode: 'H2Y 1C6',
                        country: 'CA',
                    },
                },
            ],
        },
        {
            id: 'origin_2',
            fields: [
                {
                    nickname: 'Origin Name',
                    checkboxSame: true,
                    returnAddress: {
                        line1: '600 Travis St.',
                        line2: 'Ste. 3200',
                        line3: '',
                        city: 'Houston',
                        state: 'TX',
                        postcode: '77002',
                        country: 'US',
                    },
                    shippingAddress: {
                        line1: '600 Travis St.',
                        line2: 'Ste. 3200',
                        line3: '',
                        city: 'Houston',
                        state: 'TX',
                        postcode: '77002',
                        country: 'US',
                    },
                },
            ],
        },
    ],
};

const initialState: FormApiState = {
    forms: [],
    isInitialized: false,
    formError: {},
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: false,

    currentForm: initialCurrentForm,

    isUploadingJSON: false,
    isUploadingJSONSuccess: false,
    isUploadingJSONFailure: false,
    uploadJSONResult: {},

    customComponents: [],
    customComponentError: {},
    isCustomComponentLoading: false,
    isCustomComponentLoadingSuccess: false,
    isCustomComponentLoadingFailure: false,

    isCustomComponentDeleteSuccess: false,
    isCustomComponentDeleteFailure: false,

    prepopulatedData: initialPrepopulatedData,
};

const formsReducer = createReducer(
    initialState,
    on(formActions.getForms, (state) => ({ ...state, isLoading: true })),
    on(formActions.deleteForm, (state) => ({ ...state, isLoading: true })),
    on(formActions.getFormsFailure, (state, result) => ({
        ...state,
        formError: result,
        isLoading: false,
        isLoadingFailure: true,
    })),
    on(formActions.getFormsSuccess, (state, result) => {
        return {
            ...state,
            forms: result.response.map((r) => ({
                _id: r.id,
                formName: r.formName,
                data: r.data,
                createdAt: r.createdAt,
                updatedAt: r.updatedAt,
                dictionaries: r.dictionaries,
            })),
            isLoading: false,
            isLoadingSuccess: true,
            isInitialized: true,
        };
    }),

    // on(formActions.deleteFormSuccess, (state, result) => ({
    //     ...state,
    //     forms: state.forms.filter((form) => form._id !== result.formId),
    // })),
    // on(formActions.editForm, (state, result) => {
    //     return {
    //         ...state,
    //         editedForm: state.forms.find(
    //             (form) => form.form.id === result.formId
    //         ),
    //     };
    // }),

    on(formActions.setForm, (state, currentForm) => ({
        ...state,
        currentForm: currentForm,
    })),

    on(formActions.uploadFormJSON, (state) => ({
        ...state,
        isUploadingJSON: true,
    })),
    on(formActions.uploadFormJSONSuccess, (state, result) => ({
        ...state,
        uploadJSONResult: result,
        isUploadingJSON: false,
        isUploadingJSONSuccess: true,
        editedForm: result,
    })),
    on(formActions.uploadFormJSONFailure, (state, result) => ({
        ...state,
        uploadJSONResult: result,
        isUploadingJSON: false,
        isUploadingJSONFailure: true,
    })),
    on(formActions.uploadFormJSONReset, (state) => ({
        ...state,
        isUploadingJSON: false,
        isUploadingJSONSuccess: false,
        isUploadingJSONFailure: false,
    })),

    on(formActions.getCustomComponents, (state) => ({
        ...state,
        isCustomComponentLoading: true,
    })),
    on(formActions.getCustomComponentsFailure, (state, result) => ({
        ...state,
        customComponentError: result,
        isCustomComponentLoading: false,
        isCustomComponentLoadingFailure: true,
    })),
    on(formActions.getCustomComponentsSuccess, (state, result) => ({
        ...state,
        customComponents: result.response,
        isCustomComponentLoading: false,
        isCustomComponentLoadingSuccess: true,
    })),

    on(formActions.deleteCustomComponentJSONSuccess, (state) => ({
        ...state,
        isCustomComponentDeleteSuccess: true,
    })),
    on(formActions.deleteCustomComponentJSONFailure, (state) => ({
        ...state,
        isCustomComponentDeleteFailure: true,
    })),
    on(loadLatestDataToForm, (state, action) => {
        const formToUpdateIndex = action.formInput.findIndex(
            (form: any) => form._id === action.formId
        );
        return {
            ...state,
            forms: [
                ...action.formInput.slice(0, formToUpdateIndex),
                {
                    ...action.formInput[formToUpdateIndex],
                    data: action.latestData,
                },
                ...action.formInput.slice(formToUpdateIndex + 1),
            ],
        };
    })
);

export function formApiReducer(
    state: FormApiState | undefined,
    action: Action
): any {
    return formsReducer(state, action);
}

import { abbreviations } from '../../assets/schema/abbrevation-dictionary';

export function convertKebabCaseToPascalCase(text: string) {
    if (text === '' || text === undefined) {
        return '';
    }
    return text.split('-').map(convertWordToUppercase).join('');
}

export function firstLetterUpperCase(word: string) {
    return `${word[0].toUpperCase()}${word.slice(1)}`;
}

export function convertKebabCaseToTitleCase(text: string) {
    if (text === undefined || text.trim() === '') {
        return text;
    }

    return text.split('-').map(convertWordToUppercase).join(' ');
}

export function convertPascalCaseToTitleCase(text: string) {
    return text
        .split(/([A-Z][a-z]+)/)
        .filter((s) => s !== '')
        .join(' ');
}

export function convertTitleCaseToKebabCase(text: string) {
    return text.toLowerCase().replace(/ /g, '-');
}

function convertWordToUppercase(word: string) {
    if (abbreviations.indexOf(word) < 0) {
        return firstLetterUpperCase(word);
    }

    return word.toUpperCase();
}

<div
    *ngIf="{ getAllDataStores: getAllDataStores$ | async } as state"
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <div class="mb-3">{{ label }}</div>
    <mat-radio-group
        class="flex"
        [ngModel]="value"
        (ngModelChange)="handleModelChanged($event)"
    >
        <mat-radio-button
            [value]="option.key"
            *ngFor="
                let option of getAllowedOptions(state.getAllDataStores) | async
            "
            [attr.data-cy]="
                getRadioButtonTestId(sectionId, clonedId, label, option.key)
            "
            [disabled]="readOnly"
            >{{ option.value }}</mat-radio-button
        >
    </mat-radio-group>
</div>

<div
    *ngIf="{
        getTriggerForSection: getTriggerForSection$ | async,
        customComponents: customComponents$ | async,
        getSubmissionSectionById: getSubmissionSectionById$ | async
    } as state"
>
    <div
        *ngIf="
            shouldBeVisible(
                state.getSubmissionSectionById,
                state.getTriggerForSection
            )
        "
        class="light-background"
    >
        <app-accordion
            [showBackground]="!isRepeater()"
            [darkHeader]="true"
            [isCollapsed]="isSectionAccordionCollapsed"
            (toggle)="handleToggleSectionAccordion()"
        >
            <app-accordion-header-content>
                <div
                    class="canvas-section-container"
                    [id]="clonedId ?? sectionId"
                >
                    <div class="canvas-section flex">
                        <div class="canvas-section-number" *ngIf="number">
                            {{ number }}
                        </div>
                        <div class="canvas-section-title full-width">
                            <span class="view-content">{{ name }}</span>
                        </div>
                    </div>
                </div>
            </app-accordion-header-content>
            <app-accordion-content>
                <div
                    class="subsections-container"
                    [ngClass]="{ bordered: isRepeater() }"
                >
                    <div [ngClass]="{ repeater: isRepeater() }">
                        <div
                            *ngFor="
                                let component of components;
                                trackBy: tracker;
                                let i = index
                            "
                            class="component"
                        >
                            <div
                                *ngIf="isBlankComponent(component); else other"
                            ></div>
                            <ng-template #other>
                                <div
                                    [attr.data-cy]="
                                        getTestId(sectionId, clonedId)
                                    "
                                >
                                    <app-analyst-form-section-repeater-indicator
                                        [repeaterSize]="repeaterSize"
                                        [elementIndex]="i"
                                        [name]="getSingularForm(name)"
                                        (deleteElement)="handleDeleteElement(i)"
                                        [sectionId]="sectionId"
                                        [clonedId]="clonedId"
                                    ></app-analyst-form-section-repeater-indicator>
                                    <app-analyst-form-section-element
                                        [component]="component"
                                        [customComponents]="
                                            state.customComponents.components
                                        "
                                        [sectionId]="sectionId"
                                        [componentIndex]="i"
                                        [clonedId]="clonedId"
                                        [readOnly]="readOnly"
                                        [sectionUniqueId]="sectionUniqueId"
                                    ></app-analyst-form-section-element>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <button
                        mat-raised-button
                        color="primary"
                        *ngIf="isRepeater()"
                        class="m-2 clone-section-button"
                        (click)="cloneSection()"
                    >
                        <mat-icon>add</mat-icon>
                        Add {{ getSingularForm(name) }}
                    </button>
                </div>
            </app-accordion-content>
        </app-accordion>
    </div>
</div>

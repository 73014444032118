import { createAction, props } from '@ngrx/store';
import { SubmissionDictionaryType, SubmissionsState } from './model';
import { Value } from '../../shared/base-new-value';
import { Section } from '../../models/section';
import { SubmissionStatus } from '../../submission.service';

export const UPDATE_DICTIONARY = '[Submission] Update Dictionary';
export const UPDATE_DICTIONARY_BULK = '[Submission] Update Dictionary Bulk';
export const LOAD_ANALYST_FORM_FROM_SUBMISSION =
    '[Submission] Load Analyst Form From Submission';
export const LOAD_ANALYST_FORM_FROM_TEMPLATE =
    '[Submission] Load Analyst Form From Template';
export const LOAD_SUBMISSION = '[Submission] Load';
export const LOAD_SUBMISSION_SUCCESS = '[Submission] Load Success';

export const ADD_SUBMISSION = '[Submission] Add';
export const DELETE_SUBMISSION = '[Submission] Delete';
export const DELETE_SUBMISSION_SUCCESS = '[Submission] Delete Success';

export const STORE_COMPONENT_VALUE = '[Submission] Store component value';
export const STORE_COMPONENT_ADDITIONAL_VALUE =
    '[Submission] Store component additional value';
const STORE_COMPONENT_VALUE_SUCCESS =
    '[Submission] Store component value Success';
export const TRIGGER_VALUE_CHANGED = '[Submission] Trigger value changed';
export const START_OBSERVING_TRIGGER = '[Submission] Start observing trigger';

export const LOAD_DICTIONARY = '[Submission] Load Dictionary';

const CLONE_REPEATER = '[Submission] Clone repeater';
const DELETE_REPEATER = '[Submission] Delete repeater';

export const UPSERT_ONBOARDING_FORM = '[Submission] Save Onboarding Form';

export const CHANGE_SUBMISSION_STATUS = '[Submission] Status Change';

const CHANGE_SUBMISSION_FILTERS = '[Submission List] Change filters';
const CHANGE_SUBMISSION_FILTERS_SUCCESS =
    '[Submission List] Change filters Success';

export const updateDictionary = createAction(
    UPDATE_DICTIONARY,
    props<{
        dictionaryType: SubmissionDictionaryType;
        value: Value;
        label: string;
        dictionaryUniqueIdType: string;
    }>()
);

export const updateDictionaryBulk = createAction(
    UPDATE_DICTIONARY_BULK,
    props<{
        dictionaryType: SubmissionDictionaryType;
        value: Value[];
        label: string[];
        dictionaryUniqueIdType: string;
    }>()
);

export const loadAnalystFormFromTemplate = createAction(
    LOAD_ANALYST_FORM_FROM_TEMPLATE,
    props<{ formId: string }>()
);

export const loadAnalystFormFromSubmission = createAction(
    LOAD_ANALYST_FORM_FROM_SUBMISSION,
    props<{ formId: string }>()
);

export const storeComponentValue = createAction(
    STORE_COMPONENT_VALUE,
    props<{
        sectionId: string;
        componentIndex: number;
        value: string;
        clonedId: string;
    }>()
);

export const storeComponentAdditionalValue = createAction(
    STORE_COMPONENT_ADDITIONAL_VALUE,
    props<{
        sectionId: string;
        componentIndex: number;
        additionalValue: string;
        clonedId: string;
    }>()
);

export const storeComponentValueSuccess = createAction(
    STORE_COMPONENT_VALUE_SUCCESS,
    props<{
        sectionId: string;
        componentIndex: number;
        value: string;
        clonedId: string;
    }>()
);
export const loadSubmissions = createAction(LOAD_SUBMISSION);

export const loadSubmissionsSuccess = createAction(
    LOAD_SUBMISSION_SUCCESS,
    props<{ data: any[] }>()
);

export const addSubmission = createAction(
    ADD_SUBMISSION,
    props<{ data: any }>()
);

export const triggerValueChanged = createAction(
    TRIGGER_VALUE_CHANGED,
    props<{ sectionId: string; value: string }>()
);

export const startObservingTrigger = createAction(
    START_OBSERVING_TRIGGER,
    props<{ sectionId: string }>()
);

export const cloneRepeater = createAction(
    CLONE_REPEATER,
    props<{ sectionId: string; clonedId: string }>()
);

export const deleteRepeater = createAction(
    DELETE_REPEATER,
    props<{
        sectionId: string;
        clonedId: string;
        index: number;
        repeaterSize: number;
    }>()
);

export const loadDictionary = createAction(
    LOAD_DICTIONARY,
    props<{
        dictionaryExternalId: string;
        dictionaryId: string;
        dictionaryType: 'address' | 'contact';
    }>()
);

export const upsertOnboardingForm = createAction(
    UPSERT_ONBOARDING_FORM,
    props<{
        formName: string;
        formId: string;
        data: any;
        inputForm: Section[];
        inputDictionaries: SubmissionsState['dictionaries'];
        templateId: string;
        saveAs: boolean;
        templateName: string;
        status: SubmissionStatus;
    }>()
);

export const deleteSubmission = createAction(
    DELETE_SUBMISSION,
    props<{ submissionId: string }>()
);

export const deleteSubmissionSuccess = createAction(
    DELETE_SUBMISSION_SUCCESS,
    props<{ submissionId: string }>()
);

export const changeSubmissionStatus = createAction(
    CHANGE_SUBMISSION_STATUS,
    props<{ newStatus: SubmissionStatus }>()
);

export const changeSubmissionFilters = createAction(
    CHANGE_SUBMISSION_FILTERS,
    props<{ filters: SubmissionsState['filters'] }>()
);

export const changeSubmissionFiltersSuccess = createAction(
    CHANGE_SUBMISSION_FILTERS_SUCCESS,
    props<{ filters: SubmissionsState['filters']; data: any[] }>()
);

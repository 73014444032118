import { Injectable } from '@angular/core';
import { DataSourceService } from './api/data-source.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DataSourceDto } from './api/dto/data-source.dto';
import { Memoize } from './decorators/memoize.decorator';
import { Address, AddressModel } from '../models/address';
import { DefaultDictionary } from '../store/admin/model';
import { ContactModel } from '../models/contact';

@Injectable({
    providedIn: 'root',
})
export class DataStoreService {
    constructor(private dataSourceService: DataSourceService) {}

    @Memoize()
    getAllowedValues(
        allowedValues: string,
        dataStores: DataSourceDto[]
    ): Observable<Array<{ key: string; value: any }>> {
        if (allowedValues.startsWith('$')) {
            const dataStoreId = allowedValues.replace('$', '');
            const dataStore = dataStores.find(
                (store) => store.id === dataStoreId
            );

            if (dataStore.type === 'external') {
                return this.dataSourceService
                    .getExternalDataSourceMapping(dataStore.id)
                    .pipe(
                        map((data) => {
                            return data.items.map((item) => ({
                                key: item.id,
                                value: item.value,
                            }));
                        })
                    );
            } else {
                return of(
                    dataStore.items
                        .join('\n')
                        .split(',')
                        .join('\n')
                        .split('\n')
                        .map((value) => ({
                            key: value,
                            value,
                        }))
                );
            }
        }
        return of(
            allowedValues.split(',').map((value) => ({ key: value, value }))
        );
    }

    @Memoize()
    getAllowedValuesAsMapping(
        allowedValues: string,
        dataStores: DataSourceDto[],
        dictionaryUniqueType: string
    ): Observable<DefaultDictionary<Address>> {
        const dataStoreId = allowedValues;
        const dataStore = dataStores.find((store) => store.id === dataStoreId);
        return this.dataSourceService
            .getExternalDataSourceMapping<
                (AddressModel & ContactModel) & { id: string }
            >(dataStore.id)
            .pipe(
                map((mapping) => {
                    return mapping.items.map((mappingObj) => ({
                        id: mappingObj.id,
                        name: mappingObj.label,
                        dictionaryUniqueIdType: dictionaryUniqueType,
                        model: mappingObj,
                    }));
                })
            );
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss'],
})
export class ActionButtonsComponent {
    static SaveFormButtonLabel = 'Save Form';
    static SaveAsFormButtonLabel = 'Save Form As';
    static CloseFormButtonLabel = 'Close';

    @Input()
    public isDisabled: boolean;

    @Output()
    public saveForm: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public saveAsForm: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public closePage: EventEmitter<any> = new EventEmitter<any>();

    Component = ActionButtonsComponent;

    constructor() {}

    handleSaveClick($event: MouseEvent) {
        this.saveForm.emit();
    }

    handleSaveAsClick($event: MouseEvent) {
        this.saveAsForm.emit();
    }

    handleCloseClick($event: MouseEvent) {
        this.closePage.emit($event);
    }
}

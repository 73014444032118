import { GeneralState } from './model';
import { Action, createReducer, on } from '@ngrx/store';
import { refreshNeeded, refreshSucceded, setFilters } from './general.action';

const initialState: GeneralState = {
    refreshNeeded: false,
    filters: {
        publication: '',
    },
};

const createGeneralReducer = createReducer(
    initialState,
    on(refreshNeeded, (state, action) => {
        return {
            ...state,
            refreshNeeded: true,
        };
    }),
    on(refreshSucceded, (state, action) => {
        return {
            ...state,
            refreshNeeded: false,
        };
    }),
    on(setFilters, (state, action) => {
        return {
            ...state,
            filters: {
                [action.group]: action.value,
            },
        };
    })
);

export function generalReducer(
    state: GeneralState | undefined,
    action: Action
) {
    return createGeneralReducer(state, action);
}

import { SubmissionsState } from './model';
import { Action, createReducer, on } from '@ngrx/store';
import {
    addSubmission,
    changeSubmissionFiltersSuccess,
    changeSubmissionStatus,
    cloneRepeater,
    deleteRepeater,
    deleteSubmissionSuccess,
    loadAnalystFormFromSubmission,
    loadSubmissionsSuccess,
    storeComponentAdditionalValue,
    storeComponentValueSuccess,
    triggerValueChanged,
    updateDictionary,
    updateDictionaryBulk,
} from './submission.action';
import { v4 } from 'uuid';
import { AddressModel } from '../../models/address';
import {
    getFormsSuccess,
    loadLatestDataToForm,
} from '../form-api/form-api.actions';
import { componentTreeAsAnArray } from '../../utils/component-tree';

const initialState: SubmissionsState = {
    submissions: [],
    form: { data: [], templateName: '', status: 'draft' },
    dictionaries: {
        address: [],
        contact: [],
    },
    isInitialized: false,
    triggers: [],
    filters: {
        status: 'all' as any,
    },
};

function createRepeaterClonedSource(components) {
    return components.map((component) => {
        let newComponents = undefined;

        if (component?.components !== undefined) {
            newComponents = createRepeaterClonedSource(
                component.clonedSource ?? component.components
            );
        }

        return {
            ...component,
            value: undefined,
            components: newComponents,
        };
    });
}

function findAllPossibleSectionsToUpdate(
    state: SubmissionsState,
    sectionId: string,
    clonedId: string
) {
    const newForm = JSON.parse(JSON.stringify(state.form.data));

    const sectionsWithTheSameId = componentTreeAsAnArray(
        newForm,
        undefined
    ).filter((section) => section?.id === sectionId);

    let sectionToUpdate = sectionsWithTheSameId[0];
    if (clonedId) {
        sectionToUpdate = sectionsWithTheSameId.find(
            (section) => section?.clonedId === clonedId
        );
    }

    return { newForm, sectionToUpdate };
}

const createSubmissionReducer = createReducer(
    initialState,
    on(
        updateDictionary,
        (state, { dictionaryType, value, label, dictionaryUniqueIdType }) => {
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    [dictionaryType]: [
                        ...state.dictionaries[dictionaryType],
                        {
                            id: v4(),
                            name: label,
                            model: value as AddressModel,
                            dictionaryUniqueIdType,
                        },
                    ],
                },
            };
        }
    ),
    on(
        updateDictionaryBulk,
        (state, { dictionaryType, value, label, dictionaryUniqueIdType }) => {
            return {
                ...state,
                dictionaries: {
                    ...state.dictionaries,
                    [dictionaryType]: [
                        ...(state.dictionaries[dictionaryType] as any[]).filter(
                            (element) =>
                                element.dictionaryUniqueIdType !==
                                dictionaryUniqueIdType
                        ),
                        ...value.map((v, index) => ({
                            id: v4(),
                            name: label[index],
                            model: v,
                            dictionaryUniqueIdType,
                        })),
                    ],
                },
            };
        }
    ),
    on(loadLatestDataToForm, (state, action) => {
        return {
            ...state,
            dictionaries: action.dictionaries,
        };
    }),
    on(getFormsSuccess, (state, action) => {
        const id = action?.response[0]?.formId;
        if (id) {
            const content = action.response.find((d) => d.id === id);
            return {
                ...state,
                form: {
                    data: content.data,
                    templateName: content.formName,
                    status: content.status,
                },
                dictionaries: content.dictionaries,
                editMode: false,
            };
        }

        return {
            ...state,
        };
    }),
    on(loadAnalystFormFromSubmission, (state: SubmissionsState, action) => {
        const submission = state.submissions.find(
            (s) => s.id === action.formId
        );
        return {
            ...state,
            form: {
                data: submission.inputForm,
                templateName: submission.templateName,
                status: submission.status,
            },
            dictionaries: submission.inputDictionaries,
            editMode: true,
        };
    }),
    on(storeComponentValueSuccess, (state, action) => {
        const { newForm, sectionToUpdate } = findAllPossibleSectionsToUpdate(
            state,
            action.sectionId,
            action.clonedId
        );

        let componentToUpdate =
            sectionToUpdate.components[action.componentIndex];

        componentToUpdate = {
            ...componentToUpdate,
            value: action.value,
        };

        sectionToUpdate.components[action.componentIndex] = componentToUpdate;

        return {
            ...state,
            form: {
                ...state.form,
                data: newForm,
            },
        };
    }),
    on(storeComponentAdditionalValue, (state, action) => {
        const { newForm, sectionToUpdate } = findAllPossibleSectionsToUpdate(
            state,
            action.sectionId,
            action.clonedId
        );

        let componentToUpdate =
            sectionToUpdate.components[action.componentIndex];

        componentToUpdate = {
            ...componentToUpdate,
            additionalValue: action.additionalValue,
        };

        sectionToUpdate.components[action.componentIndex] = componentToUpdate;

        return {
            ...state,
            form: {
                ...state.form,
                data: newForm,
            },
        };
    }),
    on(loadSubmissionsSuccess, (state, action) => {
        return {
            ...state,
            submissions: action.data,
            isInitialized: true,
        };
    }),
    on(addSubmission, (state, action) => {
        return {
            ...state,
            submissions: [...state.submissions, action.data],
            filters: {
                status: 'all' as any,
            },
        };
    }),
    on(triggerValueChanged, (state, action) => {
        const triggerIndex = state.triggers.findIndex(
            (trigger) => trigger.sectionId === action.sectionId
        );
        return {
            ...state,
            triggers: [
                ...state.triggers.slice(0, triggerIndex),
                {
                    sectionId: action.sectionId,
                    value: action.value,
                },
                ...state.triggers.slice(triggerIndex + 1),
            ],
        };
    }),
    on(cloneRepeater, (state, action) => {
        let { newForm, sectionToUpdate } = findAllPossibleSectionsToUpdate(
            state,
            action.sectionId,
            action.clonedId
        );

        if (sectionToUpdate['clonedSource'] === undefined) {
            sectionToUpdate['clonedSource'] = createRepeaterClonedSource(
                sectionToUpdate.components
            );
        }

        sectionToUpdate.components = [
            ...sectionToUpdate.components,
            ...sectionToUpdate['clonedSource'].map((component) => ({
                ...component,
                clonedId: v4(),
            })),
        ];

        return {
            ...state,
            form: {
                ...state.form,
                data: newForm,
            },
        };
    }),
    on(deleteRepeater, (state, action) => {
        let { newForm, sectionToUpdate } = findAllPossibleSectionsToUpdate(
            state,
            action.sectionId,
            action.clonedId
        );

        sectionToUpdate.components = sectionToUpdate.components.filter(
            (component, index) =>
                index < action.index ||
                index > action.index + action.repeaterSize - 1
        );

        return {
            ...state,
            form: { ...state.form, data: newForm },
        };
    }),
    on(deleteSubmissionSuccess, (state, action) => {
        return {
            ...state,
            submissions: state.submissions.filter(
                (submission) => submission.id !== action.submissionId
            ),
        };
    }),
    on(changeSubmissionStatus, (state, action) => {
        return {
            ...state,
            form: {
                ...state.form,
                status: action.newStatus,
            },
        };
    }),
    on(changeSubmissionFiltersSuccess, (state, action) => {
        return {
            ...state,
            filters: action.filters,
            submissions: action.data,
        };
    })
);

export function submissionReducer(
    state: SubmissionsState | undefined,
    action: Action
) {
    return createSubmissionReducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { PrepopulatedDataType } from './prepopulated-data.reducer';
import {
    ComponentType,
    PrepopulatedDataSelectResponseDataEntity,
} from '../../core/allowed-values.service';

const PREPOPULATED_DATA_LOAD_TYPES = '[Prepopulated Data] Load types';
const PREPOPULATED_DATA_LOAD_TYPES_SUCCESS =
    '[Prepopulated Data] Load types Success';
const PREPOPULATED_DATA_LOAD = '[Prepopulated Data] Load';
const PREPOPULATED_DATA_LOAD_SUCCESS = '[Prepopulated Data] Load Success';

export const LoadTypes = createAction(PREPOPULATED_DATA_LOAD_TYPES);
export const LoadTypesSuccess = createAction(
    PREPOPULATED_DATA_LOAD_TYPES_SUCCESS,
    props<{ types: Array<PrepopulatedDataType> }>()
);

export const LoadData = createAction(
    PREPOPULATED_DATA_LOAD,
    props<{ dataType: string; componentType: ComponentType }>()
);
export const LoadDataSuccess = createAction(
    PREPOPULATED_DATA_LOAD_SUCCESS,
    props<{
        data: Array<PrepopulatedDataSelectResponseDataEntity>;
        key: string;
    }>()
);

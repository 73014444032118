import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ObjectService {
    constructor() {}

    isEmpty(obj: any): boolean {
        if (obj === undefined) {
            return true;
        }

        return Object.keys(obj).length === 0;
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentElementsService } from '../../core/component-elements.service';
import { FormValidatorService } from '../form-validator.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { Memoize } from '../../core/decorators/memoize.decorator';
import { convertTitleCaseToKebabCase } from '../../utils/text-utils';

@Component({
    selector: 'app-analyst-form-checkbox',
    templateUrl: './analyst-form-checkbox.component.html',
    styleUrls: ['./analyst-form-checkbox.component.scss'],
})
export class AnalystFormCheckboxComponent {
    @Input()
    public label: string;

    @Input()
    public value: boolean;

    @Input()
    public readOnly: boolean = false;

    @Input()
    public component: EditComponentModel;

    @Input()
    sectionId: string;

    @Input()
    clonedId: string;

    @Output()
    valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    handleModelChange($event: any) {
        this.valueChanged.emit($event);
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }

    @Memoize()
    getInputTestId(clonedId: string, sectionId: string, label: string) {
        return `${clonedId ?? sectionId}_${convertTitleCaseToKebabCase(label)}`;
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    getDictionaryByType,
    GetDictionaryByTypeType,
} from '../../store/submission/submission.selector';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import {
    loadDictionary,
    updateDictionary,
} from '../../store/submission/submission.action';
import {
    NewOptionModalWindowComponent,
    NewOptionModalWindowComponentDialogData,
    NewOptionModalWindowComponentDialogResult,
} from '../../shared/new-option-modal-window/new-option-modal-window.component';
import { DefaultDictionary } from '../../store/admin/model';
import { Contact } from '../../models/contact';
import { first } from 'rxjs/operators';
import { firstLetterUpperCase } from '../../utils/text-utils';
import { ComponentElementsService } from '../../core/component-elements.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { FormValidatorService } from '../form-validator.service';

@Component({
    selector: 'app-analyst-form-contact',
    templateUrl: './analyst-form-contact.component.html',
    styleUrls: ['./analyst-form-contact.component.scss'],
})
export class AnalystFormContactComponent implements OnInit, OnDestroy {
    @Input()
    public label: string;

    @Input()
    public value: string;

    @Input()
    public readOnly: boolean;

    @Input()
    public uniqueId: string;

    @Input()
    public dictionaryId: string;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter<string>();

    public getDictionaryByType$: Observable<GetDictionaryByTypeType<any>>;

    public selectedContact: DefaultDictionary<Contact>[0];

    private subscription: Subscription;

    constructor(
        private store: Store,
        private matDialog: MatDialog,
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit(): void {
        if (this.dictionaryId) {
            this.store.dispatch(
                loadDictionary({
                    dictionaryExternalId: this.dictionaryId,
                    dictionaryId: this.uniqueId,
                    dictionaryType: 'contact',
                })
            );
        }
        this.subscription = this.store
            .select(getDictionaryByType('contact'))
            .pipe(first())
            .subscribe((data) => {
                const contactData = data as GetDictionaryByTypeType<Contact>;
                if (this.value) {
                    this.selectedContact = this.getAllOptions(
                        contactData.dictionary
                    ).find((contact) => contact.id === this.value);
                }
            });
        this.getDictionaryByType$ = this.store.select(
            getDictionaryByType('contact')
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addNewContact() {
        const dialogRef = this.matDialog.open<
            NewOptionModalWindowComponent,
            NewOptionModalWindowComponentDialogData,
            NewOptionModalWindowComponentDialogResult
        >(NewOptionModalWindowComponent, {
            data: {
                type: 'contact',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.store.dispatch(
                    updateDictionary({
                        dictionaryType: 'contact',
                        value: result.model,
                        label: result.value,
                        dictionaryUniqueIdType: this.uniqueId,
                    })
                );
            }
        });
    }

    handleModelChange($event: any, dictionary: DefaultDictionary<Contact>) {
        this.selectedContact = this.getAllOptions(dictionary).find(
            (contact) => contact.id === $event
        );
        this.valueChanged.emit($event);
    }

    getAllOptions(dictionary: DefaultDictionary<Contact>) {
        return dictionary.filter(
            (item) => item.dictionaryUniqueIdType === this.uniqueId
        );
    }

    getContactType() {
        return firstLetterUpperCase(this.selectedContact.model.contactType);
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }
}

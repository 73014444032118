<div class="filler-preview-container">
    <div class="filler-preview">
        <div
            *ngFor="let section of submission.sections; let $index = index"
            class="filler-preview-section"
        >
            <app-accordion
                [showBackground]="false"
                [isCollapsed]="isSectionPreviewCollapsed(section, $index)"
                (toggle)="togglePreviewAccordion(section)"
            >
                <app-accordion-header-content class="full-width">
                    <div
                        class="filler-preview-element flex aligned-center p-2"
                        (click)="scrollToSection(section)"
                        [ngClass]="{
                            'green-text': requiredFieldsAreFilledIn(
                                section.components
                            )
                        }"
                    >
                        <div class="filler-preview-section-number">
                            {{ section.number }}
                        </div>
                        {{ section.name }}
                    </div>
                </app-accordion-header-content>
                <app-accordion-content>
                    <ng-container
                        *ngTemplateOutlet="
                            nestedSection;
                            context: {
                                sections: getVisiblePreviewSections(
                                    section.components
                                ),
                                directlyUnderMain: true
                            }
                        "
                    ></ng-container>
                </app-accordion-content>
            </app-accordion>
        </div>
    </div>
</div>

<ng-template
    #nestedSection
    let-sections="sections"
    let-directlyUnderMain="directlyUnderMain"
>
    <div
        *ngIf="nestedPreviewVisible(sections)"
        class="filler-preview-nested-section"
        [ngClass]="{ 'big-indentation': directlyUnderMain }"
    >
        <div *ngFor="let container of getAllContainers(sections)">
            <div
                class="filler-preview-element no-select pointer flex justified"
                (click)="scrollToSection(container)"
                [ngClass]="{
                    'green-text': requiredFieldsAreFilledIn(
                        container.components
                    )
                }"
            >
                {{ getContainerName(container) }}
                <mat-icon
                    style="color: green"
                    *ngIf="requiredFieldsAreFilledIn(container.components)"
                    >done</mat-icon
                >
            </div>
            <div class="pl-2" *ngIf="isRepeater(container); else subsection">
                <div
                    *ngFor="
                        let repeaterInstance of repeaterInstancesSize(
                            container
                        );
                        let $index = index
                    "
                    (click)="scrollToRepeaterInstance(container, $index)"
                >
                    <div
                        class="filler-preview-element small pl-2 no-select flex justified"
                        [ngClass]="{
                            'green-text': requiredFieldsAreFilledIn(
                                container.components.slice(
                                    repeaterInstance.start,
                                    repeaterInstance.end
                                )
                            )
                        }"
                    >
                        {{ getRepeaterInstanceName(container) }}
                        {{ $index + 1 }}
                        <mat-icon
                            style="color: green"
                            *ngIf="
                                requiredFieldsAreFilledIn(
                                    container.components.slice(
                                        repeaterInstance.start,
                                        repeaterInstance.end
                                    )
                                )
                            "
                            >done</mat-icon
                        >
                    </div>
                    <ng-container
                        *ngTemplateOutlet="
                            nestedSection;
                            context: {
                                sections: getVisiblePreviewSections(
                                    container.components.slice(
                                        repeaterInstance.start,
                                        repeaterInstance.end
                                    )
                                ),
                                directlyUnderMain: false
                            }
                        "
                    ></ng-container>
                </div>
            </div>
            <ng-template #subsection>
                <ng-container
                    *ngTemplateOutlet="
                        nestedSection;
                        context: {
                            sections: getVisiblePreviewSections(
                                container.components
                            ),
                            directlyUnderMain: false
                        }
                    "
                ></ng-container>
            </ng-template>
        </div>
    </div>
</ng-template>

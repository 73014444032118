import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AllowedValuesService } from '../../core/allowed-values.service';
import {
    LoadData,
    LoadDataSuccess,
    LoadTypes,
    LoadTypesSuccess,
} from './prepopulated-data.action';
import { exhaustMap, map } from 'rxjs/operators';

@Injectable()
export class PrepopulatedDataEffect {
    constructor(
        private actions$: Actions,
        private allowedValuesService: AllowedValuesService
    ) {}

    loadDataTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadTypes),
            exhaustMap((a) => {
                return this.allowedValuesService
                    .loadDataTypes()
                    .pipe(
                        map((response) =>
                            LoadTypesSuccess({ types: response.dataTypes })
                        )
                    );
            })
        )
    );

    loadData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoadData),
            exhaustMap((a) => {
                return this.allowedValuesService
                    .loadData(a.dataType, a.componentType)
                    .pipe(
                        map((response) =>
                            LoadDataSuccess({
                                data: response,
                                key: a.dataType,
                            })
                        )
                    );
            })
        )
    );
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface PublishModalComponentResult {
    additionalNotes: string;
}

@Component({
    selector: 'app-publish-modal',
    templateUrl: './publish-modal.component.html',
    styleUrls: ['./publish-modal.component.scss'],
})
export class PublishModalComponent {
    public additionalNotes: string = '';
    constructor(
        private dialogRef: MatDialogRef<
            PublishModalComponent,
            PublishModalComponentResult
        >
    ) {}

    handleCancelClick() {
        this.dialogRef.close();
    }

    handleOkClick() {
        this.dialogRef.close({ additionalNotes: this.additionalNotes });
    }
}

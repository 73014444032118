import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionHeaderComponent } from './accordion-header/accordion-header.component';
import { AccordionContentComponent } from './accordion-content/accordion-content.component';
import { MatIconModule } from '@angular/material/icon';
import { AccordionHeaderContentComponent } from './accordion-header-content/accordion-header-content.component';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { ComponentListComponent } from './component-list/component-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { CoreModule } from '../core/core.module';
import { UserActionComponent } from './new-option-modal-window/user-action/user-action.component';
import { UpsertStateComponent } from './new-option-modal-window/user-action/upsert-state/upsert-state.component';
import { NewAddressComponent } from './new-option-modal-window/user-action/upsert-state/new-address/new-address.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { StepLabelComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step1/step-label.component';
import { StepCountryComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step2/step-country.component';
import { StepRegionUsComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step3-us/step-region-us.component';
import { StepRegionCAComponent } from './new-option-modal-window/user-action/user-state/new-address/step-region-ca/step-region-ca.component';
import { StepRegionGeneralComponent } from './new-option-modal-window/user-action/user-state/new-address/step-region-general/step-region-general.component';
import { StepZipCodeComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step-zip-code/step-zip-code.component';
import { StepCityComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step-city/step-city.component';
import { StepAddressLinesComponent } from './new-option-modal-window/user-action/upsert-state/new-address/step-address-lines/step-address-lines.component';
import { TextMaskModule } from 'angular2-text-mask';
import { NewOptionModalWindowComponent } from './new-option-modal-window/new-option-modal-window.component';
import { MatSelectModule } from '@angular/material/select';
import { NewContactComponent } from './new-contact/new-contact.component';
import { DataStoreSelectBoxComponent } from './data-store-select-box/data-store-select-box.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { AddNewValueComponent } from './new-option-modal-window/user-action/upsert-state/add-new-value/add-new-value.component';

@NgModule({
    declarations: [
        AccordionComponent,
        AccordionHeaderComponent,
        AccordionContentComponent,
        AccordionHeaderContentComponent,
        TableComponent,
        ComponentListComponent,
        ActionButtonsComponent,
        ConfirmModalComponent,
        UploadButtonComponent,
        UserActionComponent,
        UpsertStateComponent,
        NewAddressComponent,
        NewContactComponent,
        AddNewValueComponent,
        StepLabelComponent,
        StepCountryComponent,
        StepRegionUsComponent,
        StepRegionCAComponent,
        StepRegionGeneralComponent,
        StepZipCodeComponent,
        StepCityComponent,
        StepAddressLinesComponent,
        NewOptionModalWindowComponent,
        DataStoreSelectBoxComponent,
        InputComponent,
        SelectComponent,
    ],
    exports: [
        AccordionComponent,
        AccordionContentComponent,
        AccordionHeaderComponent,
        AccordionHeaderContentComponent,
        TableComponent,
        TableComponent,
        ComponentListComponent,
        ActionButtonsComponent,
        UploadButtonComponent,
        UserActionComponent,
        AddNewValueComponent,
        UpsertStateComponent,
        NewAddressComponent,
        NewContactComponent,
        StepLabelComponent,
        StepCountryComponent,
        StepRegionUsComponent,
        StepRegionCAComponent,
        StepRegionGeneralComponent,
        StepZipCodeComponent,
        StepCityComponent,
        StepAddressLinesComponent,
        NewOptionModalWindowComponent,
        DataStoreSelectBoxComponent,
        InputComponent,
        SelectComponent,
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        MatMenuModule,
        MatDialogModule,
        CoreModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        TextMaskModule,
        MatOptionModule,
        MatSelectModule,
        MatTooltipModule,
    ],
})
export class SharedModule {}

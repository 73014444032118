<mat-form-field appearance="outline" class="full-width label-outside">
    <mat-label>{{ label }}</mat-label>
    <mat-select
        [ngModel]="value ?? defaultValue"
        (ngModelChange)="handleModelChange($event)"
        [disabled]="readOnly"
        [attr.data-cy]="
            getInputTestId(clonedId, sectionId, label, optionsMapLabel)
        "
    >
        <mat-option value="yes">Yes</mat-option>
        <mat-option value="no">No</mat-option>
    </mat-select>
</mat-form-field>

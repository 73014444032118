import { Injectable } from '@angular/core';
import { ElementModel } from '../models/db-schema.model';
import { MenuComponent } from '../enums/components';

@Injectable({
    providedIn: 'root',
})
export class ComponentElementService {
    constructor() {}

    isLabel(element: ElementModel) {
        return element?.name === `label`;
    }

    isAllowedValues(element: ElementModel) {
        return element?.name === 'allowedValues';
    }

    isConstraint(element: ElementModel, constraint: string) {
        const constraintName = `constraints${constraint}`;
        return element?.name === constraintName;
    }

    isUniqueId(element: ElementModel) {
        return element?.component === MenuComponent.UniqueId;
    }

    isOptionsMapContent(element: ElementModel) {
        return element?.component === MenuComponent.OptionsMapContent;
    }
}

<div
    *ngIf="repeaterSize > 0 && elementIndex % repeaterSize === 0"
    class="flex justified"
    [id]="getRepeaterAnchor(elementIndex / repeaterSize + 1)"
>
    <span> {{ name }} {{ elementIndex / repeaterSize + 1 }}</span>
    <button mat-icon-button color="accent" (click)="handleRemove()">
        <mat-icon>close</mat-icon>
    </button>
</div>

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BaseNewValue, Value } from '../../../../base-new-value';

@Component({
    selector: 'app-add-new-value',
    templateUrl: './add-new-value.component.html',
    styleUrls: ['./add-new-value.component.scss'],
})
export class AddNewValueComponent
    extends BaseNewValue<Value>
    implements OnInit, OnChanges
{
    @Input()
    initialModel: any;

    @Output()
    public save: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit(): void {
        this.model = this.initialModel ?? new Value('');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.initialModel.firstChange) {
            this.model = changes.initialModel.currentValue;
        }
    }

    handleSave() {
        this.save.emit();
    }

    handleCancel() {
        this.cancel.emit();
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    SubmissionApiResponse,
    SubmissionService,
} from '../../submission.service';
import {
    loadDictionary,
    loadAnalystFormFromTemplate,
    loadSubmissions,
    loadSubmissionsSuccess,
    startObservingTrigger,
    storeComponentValue,
    storeComponentValueSuccess,
    updateDictionaryBulk,
    addSubmission,
    upsertOnboardingForm,
    deleteSubmission,
    deleteSubmissionSuccess,
    changeSubmissionFilters,
    changeSubmissionFiltersSuccess,
} from './submission.action';
import { catchError, exhaustMap, first, map, tap } from 'rxjs/operators';
import { FormApiService } from '../../form-api.service';
import { getFormsSuccess } from '../form-api/form-api.actions';
import { select, Store } from '@ngrx/store';
import { TriggerService } from '../../core/trigger.service';
import { getSubmissionSectionById } from './submission.selector';
import { ComponentElementsService } from '../../core/component-elements.service';
import { Observable, of } from 'rxjs';
import { nullAction } from '../admin/admin.action';
import { refreshNeeded } from '../general/general.action';
import { StorageService } from '../../core/storage.service';
import { DataSourceService } from '../../core/api/data-source.service';
import { AddressModel } from '../../models/address';
import { ContactModel } from '../../models/contact';
import { Router } from '@angular/router';

@Injectable()
export class SubmissionEffect {
    constructor(
        private submissionService: SubmissionService,
        private formApiService: FormApiService,
        private actions$: Actions,
        private store: Store,
        private triggerService: TriggerService,
        private componentElementService: ComponentElementsService,
        private storageService: StorageService,
        private dataSourceService: DataSourceService,
        private router: Router
    ) {}

    loadAnalystFormFromTemplate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadAnalystFormFromTemplate),
            exhaustMap((a) => {
                return this.formApiService.getFormData('').pipe(
                    map((response: any[]) => {
                        return getFormsSuccess({
                            response: response.map((d) => ({
                                id: d._id,
                                formName: d.formName,
                                data: d.data,
                                createdAt: d.createdAt,
                                updatedAt: d.updatedAt,
                                dictionaries: d.dictionaries,
                                formId: a.formId,
                                status: d.status,
                            })),
                        });
                    })
                );
            })
        );
    });

    loadSubmissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSubmissions),
            exhaustMap((a) => {
                return this.submissionService.getAll().pipe(
                    map(({ forms }) => {
                        return loadSubmissionsSuccess({ data: forms });
                    }),
                    catchError((error) => {
                        this.storageService.addValue(
                            {
                                timestamp: new Date().toISOString(),
                                errorMessage: error,
                            },
                            'error',
                            'forever'
                        );
                        return of(refreshNeeded());
                    })
                );
            })
        )
    );

    storeComponentValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(storeComponentValue),
            exhaustMap((a) => {
                return this.store.pipe(
                    select(getSubmissionSectionById(a.sectionId)),
                    first(),
                    map(({ section }) => {
                        const componentUniqueId =
                            this.componentElementService.getUniqueId(
                                section.components[a.componentIndex]
                            );
                        this.triggerService.changeValue(
                            componentUniqueId,
                            a.value
                        );
                        return storeComponentValueSuccess({ ...a });
                    })
                );
            })
        )
    );

    startObservingTrigger$ = createEffect(() =>
        this.actions$.pipe(
            ofType(startObservingTrigger),
            exhaustMap((a) => {
                return this.store.pipe(
                    select(getSubmissionSectionById(a.sectionId)),
                    first(),
                    map(({ section }) => {
                        this.triggerService.observe(
                            section?.trigger,
                            section.id
                        );
                        return nullAction();
                    })
                );
            })
        )
    );

    loadDictionary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadDictionary),
            exhaustMap((a) => {
                return this.dataSourceService
                    .getExternalDataSourceMapping<
                        (AddressModel & ContactModel) & { id: string }
                    >(a.dictionaryExternalId)
                    .pipe(
                        map((response) => {
                            return updateDictionaryBulk({
                                dictionaryType: a.dictionaryType,
                                value: response.items as any[],
                                dictionaryUniqueIdType: a.dictionaryType,
                                label: response.items.map((item) => item.label),
                            });
                        })
                    );
            })
        )
    );

    upsertSubmission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(upsertOnboardingForm),
            exhaustMap((action) => {
                let observable: Observable<SubmissionApiResponse>;

                if (action.saveAs) {
                    observable = this.submissionService.createSubmission(
                        action.formName,
                        action.data,
                        action.formId,
                        action.inputForm,
                        action.inputDictionaries,
                        action.templateName,
                        action.status
                    );
                } else {
                    if (action.templateId) {
                        observable = this.submissionService.updateSubmission(
                            action.formName,
                            action.data,
                            action.formId,
                            action.inputForm,
                            action.inputDictionaries,
                            action.templateId,
                            action.templateName,
                            action.status
                        );
                    } else {
                        observable = this.submissionService.createSubmission(
                            action.formName,
                            action.data,
                            action.formId,
                            action.inputForm,
                            action.inputDictionaries,
                            action.templateName,
                            action.status
                        );
                    }
                }

                return observable.pipe(
                    map((response) => {
                        return addSubmission({ data: response });
                    }),
                    tap(() => {
                        if (action.saveAs) {
                            return of(null);
                        }
                        return this.router.navigate(['analyst', 'form-list']);
                    })
                );
            })
        )
    );

    deleteSubmission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteSubmission),
            exhaustMap((action) => {
                return this.submissionService
                    .deleteSubmission(action.submissionId)
                    .pipe(
                        map(() => {
                            return deleteSubmissionSuccess({
                                submissionId: action.submissionId,
                            });
                        })
                    );
            })
        )
    );

    changeSubmissionFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeSubmissionFilters),
            exhaustMap((action) => {
                if (action.filters.status === undefined) {
                    return of(loadSubmissions());
                }

                return this.submissionService
                    .getSubmissionByStatus(action.filters.status)
                    .pipe(
                        map((response) => {
                            return changeSubmissionFiltersSuccess({
                                filters: action.filters,
                                data: response.forms,
                            });
                        })
                    );
            })
        )
    );
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { triggerValueChanged } from '../store/submission/submission.action';

@Injectable({
    providedIn: 'root',
})
export class TriggerService {
    private observedKeys: Array<{ key: string; sections: string[] }> = [];

    constructor(private store: Store) {}

    observe(key: string, sectionId: string) {
        if (key) {
            const keyIndex = this.observedKeys.findIndex(
                this.observedKeyFinder(key)
            );
            this.observedKeys = [
                ...this.observedKeys.slice(0, keyIndex),
                {
                    key,
                    sections: [
                        ...(this.observedKeys.find(this.observedKeyFinder(key))
                            ?.sections ?? []),
                        sectionId,
                    ],
                },
                ...this.observedKeys.slice(keyIndex + 1),
            ];
            this.store.dispatch(triggerValueChanged({ sectionId, value: '' }));
        }
    }

    private observedKeyFinder(key: string) {
        return (k) => k.key === key;
    }

    changeValue(key: string, value: any) {
        const observedKey = this.observedKeys.find(this.observedKeyFinder(key));
        for (const section of observedKey?.sections ?? []) {
            this.store.dispatch(
                triggerValueChanged({ sectionId: section, value })
            );
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadTypes } from './store/prepopulated-data/prepopulated-data.action';
import { Observable } from 'rxjs';
import {
    isRefreshNeeded,
    IsRefreshNeededType,
} from './store/general/general.selector';
import { refreshSucceded } from './store/general/general.action';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private store: Store) {}

    public isRefreshNeeded$: Observable<IsRefreshNeededType>;

    ngOnInit() {
        this.store.dispatch(LoadTypes());
        this.isRefreshNeeded$ = this.store.select(isRefreshNeeded);
    }

    handleClick() {
        this.store.dispatch(refreshSucceded());
        window.location.reload();
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-accordion-header-content',
    templateUrl: './accordion-header-content.component.html',
    styleUrls: ['./accordion-header-content.component.scss'],
})
export class AccordionHeaderContentComponent {
    constructor() {}
}

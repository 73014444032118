import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Memoize } from '../../../../../core/decorators/memoize.decorator';
import { convertTitleCaseToKebabCase } from '../../../../../utils/text-utils';

@Component({
    selector: 'app-boolean-option',
    templateUrl: './boolean-option.component.html',
    styleUrls: ['./boolean-option.component.scss'],
})
export class BooleanOptionComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() defaultValue: string;
    @Input() readOnly: boolean;
    @Input() sectionId: string;
    @Input() clonedId: string;
    @Input() optionsMapLabel: string;

    @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
    constructor() {}

    handleModelChange($event: any) {
        this.valueChanged.emit($event);
    }

    @Memoize()
    getInputTestId(
        clonedId: string,
        sectionId: string,
        label: string,
        optionsMapLabel: string
    ) {
        return `${clonedId ?? sectionId}_${convertTitleCaseToKebabCase(
            optionsMapLabel
        )}_${convertTitleCaseToKebabCase(label)
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/\./g, '')
            .replace(/\//, '')}`;
    }
}

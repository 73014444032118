import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ComponentElementsService } from '../../core/component-elements.service';
import { FormValidatorService } from '../form-validator.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { Memoize } from '../../core/decorators/memoize.decorator';
import { convertTitleCaseToKebabCase } from '../../utils/text-utils';

@Component({
    selector: 'app-analyst-form-toggle-button',
    templateUrl: './analyst-form-toggle-button.component.html',
    styleUrls: ['./analyst-form-toggle-button.component.scss'],
})
export class AnalystFormToggleButtonComponent implements OnInit, OnChanges {
    @Input()
    public sectionId: string;

    @Input()
    public readOnly: boolean;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    clonedId: string;

    toggleStatus: boolean;

    constructor(
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit() {
        if (this.component?.value) {
            this.toggleStatus = this.component.value;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            if (changes.data.currentValue?.value) {
                this.toggleStatus = changes.data.currentValue.value;
            }
        }
    }

    handleToggleValueChanged($event: any) {
        this.valueChanged.emit($event);
        this.toggleStatus = $event;
    }

    getToggleLabel() {
        return this.componentElementsService.getLabel(this.component);
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }

    @Memoize()
    getTestId(
        sectionId: string,
        clonedId: string,
        component: EditComponentModel
    ) {
        return `${clonedId ?? sectionId}_${convertTitleCaseToKebabCase(
            this.getToggleLabel()
        )}`;
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    Submission,
    SubmissionDictionaryType,
    SubmissionsState,
    Trigger,
} from './model';
import { DefaultDictionary } from '../admin/model';
import { Section } from '../../models/section';
import { componentTreeAsAnArray } from '../../utils/component-tree';
import { SubmissionStatus } from '../../submission.service';

export const getSubmissionState =
    createFeatureSelector<SubmissionsState>('submission');

export interface GetDictionaryByTypeType<T> {
    dictionary: DefaultDictionary<T>;
}

export interface GetFormForAnalystSelectType {
    sections: Section[];
    templateId: string;
    templateName: string;
    formName: string;
    status: SubmissionStatus;
}

export interface GetSubmissionsType {
    submissions: Submission[];
}

export interface GetSubmissionSectionByIdType {
    section: Section;
}

export interface GetTriggerForSectionType {
    currentValue: string;
}

export interface GetTriggersType {
    triggers: Array<Trigger>;
}

export interface GetSubmissionFilters {
    filters: SubmissionsState['filters'];
}

export const getDictionaryByType = <T>(
    dictionaryName: SubmissionDictionaryType
) =>
    createSelector<any, SubmissionsState, GetDictionaryByTypeType<T>>(
        getSubmissionState,
        (state) => {
            return {
                dictionary: state.dictionaries[
                    dictionaryName
                ] as Array<unknown> as DefaultDictionary<T>,
            };
        }
    );

export const getDictionaries = createSelector(getSubmissionState, (state) => {
    return state.dictionaries;
});

export const getFormForAnalystSelect = (submissionId: string) =>
    createSelector<any, SubmissionsState, GetFormForAnalystSelectType>(
        getSubmissionState,
        (state) => {
            const submission = state.submissions.find(
                (submission) => submission.id === submissionId
            );
            return {
                sections: state.form.data,
                templateId: submission?.templateId,
                formName: submission?.formName,
                templateName: state.form.templateName,
                status: state.form.status,
            };
        }
    );

export const getSubmissions = createSelector<
    any,
    SubmissionsState,
    GetSubmissionsType
>(getSubmissionState, (state) => {
    return { submissions: state.submissions };
});

export const isSubmissionStateInitialized = createSelector(
    getSubmissionState,
    (state) => state.isInitialized
);

export const getSubmissionSectionById = (sectionId: string) =>
    createSelector<any, SubmissionsState, GetSubmissionSectionByIdType>(
        getSubmissionState,
        (state) => {
            const newForm = JSON.parse(JSON.stringify(state.form.data));

            const section = componentTreeAsAnArray(newForm, undefined).find(
                (section) => section?.id === sectionId
            );

            return { section };
        }
    );

export const getTriggerForSection = (sectionId: string) =>
    createSelector<any, SubmissionsState, GetTriggerForSectionType>(
        getSubmissionState,
        (state) => {
            return {
                currentValue:
                    state.triggers.find(
                        (trigger) => trigger.sectionId === sectionId
                    )?.value ?? 'false',
            };
        }
    );

export const getTriggers = createSelector<
    any,
    SubmissionsState,
    GetTriggersType
>(getSubmissionState, (state) => ({ triggers: state.triggers }));

export const getSubmissionFilters = createSelector<
    any,
    SubmissionsState,
    GetSubmissionFilters
>(getSubmissionState, (state) => ({ filters: state.filters }));

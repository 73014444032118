<div
    *ngIf="{
        dictionaries: getDictionaries$ | async,
        submission: (getFormForAnalystSelect$ | async),
        getTriggers: (getTriggers$ | async)
    } as state"
>
    <div class="section-preview-container flex justified-center">
        <app-filler-preview
            [submission]="state.submission"
            [triggers]="state.getTriggers"
        ></app-filler-preview>
        <div class="sections">
            <app-header
                [initialFormName]="state.submission.formName"
                [submissionStatus]="state.submission.status"
                [sections]="state.submission.sections"
                [dictionaries]="state.dictionaries"
                [templateId]="state.submission.templateId"
                [triggers]="state.getTriggers.triggers"
                [templateName]="state.submission.templateName"
                [formId]="formId"
            ></app-header>
            <div
                *ngFor="
                    let section of state.submission.sections;
                    trackBy: tracker
                "
                class="section-preview"
            >
                <app-analyst-form-section
                    *ngIf="section.component === menuComponent.Section"
                    [components]="section.components"
                    [number]="section.number"
                    [name]="section.name"
                    [sectionId]="section.id"
                    [sectionUniqueId]="section.uniqueId1"
                ></app-analyst-form-section>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpService } from './core/http.service';

export type GetCustomComponentResponseBody = Array<{
    _id: string;
    name: string;
    data: any[];
    createdAt: string;
    updatedAt: string;
}>;

export interface UpdateCustomComponentResponseBody {
    _id: string;
    name: string;
    data: any[];
    createdAt: string;
    updatedAt: string;
}

export interface UpdateCustomComponentRequestBody {
    updatedData: any[];
}

@Injectable({
    providedIn: 'root',
})
export class CustomComponentsApiService {
    constructor(private httpService: HttpService) {}

    getCustomComponents() {
        return this.httpService
            .get<GetCustomComponentResponseBody>('components')
            .send();
    }

    updateCustomComponent(componentId: string, data: any[]) {
        return this.httpService
            .put<
                UpdateCustomComponentResponseBody,
                UpdateCustomComponentRequestBody
            >(`components/${componentId}`)
            .withBody({ updatedData: data })
            .send();
    }

    deleteCustomComponent(componentId: string) {
        return this.httpService.delete(`components/${componentId}`).send();
    }
}

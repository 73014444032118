import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(private toastr: ToastrService) {}

    showError(message) {
        this.toastr.error(message);
    }

    showSuccess(message) {
        this.toastr.success(message);
    }
}

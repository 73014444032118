<app-new-address-step-label
    *ngIf="currentStep === 'stepLabel'"
    (labelChanged)="handleLabelChanged($event)"
    [label]="model.label"
    (nextStep)="moveToStep('stepCountry')"
></app-new-address-step-label>
<app-new-address-step-country
    *ngIf="currentStep === 'stepCountry'"
    [country]="model.country"
    (countryChange)="handleCountryChanged($event)"
    (nextStep)="moveToStepRegion()"
    (previousStep)="moveToStep('stepLabel')"
></app-new-address-step-country>
<app-new-address-step-region-us
    *ngIf="currentStep === 'stepRegionUS'"
    [region]="model.region"
    (regionChanged)="handleRegionChanged($event)"
    (nextStep)="moveToStep('stepZipCode')"
    (previousStep)="moveToStep('stepCountry')"
></app-new-address-step-region-us>
<app-new-address-step-region-ca
    *ngIf="currentStep === 'stepRegionCA'"
    [region]="model.region"
    (regionChanged)="handleRegionChanged($event)"
    (nextStep)="moveToStep('stepZipCode')"
    (previousStep)="moveToStep('stepCountry')"
></app-new-address-step-region-ca>
<app-new-address-step-region-general
    *ngIf="currentStep === 'stepRegionGeneral'"
    [region]="model.region"
    (regionChanged)="handleRegionChanged($event)"
    (nextStep)="moveToStep('stepZipCode')"
    (previousStep)="moveToStep('stepCountry')"
></app-new-address-step-region-general>
<app-new-address-step-zip-code
    *ngIf="currentStep === 'stepZipCode'"
    [zipCode]="model.zipCode"
    [mask]="getZipCodeMask()"
    (zipCodeChanged)="handleZipCodeChanged($event)"
    (nextStep)="moveToStep('stepCity')"
    (previousStep)="moveToStepRegion()"
></app-new-address-step-zip-code>
<app-new-address-step-city
    *ngIf="currentStep === 'stepCity'"
    [city]="model.city"
    (cityChanged)="handleCityChanged($event)"
    (nextStep)="moveToStep('stepAddressLines')"
    (previousStep)="moveToStep('stepZipCode')"
></app-new-address-step-city>
<app-new-address-step-address-lines
    *ngIf="currentStep === 'stepAddressLines'"
    [line1]="model.line1"
    [line2]="model.line2"
    [line3]="model.line3"
    (line1Changed)="handleLine1Changed($event)"
    (line2Changed)="handleLine2Changed($event)"
    (line3Changed)="handleLine3Changed($event)"
    (nextStep)="handleSave()"
    (previousStep)="moveToStep('stepCity')"
></app-new-address-step-address-lines>

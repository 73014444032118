<div class="flex aligned-center justified">
    <div class="flex gap20">
        <app-form-name
            [initialFormName]="initialFormName"
            (formNameChange)="handleFormNameChange($event)"
        ></app-form-name>
        <div class="flex aligned-center justified gap15">
            <app-select
                [value]="submissionStatus"
                [options]="submissionStates"
                (valueChanged)="handleValueChanged($event)"
            ></app-select>
        </div>
    </div>
    <div class="button-container">
        <button
            mat-raised-button
            color="accent"
            class="action-button medium-button"
            (click)="
                publishSubmission(sections, dictionaries, templateId, triggers)
            "
            [disabled]="
                publishButtonNotAvailable && submissionStatus !== 'approved'
            "
        >
            Publish
        </button>
        <app-action-buttons
            [isDisabled]="false"
            (saveForm)="
                handleSaveForm(
                    sections,
                    dictionaries,
                    templateId,
                    formName,
                    triggers,
                    templateName,
                    submissionStatus
                )
            "
            (saveAsForm)="
                handleSaveAsForm(
                    sections,
                    dictionaries,
                    templateId,
                    formName,
                    triggers,
                    templateName,
                    submissionStatus
                )
            "
            (closePage)="handleClosePage()"
        ></app-action-buttons>
    </div>
</div>

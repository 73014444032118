<mat-form-field appearance="outline" class="full-width label-outside">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        [matDatepicker]="picker"
        (click)="picker.open()"
        [ngModel]="value"
        (ngModelChange)="handleValueChange($event)"
        [disabled]="readOnly"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

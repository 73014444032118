import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
    getAllPublications,
    GetAllPublications,
} from '../../store/publication/publication.selector';
import { Store } from '@ngrx/store';
import { setFilters } from '../../store/general/general.action';
import { getFilters } from '../../store/general/general.selector';
import { ModalService } from '../../shared/modal.service';
import {
    PublicationPreviewModalComponent,
    PublicationPreviewModalComponentData,
    PublicationPreviewModalComponentResult,
} from './publication-preview-modal/publication-preview-modal.component';
import { Publication } from '../../store/publication/model';
import { markPublicationAsInvalid } from '../../store/publication/publication.action';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    public getAllPublications$: Observable<GetAllPublications>;
    public getFilters$: Observable<string>;

    constructor(private store: Store, private modalService: ModalService) {}

    ngOnInit(): void {
        this.getAllPublications$ = this.store.select(getAllPublications);
        this.getFilters$ = this.store.select(getFilters('publication'));
    }

    handleFilterClick($event: string) {
        this.store.dispatch(
            setFilters({ group: 'publication', value: $event })
        );
    }

    handlePublicationClick($event: string, getAllPublications: Publication[]) {
        this.modalService.openModal<
            PublicationPreviewModalComponent,
            PublicationPreviewModalComponentData,
            PublicationPreviewModalComponentResult
        >(
            PublicationPreviewModalComponent,
            {
                sections: getAllPublications.find(
                    (publication) => publication.id === $event
                ).inputForm,
            },
            (result) => {
                if (result?.markAsInvalid === true) {
                    this.store.dispatch(
                        markPublicationAsInvalid({ publicationId: $event })
                    );
                }
            },
            { width: '80%' }
        );
    }
}

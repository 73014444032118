<div
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <mat-form-field appearance="outline" class="full-width label-outside">
        <mat-label>{{ label }}</mat-label>
        <input
            matInput
            class="full-width"
            [ngModel]="value"
            (ngModelChange)="handleModelChanged($event)"
            [disabled]="readOnly"
            [attr.data-cy]="getInputTestId(clonedId, sectionId, label)"
        />
    </mat-form-field>
</div>

import { OnboardingSchema } from './model';
import {
    convertPascalCaseToTitleCase,
    convertTitleCaseToKebabCase,
} from '../../utils/text-utils';
import { MenuComponent } from '../../enums/components';

function getUniqueIdsFromObject(root: OnboardingSchema, sectionId: string) {
    return Object.keys(root)
        .filter((key) => key !== 'Models')
        .map((key) => {
            const label = convertPascalCaseToTitleCase(key);
            const value = convertTitleCaseToKebabCase(label);
            const schemaType = root[key];

            return {
                key: value,
                value: label,
                type:
                    schemaType.value === 'integer'
                        ? 'string'
                        : schemaType.value,
                isUsed: [
                    MenuComponent.OptionsMap,
                    MenuComponent.Repeater,
                    MenuComponent.Section,
                ].includes(schemaType?.componentType)
                    ? false
                    : schemaType.usedInSections.includes(sectionId),
                componentType: schemaType.componentType,
            };
        })
        .filter((item) => item !== undefined);
}

export function getUniqueIdsFromPath(
    schema: OnboardingSchema,
    path: string[],
    sectionId: string
) {
    let currentRoot = schema;
    let traversedPath = [];
    let currentRootType;
    path.forEach((pathPart) => {
        const traversedPathUniqueIds = getUniqueIdsFromPath(
            schema,
            traversedPath,
            sectionId
        );
        currentRootType = traversedPathUniqueIds.find((v) =>
            v.key.startsWith(pathPart)
        );
        if (!isBuiltInType(currentRootType.type)) {
            const rootUniqueIds = getUniqueIdsFromObject(
                currentRoot,
                sectionId
            );
            const newRootType = rootUniqueIds.find((v) =>
                v.key.startsWith(pathPart)
            );
            const modelName = newRootType.type.replace('[]', '');
            currentRoot = schema.Models[modelName];

            traversedPath.push(pathPart);
        }
    });
    if (traversedPath.length < path.length) {
        return [
            {
                ...currentRootType,
                type: currentRootType.type.replace('[]', ''),
            },
        ];
    }
    return getUniqueIdsFromObject(currentRoot, sectionId);
}

export function isBuiltInType(type: string) {
    return [
        'string',
        'string[]',
        'map',
        'Contact',
        'boolean',
        'Address',
        'Contact[]',
        'Address[]',
    ].includes(type);
}

<div class="label">{{ label }}</div>
<div
    *ngFor="let option of visibleOptions; let index = index; trackBy: tracker"
    [ngClass]="{
        'component-required': isComponentRequired(option),
        'component-valid': isComponentValid(option)
    }"
>
    <app-text-option
        *ngIf="option.type === 'text'"
        [label]="option.label"
        [value]="option.value"
        [defaultValue]="option.defaultValue"
        (valueChanged)="handleTextValueChange($event, index)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
        [optionsMapLabel]="label"
    ></app-text-option>
    <app-date-option
        *ngIf="option.type === 'date'"
        [label]="option.label"
        [value]="option.value"
        (valueChanged)="handleTextValueChange($event, index)"
        [readOnly]="readOnly"
    ></app-date-option>
    <app-boolean-option
        *ngIf="option.type === 'boolean'"
        [label]="option.label"
        [value]="option.value"
        [defaultValue]="option.defaultValue"
        (valueChanged)="handleTextValueChange($event, index)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
        [optionsMapLabel]="label"
    ></app-boolean-option>
</div>

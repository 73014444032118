<div
    class="accordion-container"
    [ngClass]="{
        bordered: !isCollapsed && showBorder,
        'accordion-container-dark': darkContent
    }"
>
    <app-accordion-header
        *ngIf="showHeader"
        (toggle)="handleCollapse()"
        [collapsed]="isCollapsed"
        [showBackground]="showBackground"
        [darkHeader]="darkHeader"
    >
        <ng-content select="app-accordion-header-content"></ng-content>
    </app-accordion-header>
    <ng-content
        select="app-accordion-content"
        *ngIf="!isCollapsed"
    ></ng-content>
</div>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    createPublication,
    createPublicationSuccess,
    fetchAllPublications,
    fetchAllPublicationsSuccess,
    markPublicationAsInvalid,
    markPublicationAsInvalidSuccess,
} from './publication.action';
import { exhaustMap, map } from 'rxjs/operators';
import { PublicationService } from '../../core/api/publication.service';
import { Router } from '@angular/router';
import { setFilters } from '../general/general.action';
import { of } from 'rxjs';
import { nullAction } from '../admin/admin.action';

@Injectable()
export class PublicationEffect {
    constructor(
        private actions$: Actions,
        private publicationService: PublicationService,
        private router: Router
    ) {}

    fetchAllPublications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fetchAllPublications),
            exhaustMap((a) => {
                return this.publicationService.fetch().pipe(
                    map((response) => {
                        return fetchAllPublicationsSuccess({ data: response });
                    })
                );
            })
        );
    });

    createPublication$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createPublication),
            exhaustMap((a) => {
                return this.publicationService
                    .create(
                        a.submissionId,
                        a.templateId,
                        a.dictionaries,
                        a.publication,
                        a.additionalNotes,
                        a.inputForm
                    )
                    .pipe(
                        map((response) => {
                            this.router.navigate(['analyst', 'form-list']);
                            return createPublicationSuccess({
                                id: response.id,
                                submissionName: response.submissionName,
                                additionalNotes: response.additionalNotes,
                                isActive: response.isActive,
                                inputForm: response.inputForm,
                                createdAt: response.createdAt,
                            });
                        })
                    );
            })
        );
    });

    setFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setFilters),
            exhaustMap((action) => {
                if (action.group === 'publication') {
                    return this.publicationService.fetch(action.value).pipe(
                        map((response) => {
                            return fetchAllPublicationsSuccess({
                                data: response,
                            });
                        })
                    );
                }

                return of(nullAction());
            })
        )
    );

    markPublicationAsInvalid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(markPublicationAsInvalid),
            exhaustMap((action) => {
                return this.publicationService
                    .markAsInvalid(action.publicationId)
                    .pipe(
                        map((response) => {
                            return markPublicationAsInvalidSuccess(response);
                        })
                    );
            })
        )
    );
}

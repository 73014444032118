import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DataSourceEntry } from '../model/data-source.entry';
import { MongoEntity } from '../../models/db-schema.model';

export type FormListRow<ExtraDataType> = MongoEntity<{
    formName: string;
    extraData: ExtraDataType;
    templateName: string;
}>;

export interface DeleteClickEvent<ExtraDataType> {
    element: DataSourceEntry<ExtraDataType>;
}

export interface TableColumn {
    key: string;
    visibleName: string;
    visibleValue: (item) => string;
}

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent<ExtraDataRow> implements OnInit, OnChanges {
    @ViewChild(MatSort)
    public sort: MatSort;

    @Input()
    public columns: Array<TableColumn>;

    @Input()
    public dataSourceInput: Array<DataSourceEntry<ExtraDataRow>>;

    @Output()
    public itemClick: EventEmitter<FormListRow<ExtraDataRow>> =
        new EventEmitter<FormListRow<ExtraDataRow>>();

    @Output()
    public deleteClick: EventEmitter<DeleteClickEvent<ExtraDataRow>> =
        new EventEmitter<DeleteClickEvent<ExtraDataRow>>();

    public dataSource: MatTableDataSource<DataSourceEntry<ExtraDataRow>>;

    public displayedColumns: string[] = [];

    constructor() {}

    ngOnInit(): void {
        this.displayedColumns = this.columns.map((column) => column.key);
        this.getFormList();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getFormList();
    }

    getFormList() {
        this.dataSource = new MatTableDataSource<DataSourceEntry<ExtraDataRow>>(
            this.dataSourceInput
        );
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item, property) => {
            return item[property].toLowerCase();
        };
    }

    handleRowClick(row: FormListRow<ExtraDataRow>) {
        this.itemClick.emit(row);
    }

    handleClickOnDelete(
        $event: MouseEvent,
        element: DataSourceEntry<ExtraDataRow>
    ) {
        $event.stopPropagation();
        this.deleteClick.emit({ element });
    }
}

<div
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <mat-checkbox
        [ngModel]="value"
        (ngModelChange)="handleModelChange($event)"
        [disabled]="readOnly"
        [attr.data-cy]="getInputTestId(clonedId, sectionId, label)"
        >{{ label }}</mat-checkbox
    >
</div>

import { Component, Input, OnInit } from '@angular/core';
import { SubmissionStatus } from '../../../submission.service';
import { SelectOption } from '../../../shared/select/select.component';
import {
    convertKebabCaseToTitleCase,
    firstLetterUpperCase,
} from '../../../utils/text-utils';
import { LoginResponse } from '../../../login/login.service';
import { StorageService } from '../../../core/storage.service';
import {
    changeSubmissionStatus,
    upsertOnboardingForm,
} from '../../../store/submission/submission.action';
import { Store } from '@ngrx/store';
import { Section } from '../../../models/section';
import {
    SubmissionDictionaryType,
    SubmissionsState,
    Trigger,
} from '../../../store/submission/model';
import { createPublication } from '../../../store/publication/publication.action';
import {
    SaveAsModalComponent,
    SaveAsSubmissionConfirmer,
    SaveAsSubmissionModalDataResult,
} from '../../../new-form/save-as-modal/save-as-modal.component';
import { Confirmable } from '../../../core/decorators/confirmable.decorator';
import { MatDialog } from '@angular/material/dialog';
import { Address } from '../../../models/address';
import { Contact } from '../../../models/contact';
import { FormFillerService } from '../form-filler.service';
import { Router } from '@angular/router';
import pluralize from 'pluralize';
import {
    PublishModalComponent,
    PublishModalComponentResult,
} from './publish-modal/publish-modal.component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input()
    public initialFormName: string;

    @Input()
    public submissionStatus: SubmissionStatus;

    @Input()
    public sections: Section[];

    @Input()
    public dictionaries: SubmissionsState['dictionaries'];

    @Input()
    public templateId: string;

    @Input()
    public triggers: Trigger[];

    @Input()
    public templateName: string;

    @Input()
    public formId: string;

    public formName: string;
    public submissionStates: Array<SelectOption>;
    public publishButtonNotAvailable: boolean;

    private static handler;
    private static staticDialog;

    constructor(
        private storageService: StorageService,
        private store: Store,
        private dialog: MatDialog,
        private router: Router,
        private service: FormFillerService
    ) {
        HeaderComponent.handler = this;
        HeaderComponent.staticDialog = dialog;
    }

    ngOnInit(): void {
        const authInfo = this.storageService.getValue<LoginResponse>(
            'token',
            'temporary'
        );
        this.submissionStates = ['draft', 'ready-for-review', 'approved'].map(
            (opt) => ({
                key: opt,
                value: convertKebabCaseToTitleCase(opt),
                disabled:
                    opt === 'approved'
                        ? !authInfo.permissions.includes('submission:approve')
                        : false,
            })
        );

        this.publishButtonNotAvailable =
            !authInfo.permissions.includes('submission:go-live');
    }

    handleFormNameChange($event: string) {
        this.formName = $event;
    }

    handleValueChanged($event: any) {
        this.store.dispatch(changeSubmissionStatus({ newStatus: $event }));
    }

    publishSubmission(
        sections: Section[],
        dictionaries: SubmissionsState['dictionaries'],
        templateId: string,
        triggers: Trigger[]
    ) {
        const dialogRef = this.dialog.open<
            PublishModalComponent,
            void,
            PublishModalComponentResult
        >(PublishModalComponent);

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let submission = this.buildSubmission(sections, triggers);
                this.store.dispatch(
                    createPublication({
                        publication: submission,
                        submissionId: this.formId,
                        templateId: templateId,
                        dictionaries,
                        additionalNotes: result.additionalNotes,
                        inputForm: sections,
                    })
                );
            }
        });
    }

    handleSaveForm(
        sections: Section[],
        dictionaries: SubmissionsState['dictionaries'],
        templateId: string,
        initialFormName: string,
        triggers: Trigger[],
        templateName: string,
        status: SubmissionStatus
    ) {
        let submission = this.buildSubmission(sections, triggers);

        this.store.dispatch(
            upsertOnboardingForm({
                formName: this.formName ?? initialFormName,
                formId: this.formId,
                data: {
                    ...submission,
                    ...this.mapDictionariesToSubmissionObject(dictionaries),
                },
                inputForm: sections,
                inputDictionaries: dictionaries,
                templateId: templateId,
                saveAs: false,
                templateName,
                status,
            })
        );
    }

    handleSaveAsForm(
        sections: Section[],
        dictionaries: SubmissionsState['dictionaries'],
        templateId: string,
        initialFormName: string,
        triggers: Trigger[],
        templateName: string,
        status: SubmissionStatus
    ) {
        this.saveAsForm({
            confirmer: new SaveAsSubmissionConfirmer(),
            formName: this.formName ?? initialFormName,
            sections,
            dictionaries,
            triggers,
            templateName,
            status,
            templateId,
        });
    }

    @Confirmable({
        handler: () => HeaderComponent.handler,
        modal: SaveAsModalComponent,
        dialogFactory: () => {
            return HeaderComponent.staticDialog;
        },
    })
    saveAsForm(result: SaveAsSubmissionModalDataResult) {
        let submission = this.buildSubmission(result.sections, result.triggers);

        this.store.dispatch(
            upsertOnboardingForm({
                formName: result.formName,
                formId: this.formId,
                data: {
                    ...submission,
                    ...this.mapDictionariesToSubmissionObject(
                        result.dictionaries
                    ),
                },
                inputForm: result.sections,
                inputDictionaries: result.dictionaries,
                templateId: result.templateId,
                saveAs: true,
                templateName: result.templateName,
                status: result.status,
            })
        );
    }

    handleClosePage() {
        this.router.navigate(['analyst', 'form-list']);
    }

    private mapDictionariesToSubmissionObject(
        dictionaries: SubmissionsState['dictionaries']
    ) {
        return Object.keys(dictionaries)
            .map((key) => {
                const dictionaryType = key as SubmissionDictionaryType;
                if (dictionaryType === 'address') {
                    return this.mapAddressDictionary(dictionaries, key);
                } else if (dictionaryType === 'contact') {
                    return this.mapContactDictionary(dictionaries, key);
                }
                throw Error('Unknown dictionary type');
            })
            .reduce((acc, curr) => ({ ...acc, ...curr } as any), {});
    }

    private mapAddressDictionary(
        dictionaries: SubmissionsState['dictionaries'],
        key: string
    ) {
        const addresses = dictionaries[key] as Array<Address>;
        return {
            [firstLetterUpperCase(pluralize(key))]: addresses.map(
                ({ model, id }) => {
                    let addressCountry;
                    if (model.country === 'US') {
                        addressCountry = 'USA';
                    } else if (model.country === 'CA') {
                        addressCountry = 'Canada';
                    } else {
                        addressCountry = model.country;
                    }
                    return {
                        id,
                        addressLabel: model.label,
                        line1: model.line1,
                        line2: model.line2,
                        line3: model.line3,
                        city: model.city,
                        state: model.region,
                        country: addressCountry,
                        zipCode: model.zipCode,
                    };
                }
            ),
        };
    }

    private mapContactDictionary(
        dictionaries: SubmissionsState['dictionaries'],
        key: string
    ) {
        const contacts = dictionaries[key] as Array<Contact>;
        return {
            [firstLetterUpperCase(pluralize(key))]: contacts.map(
                ({ id, model }) => ({
                    id: id,
                    contactType: model.contactType,
                    name: model.name,
                    phone: model.phone,
                    email: model.email,
                })
            ),
        };
    }

    private buildSubmission(sections: Section[], triggers: Trigger[]) {
        return sections.reduce((acc, section) => {
            const sectionData = this.service.getDataFromSection(
                section,
                triggers
            );
            return {
                ...acc,
                ...sectionData,
            };
        }, {});
    }
}

import { createAction, props } from '@ngrx/store';
import { Section } from '../../models/section';
import { MongoEntity } from '../../models/db-schema.model';
import { SubmissionsState } from '../submission/model';
import { SubmissionStatus } from '../../submission.service';

//region Old action definition

const GET_FORMS = '[Forms] Get Forms';
const GET_FORMS_SUCCESS = '[Forms] Get Forms Success';
const GET_FORMS_FAILURE = '[Forms] Get Forms Failure';

const SET_FORM = '[Forms] Set Form';

const DELETE_FORM = '[Forms] Delete Form';
const DELETE_FORM_SUCCESS = '[Forms] Delete Form Success';
const EDIT_FORM = '[Forms] Edit Form';

const UPLOAD_FORM_JSON = '[Forms] Upload Form JSON';
const UPLOAD_FORM_JSON_SUCCESS = '[Forms] Upload Form JSON Success';
const UPLOAD_FORM_JSON_FAILURE = '[Forms] Upload Form JSON Failure';
const UPLOAD_FORM_JSON_RESET = '[Forms] Upload Form JSON Reset';

const GET_CUSTOM_COMPONENTS = '[Custom Components] Get Custom Components';
const GET_CUSTOM_COMPONENTS_SUCCESS =
    '[Custom Components] Get Custom Components Success';
const GET_CUSTOM_COMPONENTS_FAILURE =
    '[Custom Components] Get Custom Components Failure';

const UPLOAD_CUSTOM_COMPONENT_JSON =
    '[Custom Components] Upload Custom Component JSON';
const UPLOAD_CUSTOM_COMPONENT_JSON_SUCCESS =
    '[Custom Components] Upload Custom Component JSON Success';
const UPLOAD_CUSTOM_COMPONENT_JSON_FAILURE =
    '[Custom Components] Upload Custom Component JSON Failure';

const DELETE_CUSTOM_COMPONENT_JSON =
    '[Custom Components] Delete Custom Component JSON';
const DELETE_CUSTOM_COMPONENT_JSON_SUCCESS =
    '[Custom Components] Delete Custom Component JSON Success';
const DELETE_CUSTOM_COMPONENT_JSON_FAILURE =
    '[Custom Components] Delete Custom Component JSON Failure';

const SUBMIT_FORM = '[Form] Submit';
const SUBMIT_FORM_SUCCESS = '[Form] Submit Success';

//endregion

const LOAD_LATEST_DATA_TO_FORM = '[Analyst Form] Load latest data to form';

//region Old actions
export const getForms = createAction(GET_FORMS, props<{ formName: string }>());

export const deleteForm = createAction(
    DELETE_FORM,
    props<{ formId: string }>()
);

export const deleteFormSuccess = createAction(
    DELETE_FORM_SUCCESS,
    props<{ formId: string }>()
);

export const editForm = createAction(EDIT_FORM, props<{ formId: string }>());

export const getFormsSuccess = createAction(
    GET_FORMS_SUCCESS,
    props<{
        response: Array<{
            id: string;
            formName: string;
            data: Section[];
            createdAt: string;
            updatedAt: string;
            formId: string;
            dictionaries: any;
            status: SubmissionStatus;
        }>;
    }>()
);

export const getFormsFailure = createAction(GET_FORMS_FAILURE, props<any>());

export const setForm = createAction(SET_FORM, props<any>());

export const uploadFormJSON = createAction(
    UPLOAD_FORM_JSON,
    props<{ id: string; form: any }>()
);

export const uploadFormJSONSuccess = createAction(
    UPLOAD_FORM_JSON_SUCCESS,
    props<any>()
);

export const uploadFormJSONFailure = createAction(
    UPLOAD_FORM_JSON_FAILURE,
    props<any>()
);

export const uploadFormJSONReset = createAction(UPLOAD_FORM_JSON_RESET);

export const getCustomComponents = createAction(GET_CUSTOM_COMPONENTS);

export const getCustomComponentsSuccess = createAction(
    GET_CUSTOM_COMPONENTS_SUCCESS,
    props<any>()
);

export const getCustomComponentsFailure = createAction(
    GET_CUSTOM_COMPONENTS_FAILURE,
    props<any>()
);

export const uploadCustomComponentJSON = createAction(
    UPLOAD_CUSTOM_COMPONENT_JSON,
    props<any>()
);

export const uploadCustomComponentJSONSuccess = createAction(
    UPLOAD_CUSTOM_COMPONENT_JSON_SUCCESS,
    props<any>()
);

export const uploadCustomComponentJSONFailure = createAction(
    UPLOAD_CUSTOM_COMPONENT_JSON_FAILURE,
    props<any>()
);

export const deleteCustomComponentJSON = createAction(
    DELETE_CUSTOM_COMPONENT_JSON,
    props<any>()
);

export const deleteCustomComponentJSONSuccess = createAction(
    DELETE_CUSTOM_COMPONENT_JSON_SUCCESS,
    props<any>()
);

export const deleteCustomComponentJSONFailure = createAction(
    DELETE_CUSTOM_COMPONENT_JSON_FAILURE,
    props<any>()
);

export const submitForm = createAction(SUBMIT_FORM, props<any>());

export const submitFormSuccess = createAction(
    SUBMIT_FORM_SUCCESS,
    props<any>()
);

//endregion

export const loadLatestDataToForm = createAction(
    LOAD_LATEST_DATA_TO_FORM,
    props<{
        formId: string;
        latestData: Section[];
        formInput: Array<MongoEntity<{ formName: string; data: any }>>;
        dictionaries: SubmissionsState['dictionaries'];
    }>()
);

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-new-address-step-city',
    templateUrl: './step-city.component.html',
    styleUrls: ['./step-city.component.scss'],
})
export class StepCityComponent {
    @Input()
    public city: string;

    @Output()
    public cityChanged: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public nextStep: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public previousStep: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    handleCityChanged($event: any) {
        this.cityChanged.emit($event);
    }

    handleNextStep() {
        this.nextStep.emit();
    }

    handlePreviousStep() {
        this.previousStep.emit();
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
    @Input()
    public showBackground: boolean;

    @Input()
    public showBorder: boolean = false;

    @Input()
    public darkHeader: boolean = false;

    @Input()
    public darkContent: boolean = false;

    @Input()
    public showHeader: boolean = true;

    @Input()
    public isCollapsed: boolean = false;

    @Output()
    public toggle: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    handleCollapse() {
        this.toggle.emit();
    }
}

<mat-form-field
    appearance="outline"
    class="form-input full-width"
    [ngClass]="{ 'label-outside': label !== '' }"
>
    <mat-label *ngIf="label !== ''">{{ label }}</mat-label>
    <mat-select
        [ngModel]="value"
        (ngModelChange)="handleValueChanged($event)"
        [placeholder]="placeholder"
        ngDefaultControl
    >
        <mat-option
            *ngFor="let option of options"
            [value]="option.key"
            [disabled]="option.disabled"
            >{{ option.value }}</mat-option
        >
    </mat-select>
</mat-form-field>

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { fetchAllPublications } from '../store/publication/publication.action';
import { getForms } from '../store/form-api/form-api.actions';

@Injectable({
    providedIn: 'root',
})
export class PublicationListResolver implements Resolve<any> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.store.dispatch(fetchAllPublications());
        this.store.dispatch(getForms({ formName: '' }));
    }
}

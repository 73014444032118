import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralState } from './model';

export interface IsRefreshNeededType {
    isRefreshNeeded: boolean;
}

export const getGeneralState = createFeatureSelector<GeneralState>('general');

export const isRefreshNeeded = createSelector<
    any,
    GeneralState,
    IsRefreshNeededType
>(getGeneralState, (state) => ({
    isRefreshNeeded: state.refreshNeeded,
}));

export const getFilters = (group: keyof GeneralState['filters']) =>
    createSelector<any, GeneralState, string>(
        getGeneralState,
        (state) => state.filters[group]
    );

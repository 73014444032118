<span>Please specify country</span>
<mat-form-field class="full-width">
    <mat-label>Country</mat-label>
    <mat-select
        [ngModel]="country"
        (ngModelChange)="handleCountryChange($event)"
    >
        <mat-option value="US">US</mat-option>
        <mat-option value="CA">CA</mat-option>
        <mat-option value="General">General</mat-option>
    </mat-select>
</mat-form-field>

<div class="flex">
    <button
        mat-raised-button
        (click)="handlePreviousStep()"
        style="flex-grow: 1"
    >
        Previous
    </button>
    <button
        mat-raised-button
        class="ml-1"
        (click)="handleNextStep()"
        style="flex-grow: 1"
    >
        Next
    </button>
</div>

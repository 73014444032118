<div
    *ngIf="{
        getAllPublications: getAllPublications$ | async,
        getFilters: getFilters$ | async
    } as state"
>
    <div class="flex">
        <app-filters
            [activeFilterId]="state.getFilters"
            (filterClick)="handleFilterClick($event)"
        ></app-filters>
        <app-publication-list
            [publications]="state.getAllPublications"
            (publicationClick)="
                handlePublicationClick($event, state.getAllPublications)
            "
            class="full-width"
        >
        </app-publication-list>
    </div>
</div>

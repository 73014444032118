import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-new-address-step-label',
    templateUrl: './step-label.component.html',
    styleUrls: ['./step-label.component.scss'],
})
export class StepLabelComponent {
    constructor() {}

    @Input()
    public label: string;

    @Output()
    public labelChanged: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public nextStep: EventEmitter<any> = new EventEmitter<any>();

    handleLabelChange($event: any) {
        this.labelChanged.emit($event);
    }

    handleNextStep() {
        this.nextStep.emit();
    }
}

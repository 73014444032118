<mat-form-field
    appearance="outline"
    class="full-width form-input label-outside"
    [style]="getPlaceholder(value)"
    floatLabel="always"
>
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        ngDefaultControl
        [ngModel]="value"
        (ngModelChange)="handleValueChanged($event)"
        [readonly]="isReadOnly"
        [ngClass]="{ 'no-select': isReadOnly }"
    />
</mat-form-field>
<mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>

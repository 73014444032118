import { Injectable } from '@angular/core';
import { ComponentElementsService } from '../core/component-elements.service';
import { componentTreeAsAnArray, isContainer } from '../utils/component-tree';
import { MenuComponent } from '../enums/components';
import { Section } from '../models/section';
import { EditComponentModel } from '../models/db-schema.model';
import { Option } from '../models/components/options-map';

interface RequiredComponent<Value = unknown> {
    constraints: {
        minLength: number;
        maxLength: number;
    };
    value: Value;
    componentType: MenuComponent;
}

type OptionsMapRequiredComponent = RequiredComponent<Array<Option>>;
type CommonRequiredComponent = RequiredComponent<string>;

function isOptionsMapRequiredComponent(
    component: RequiredComponent
): component is OptionsMapRequiredComponent {
    return component.componentType === MenuComponent.OptionsMap;
}

function isCommonRequiredComponent(
    component: RequiredComponent
): component is CommonRequiredComponent {
    return component.componentType !== MenuComponent.OptionsMap;
}

@Injectable({
    providedIn: 'root',
})
export class FormValidatorService {
    constructor(private componentElementsService: ComponentElementsService) {}

    isValidForm(sections: Section[]) {
        const formHasRequiredComponents = componentTreeAsAnArray(
            sections,
            undefined
        )
            .filter(
                (component) =>
                    !isContainer(component) &&
                    component.component !== MenuComponent.OptionsMap
            )
            .map((component) => {
                return this.checkRequiredForCommonComponent(component);
            })
            .reduce((acc, curr) => acc || curr, false);

        const allOptionsMapComponentsAreFilledIn = componentTreeAsAnArray(
            sections,
            undefined
        )
            .filter(
                (component) => component.component === MenuComponent.OptionsMap
            )
            .map((component) => {
                return this.isOptionsMapContentFilledIn(component);
            })
            .reduce((acc, curr) => acc && curr, true);

        return !formHasRequiredComponents && allOptionsMapComponentsAreFilledIn;
    }

    requiredFieldsAreFilledIn(components: EditComponentModel[]) {
        const requiredComponents = components
            ?.filter((component) => {
                if (!component.elements) {
                    return false;
                }
                if (component.component === MenuComponent.OptionsMap) {
                    const mapContent =
                        this.componentElementsService.getOptionsMapContent(
                            component
                        );

                    if (!mapContent) {
                        return true;
                    }
                    const hasRequiredOptions = mapContent.some(
                        (option) => option.required
                    );
                    return hasRequiredOptions;
                }
                return this.componentElementsService.getConstraint(
                    component,
                    'Required'
                );
            })
            ?.map<RequiredComponent>((component) => {
                return {
                    constraints: {
                        minLength:
                            this.componentElementsService.getConstraint<number>(
                                component,
                                'MinLength'
                            ),
                        maxLength:
                            this.componentElementsService.getConstraint<number>(
                                component,
                                'MaxLength'
                            ),
                    },
                    value: component.value ?? component.defaultValue?.value,
                    componentType: component.component as MenuComponent,
                };
            });
        if ((requiredComponents?.length ?? 0) === 0) {
            return true;
        }

        const indexOfFirstRequiredComponentWithoutValue =
            requiredComponents?.findIndex((requiredComponent) => {
                if (isOptionsMapRequiredComponent(requiredComponent)) {
                    return this.checkRequiredItemsForOptionsMap(
                        requiredComponent
                    );
                } else if (isCommonRequiredComponent(requiredComponent)) {
                    return this.checkRequiredComponent(requiredComponent);
                }
                return false;
            });

        return indexOfFirstRequiredComponentWithoutValue === -1;
    }

    private checkRequiredItemsForOptionsMap(
        component: OptionsMapRequiredComponent
    ) {
        if (component.value === undefined) {
            return true;
        }
        const requiredOptions = component.value?.filter(
            (option) => option.required
        );
        return requiredOptions?.some((option) =>
            [undefined, ''].includes(option.value ?? option.defaultValue)
        );
    }

    private checkRequiredComponent(component: CommonRequiredComponent) {
        if (
            component.constraints.minLength !== undefined ||
            component.constraints.minLength !== undefined
        ) {
            let minLengthConstraint = true;
            let maxLengthConstraint = true;
            if (component.constraints.minLength) {
                minLengthConstraint =
                    (component.value?.length ?? 0) <
                    component.constraints.minLength;
            }

            if (component.constraints.maxLength) {
                maxLengthConstraint =
                    (component.value?.length ?? 0) >
                    component.constraints.maxLength;
            }

            return minLengthConstraint || maxLengthConstraint;
        }
        return [undefined, ''].includes(component.value);
    }

    private checkRequiredForCommonComponent(component: EditComponentModel) {
        const requiredConstraint = this.componentElementsService.getConstraint(
            component,
            'Required'
        );

        if (requiredConstraint) {
            return component?.value === '' || component?.value === undefined;
        }
        return false;
    }

    private isOptionsMapContentFilledIn(component: EditComponentModel) {
        if (component.value === undefined) {
            return false;
        }

        return component.value.every((v) => (v.value ?? '') !== '');
    }
}

<div
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <mat-slide-toggle
        [ngModel]="toggleStatus"
        (ngModelChange)="handleToggleValueChanged($event)"
        class="toggle-button"
        [disabled]="readOnly"
        [attr.data-cy]="getTestId(sectionId, clonedId, component)"
        >{{ getToggleLabel() }}
    </mat-slide-toggle>
</div>

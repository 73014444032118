import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-analyst-form-section-repeater-indicator',
    templateUrl: './analyst-form-section-repeater-indicator.component.html',
    styleUrls: ['./analyst-form-section-repeater-indicator.component.scss'],
})
export class AnalystFormSectionRepeaterIndicatorComponent {
    @Input()
    public repeaterSize: number;

    @Input()
    public elementIndex: number;

    @Input()
    public name: string;

    @Input()
    public sectionId: string;

    @Input()
    public clonedId: string;

    @Output()
    public deleteElement: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}

    handleRemove() {
        this.deleteElement.emit();
    }

    getRepeaterAnchor(index: number) {
        return `${this.clonedId ?? this.sectionId}-${index}`;
    }
}

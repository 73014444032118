<app-new-address
    #newComponent
    *ngIf="optionType === 'address'"
    [initialModel]="initialModel"
    (save)="handleSave()"
>
</app-new-address>
<app-new-contact
    #newComponent
    *ngIf="optionType === 'contact'"
    [initialModel]="initialModel"
    (save)="handleSave()"
></app-new-contact>
<app-add-new-value
    #newComponent
    *ngIf="optionType === 'simple'"
    [initialModel]="initialModel"
    (save)="handleSave()"
></app-add-new-value>

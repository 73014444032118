<div
    class="accordion-header-container"
    [ngClass]="{ 'no-background': !showBackground, 'dark-header': darkHeader }"
>
    <ng-content></ng-content>
    <div (click)="handleCollapse()" class="flex pointer">
        <mat-icon *ngIf="collapsed; else arrowDown">arrow_right</mat-icon>
        <ng-template #arrowDown>
            <mat-icon>arrow_drop_down</mat-icon>
        </ng-template>
    </div>
</div>

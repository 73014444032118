<div class="form-list">
    <table mat-table [dataSource]="dataSource" class="full-width" matSort>
        <ng-container
            *ngFor="let column of columns"
            [matColumnDef]="column.key"
        >
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ column.visibleName }}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container
                    *ngIf="column.key === 'actionButtons'; else simpleValue"
                >
                    <div>
                        <span (click)="handleClickOnDelete($event, element)">
                            <mat-icon
                                color="warn"
                                fontSet="material-icons-outlined"
                                >delete</mat-icon
                            >
                        </span>
                    </div>
                </ng-container>
                <ng-template #simpleValue>
                    {{ column.visibleValue(element) }}
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
            class="row-item"
            [attr.data-cy]="'row-' + row.extraData"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="handleRowClick(row)"
        ></tr>
    </table>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-form-name',
    templateUrl: './form-name.component.html',
    styleUrls: ['./form-name.component.scss'],
})
export class FormNameComponent {
    @Input() initialFormName: string;
    @Output() formNameChange = new EventEmitter<string>();

    public formName: string;
    constructor() {}

    handleFormNameChanged($event: any) {
        this.formNameChange.emit($event);
    }
}

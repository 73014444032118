import { ActionReducerMap } from '@ngrx/store';
import { formApiReducer, FormApiState } from './form-api/form-api.reducer';
import { adminReducer } from './admin/admin.reducer';
import {
    prepopulatedDataReducer,
    PrepopulatedDataState,
} from './prepopulated-data/prepopulated-data.reducer';
import {
    customComponentsReducer,
    CustomComponentsState,
} from './custom-components/custom-components.reducer';
import { AdminState } from './admin/model';
import { SubmissionsState } from './submission/model';
import { submissionReducer } from './submission/submission.reducer';
import { dataStoreReducer } from './data-store/data-store.reducer';
import { DataStoreState } from './data-store/model';
import { GeneralState } from './general/model';
import { generalReducer } from './general/general.reducer';
import { PublicationState } from './publication/model';
import { publicationReducer } from './publication/publication.reducer';

export interface ReducerState {
    forms: FormApiState;
    admin: AdminState;
    prepopulatedData: PrepopulatedDataState;
    customComponents: CustomComponentsState;
    submission: SubmissionsState;
    dataStore: DataStoreState;
    general: GeneralState;
    publication: PublicationState;
}

export const reducers: ActionReducerMap<ReducerState> = {
    forms: formApiReducer,
    admin: adminReducer,
    prepopulatedData: prepopulatedDataReducer,
    customComponents: customComponentsReducer,
    submission: submissionReducer,
    dataStore: dataStoreReducer,
    general: generalReducer,
    publication: publicationReducer,
};

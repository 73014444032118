<div class="filters-pane" *ngIf="{ getForms: getForms$ | async } as state">
    <div class="filters-section" [ngClass]="{ hidden: !isContentVisible }">
        <div class="filters-section-header">Template Name</div>
        <div
            class="filters-section-item"
            [ngClass]="{ active: activeFilterId === '' }"
            (click)="handleClick('')"
        >
            All
        </div>
        <div
            *ngFor="let form of state.getForms.forms; let last = last"
            class="filters-section-item"
            [ngClass]="{ active: activeFilterId === form._id }"
            (click)="handleClick(form._id)"
        >
            {{ form.formName }}
            <ng-container *ngIf="last && !isContentVisible">
                {{ setContentVisible() }}
            </ng-container>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { MenuComponent } from '../enums/components';
import { Component } from '../models/section';
import { ComponentElementService } from './component-element.service';
import { EditComponentModel } from '../models/db-schema.model';
import { Option } from '../models/components/options-map';

@Injectable({
    providedIn: 'root',
})
export class ComponentElementsService {
    constructor(private componentElementService: ComponentElementService) {}

    getLabel(component: EditComponentModel) {
        return component.elements.find(this.componentElementService.isLabel)
            ?.defaultValue;
    }

    getAllowedValues(component: EditComponentModel): string {
        return component.elements.find(
            this.componentElementService.isAllowedValues
        )?.defaultValue;
    }

    getConstraint<R = boolean>(component: EditComponentModel, name: string): R {
        return component.elements.find((element) =>
            this.componentElementService.isConstraint(element, name)
        )?.defaultValue;
    }

    getUniqueId(component: EditComponentModel) {
        return component?.elements?.find(
            this.componentElementService.isUniqueId
        )?.defaultValue;
    }

    getDefaultValues(component: EditComponentModel) {
        const optionsMap = component.elements.find(
            this.componentElementService.isOptionsMapContent
        );

        if (optionsMap) {
            return optionsMap.defaultValue.map((value) => ({
                [value.name]: value.defaultValue,
            }));
        }

        return [];
    }

    getOptionsMapContent(component: EditComponentModel): Array<Option> {
        return component.elements.find(
            this.componentElementService.isOptionsMapContent
        ).defaultValue;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalystFormSectionComponent } from './analyst-form-section/analyst-form-section.component';
import { AnalystFormSectionElementComponent } from './analyst-form-section/analyst-form-section-element/analyst-form-section-element.component';
import { AnalystOptionsMapComponent } from './analyst-form-section/analyst-form-section-element/analyst-options-map/analyst-options-map.component';
import { TextOptionComponent } from './analyst-form-section/analyst-form-section-element/analyst-options-map/text-option/text-option.component';
import { DateOptionComponent } from './analyst-form-section/analyst-form-section-element/analyst-options-map/date-option/date-option.component';
import { BooleanOptionComponent } from './analyst-form-section/analyst-form-section-element/analyst-options-map/boolean-option/boolean-option.component';
import { AnalystFormTextBoxComponent } from './analyst-form-text-box/analyst-form-text-box.component';
import { AnalystFormCheckboxComponent } from './analyst-form-checkbox/analyst-form-checkbox.component';
import { AnalystFormRadioButtonComponent } from './analyst-form-radio-button/analyst-form-radio-button.component';
import { AnalystFormSelectBoxComponent } from './analyst-form-select-box/analyst-form-select-box.component';
import { AnalystFormSectionRepeaterIndicatorComponent } from './analyst-form-section-repeater-indicator/analyst-form-section-repeater-indicator.component';
import { AnalystFormYesNoButtonComponent } from './analyst-form-yes-no-button/analyst-form-yes-no-button.component';
import { AnalystFormToggleButtonComponent } from './analyst-form-toggle-button/analyst-form-toggle-button.component';
import { AnalystFormAddressComponent } from './analyst-form-address/analyst-form-address.component';
import { AnalystFreeTextSelectBoxComponent } from './analyst-free-text-select-box/analyst-free-text-select-box.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TextMaskModule } from 'angular2-text-mask';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AnalystFormContactComponent } from './analyst-form-contact/analyst-form-contact.component';
import { SelectTemplateModalComponent } from './select-template-modal/select-template-modal.component';
import { AnalystFormViewComponent } from './analyst-form-view/analyst-form-view.component';
import { FillerPreviewComponent } from './analyst-form-view/filler-preview/filler-preview.component';
import { FormNameComponent } from './analyst-form-view/form-name/form-name.component';
import { TemplateListComponent } from './select-template-modal/template-list/template-list.component';
import { HeaderComponent } from './analyst-form-view/header/header.component';
import { PublishModalComponent } from './analyst-form-view/header/publish-modal/publish-modal.component';

@NgModule({
    declarations: [
        AnalystFormSectionComponent,
        AnalystFormSectionElementComponent,
        AnalystOptionsMapComponent,
        TextOptionComponent,
        DateOptionComponent,
        BooleanOptionComponent,
        AnalystFormTextBoxComponent,
        AnalystFormCheckboxComponent,
        AnalystFormRadioButtonComponent,
        AnalystFormSelectBoxComponent,
        AnalystFormSectionRepeaterIndicatorComponent,
        AnalystFormYesNoButtonComponent,
        AnalystFormToggleButtonComponent,
        AnalystFormAddressComponent,
        AnalystFreeTextSelectBoxComponent,
        AnalystFormContactComponent,
        SelectTemplateModalComponent,
        AnalystFormViewComponent,
        FillerPreviewComponent,
        FormNameComponent,
        TemplateListComponent,
        HeaderComponent,
        PublishModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatButtonModule,
        MatButtonToggleModule,
        TextMaskModule,
        MatCardModule,
        MatIconModule,
        SharedModule,
        MatDatepickerModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
    ],
    exports: [
        AnalystFormSectionComponent,
        AnalystFormSectionElementComponent,
        AnalystOptionsMapComponent,
        TextOptionComponent,
        DateOptionComponent,
        BooleanOptionComponent,
        AnalystFormTextBoxComponent,
        AnalystFormCheckboxComponent,
        AnalystFormRadioButtonComponent,
        AnalystFormSelectBoxComponent,
        AnalystFormSectionRepeaterIndicatorComponent,
        AnalystFormYesNoButtonComponent,
        AnalystFormToggleButtonComponent,
        AnalystFormAddressComponent,
        AnalystFreeTextSelectBoxComponent,
        AnalystFormViewComponent,
    ],
})
export class FormFillerModule {}

import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { Observable } from 'rxjs';
import { DataSourceDto } from './dto/data-source.dto';

type DataSourcePostBody = Omit<DataSourceDto, 'id'>;
type DataSourcePutBody = Omit<DataSourceDto, 'id'>;

export interface ExternalDataSourceMapping {
    id: string;
    value: string;
}

@Injectable({
    providedIn: 'root',
})
export class DataSourceService {
    constructor(private httpService: HttpService) {}

    getAll(): Observable<DataSourceDto[]> {
        return this.httpService.get<DataSourceDto[]>('data-source').send();
    }
    saveDataSource(dataSource: DataSourcePostBody): Observable<DataSourceDto> {
        return this.httpService
            .post<DataSourceDto, DataSourcePostBody>('data-source')
            .withBody(dataSource)
            .send();
    }

    update(dataSource: DataSourceDto): Observable<DataSourceDto> {
        return this.httpService
            .put<DataSourceDto, DataSourcePutBody>(
                `data-source/${dataSource.id}`
            )
            .withBody({
                type: dataSource.type,
                name: dataSource.name,
                itemType: dataSource.itemType,
                items: dataSource.items,
            })
            .send();
    }

    createExternalSourceMappings(
        getItemsEndpointUrl: string
    ): Observable<unknown> {
        return this.httpService
            .post<unknown, { endpointUrl: string }>(
                'data-source/external/mapping'
            )
            .withBody({ endpointUrl: getItemsEndpointUrl })
            .send();
    }

    getExternalDataSourceMapping<T = ExternalDataSourceMapping>(
        externalDataSourceId: string
    ) {
        return this.httpService
            .get<DataSourceDto<T>>(
                `data-source/external/${externalDataSourceId}`
            )
            .send();
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DataStoreState } from './model';
import { DataSourceDto, ItemType } from '../../core/api/dto/data-source.dto';

export interface GetAllDataStoresType {
    dataStores: DataSourceDto[];
}

export const dataStoreSelector =
    createFeatureSelector<DataStoreState>('dataStore');

export const getAllDataStores = createSelector<
    any,
    DataStoreState,
    GetAllDataStoresType
>(dataStoreSelector, (state) => {
    return {
        dataStores: state?.dataStores ?? [],
    };
});

export const getAllDataStoresByType = (itemType: ItemType) =>
    createSelector<any, GetAllDataStoresType[], GetAllDataStoresType>(
        dataStoreSelector,
        getAllDataStores,
        (state, getAllDataStores = { dataStores: [] }) => {
            return {
                dataStores: getAllDataStores.dataStores.filter(
                    (store) => store.itemType === itemType
                ),
            };
        }
    );

<div *ngIf="{ isRefreshNeeded: isRefreshNeeded$ | async } as state">
    <div *ngIf="state.isRefreshNeeded.isRefreshNeeded" class="refresh-window">
        <div class="window-content flex">
            <img class="icon" src="assets/icons/icon-alert.svg" />
            <div class="full-width flex column justified">
                <div class="text">
                    <div class="header">
                        Oops! Something went a little wrong
                    </div>
                    <div class="description">
                        Click the button to refresh a page
                    </div>
                </div>
                <div class="reload-button">
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="handleClick()"
                    >
                        Reload Page
                    </button>
                </div>
            </div>
        </div>
    </div>
    <app-topbar></app-topbar>
    <router-outlet></router-outlet>
</div>

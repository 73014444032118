import { createAction, props } from '@ngrx/store';
import { UpdateComponentActionType } from '../types/update-component-action-type';
import {
    DeleteComponentActionProps,
    DeleteComponentActionType,
} from '../types/delete-component-action';

export const CUSTOM_COMPONENT_GET_ALL = '[Component] Get All';
const CUSTOM_COMPONENT_GET_ALL_SUCCESS = '[Component] Get All Success';
const CUSTOM_COMPONENT_UPDATE = '[Component] Update data';
const CUSTOM_COMPONENT_APPEND = '[Component] Append';
const CUSTOM_COMPONENT_SAVE_UPDATED = '[Component] Save Updated';
const CUSTOM_COMPONENT_SAVE_UPDATED_SUCCESS =
    '[Component] Save Updated Success';

const CUSTOM_COMPONENT_PREPARE_TO_EDIT = '[Component] Prepare to edit';
const CUSTOM_COMPONENT_PREPARE_TO_EDIT_SUCCESS =
    '[Component] Prepare to edit success';

const CUSTOM_COMPONENT_REMOVE_ELEMENT = '[Component] Remove Element';

const CUSTOM_COMPONENT_DRAG_START = '[Component] Drag start';
const CUSTOM_COMPONENT_DRAG_END = '[Component] Drag end';
const CUSTOM_COMPONENT_DELETE = '[Component] Remove component';
const CUSTOM_COMPONENT_DELETE_SUCCESS = '[Component] Remove component success';

export const customComponentsGetAll = createAction(CUSTOM_COMPONENT_GET_ALL);
export const customComponentsGetAllSuccess = createAction(
    CUSTOM_COMPONENT_GET_ALL_SUCCESS,
    props<{ components: any[] }>()
);
export const customComponentAppend = createAction(
    CUSTOM_COMPONENT_APPEND,
    props<{ newData: any; componentId: string }>()
);

export const customComponentsUpdate: UpdateComponentActionType = createAction(
    CUSTOM_COMPONENT_UPDATE,
    props<{
        sectionId: string;
        componentIndex: number;
        elementIndex: number;
        value: string;
        isUniqueId: boolean;
        uniqueIdPath?: string[];
        oldUniqueId?: string;
    }>()
);

export const customComponentSaveUpdated = createAction(
    CUSTOM_COMPONENT_SAVE_UPDATED,
    props<{ data: any[]; componentId: string }>()
);

export const customComponentSaveUpdatedSuccess = createAction(
    CUSTOM_COMPONENT_SAVE_UPDATED_SUCCESS,
    props<{ component: any }>()
);

export const customComponentPrepareToEdit = createAction(
    CUSTOM_COMPONENT_PREPARE_TO_EDIT,
    props<{ componentId: string }>()
);

export const customComponentPrepareToEditSuccess = createAction(
    CUSTOM_COMPONENT_PREPARE_TO_EDIT_SUCCESS,
    props<{ componentId: string; components: any[] }>()
);

export const customComponentRemoveElement: DeleteComponentActionType =
    createAction(
        CUSTOM_COMPONENT_REMOVE_ELEMENT,
        props<DeleteComponentActionProps>()
    );

export const customComponentDragStart = createAction(
    CUSTOM_COMPONENT_DRAG_START,
    props<{ componentIndex: number }>()
);

export const customComponentDragEnd = createAction(
    CUSTOM_COMPONENT_DRAG_END,
    props<{ componentIndex: number }>()
);

export const customComponentDelete = createAction(
    CUSTOM_COMPONENT_DELETE,
    props<{ componentId: string }>()
);

export const customComponentDeleteSuccess = createAction(
    CUSTOM_COMPONENT_DELETE_SUCCESS,
    props<{ componentId: string }>()
);

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getTemplateInformation } from '../store/form-api/form-api.selector';
import { environment } from '../../environments/environment';
import { StorageService } from '../core/storage.service';
import { LoginResponse } from '../login/login.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    isBackButtonVisible: boolean = true;
    isTopNavBarVisible: boolean = true;
    title: Observable<string>;

    Environment = environment;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private router: Router,
        private store: Store,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.title = this.setTitle(event.url);

                if (event.url === '/') {
                    this.isTopNavBarVisible = false;
                    return;
                }
                this.isTopNavBarVisible = true;

                if (event.url.includes('component-manager')) {
                    this.isBackButtonVisible = true;
                    return;
                }
                if (event.url.includes('form-list') || event.url === '/') {
                    this.isBackButtonVisible = false;
                    return;
                }
                if (event.url.includes('data-store')) {
                    this.isBackButtonVisible = false;
                    return;
                }
                this.isBackButtonVisible = true;
            }
        });
    }

    navigateBack() {
        this.location.back();
    }

    private setTitle(url: string): Observable<string> {
        if (url.includes('analyst/form-list')) {
            return of('Form Filler');
        }

        if (url.includes('data-store')) {
            return of('Data Store');
        }

        if (
            url.includes('analyst/template/') ||
            url.startsWith('/analyst/form')
        ) {
            const formId =
                this.route.firstChild.firstChild.snapshot.paramMap.get('id');
            return this.store
                .select(getTemplateInformation(formId))
                .pipe(map((data) => data?.formName));
        }

        if (url.includes('component-manager')) {
            return of('Component Manager');
        }
        if (url.includes('/publication')) {
            return of('Publications');
        }
        return of('Form Builder');
    }

    isLinkActive(
        linkName: 'data-store' | 'form-builder' | 'form-filler' | 'publication'
    ) {
        switch (linkName) {
            case 'form-builder':
                return window.location.pathname === '/form-list/form-builder';
            case 'data-store':
                return window.location.pathname === '/data-store';
            case 'form-filler':
                return window.location.pathname === '/analyst/form-list';
            case 'publication':
                return window.location.pathname === '/publication';
        }
    }

    isFormBuilderLinkShouldBeVisible() {
        const value = this.storageService.getValue<LoginResponse>(
            'token',
            'temporary'
        );
        return value?.roles?.includes('admin') ?? false;
    }

    isFormFillerLinkShouldBeVisible() {
        const value = this.storageService.getValue<LoginResponse>(
            'token',
            'temporary'
        );
        return value?.roles?.includes('analyst') ?? false;
    }

    isPublicationLinkShouldBeVisible() {
        const value = this.storageService.getValue<LoginResponse>(
            'token',
            'temporary'
        );
        return value?.permissions?.includes('submission:go-live') ?? false;
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Publication, PublicationState } from './model';

export const getPublicationState =
    createFeatureSelector<PublicationState>('publication');

export type GetAllPublications = Publication[];

export const getAllPublications = createSelector<
    object,
    PublicationState,
    GetAllPublications
>(getPublicationState, (state) => {
    return state.publications;
});

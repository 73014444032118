import { createAction, props } from '@ngrx/store';
import { DataSourceDto } from '../../core/api/dto/data-source.dto';

const RETRIEVE_DATA_STORES = '[Data Store] Retrieve';
const RETRIEVE_DATA_STORES_SUCCESS = '[Data Store] Retrieve Success';

export const retrieveDataStores = createAction(RETRIEVE_DATA_STORES);
export const retrieveDataStoresSuccess = createAction(
    RETRIEVE_DATA_STORES_SUCCESS,
    props<{ dataStores: DataSourceDto[] }>()
);

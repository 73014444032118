import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-date-option',
    templateUrl: './date-option.component.html',
    styleUrls: ['./date-option.component.scss'],
})
export class DateOptionComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() readOnly: boolean;

    @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
    constructor() {}

    handleValueChange($event: any) {
        this.valueChanged.emit($event);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { RouterModule, Routes } from '@angular/router';
import { PublicationListComponent } from './main/publication-list/publication-list.component';
import { SharedModule } from '../shared/shared.module';
import { PublicationListResolver } from '../resolvers/publication-list-resolver.service';
import { FiltersComponent } from './main/filters/filters.component';
import { PublicationPreviewModalComponent } from './main/publication-preview-modal/publication-preview-modal.component';
import { FormFillerModule } from '../form-filler/form-filler.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        resolve: { data: PublicationListResolver },
    },
];

@NgModule({
    declarations: [
        MainComponent,
        PublicationListComponent,
        FiltersComponent,
        PublicationPreviewModalComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        FormFillerModule,
        MatDialogModule,
        MatButtonModule,
    ],
})
export class PublicationModule {}

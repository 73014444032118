<div
    *ngIf="(getDictionaryByType$ | async).dictionary as dictionary"
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <div class="flex column">
        <div class="flex aligned-center gap10">
            <mat-form-field
                appearance="outline"
                class="full-width label-outside"
            >
                <mat-label>{{ label }}</mat-label>
                <mat-select
                    [disabled]="readOnly"
                    [ngModel]="value"
                    (ngModelChange)="handleModelChange($event, dictionary)"
                >
                    <mat-option
                        *ngFor="let item of getAllOptions(dictionary)"
                        [value]="item.id"
                    >
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-mini-fab color="primary" (click)="addNewAddress()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div *ngIf="selectedAddress?.model" class="flex column">
            <div class="flex gap10">
                <mat-form-field
                    appearance="outline"
                    class="full-width flex-grow1 label-outside"
                >
                    <mat-label>Street Address</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="getStreetAddress()"
                    />
                </mat-form-field>
            </div>
            <div class="flex gap10">
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>Country</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="selectedAddress.model.country"
                    />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>State</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="selectedAddress.model.region"
                    />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>Zip Code</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="selectedAddress.model.zipCode"
                    />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>City</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="selectedAddress.model.city"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<span>Specify address lines</span>
<div>
    <mat-form-field class="full-width">
        <mat-label>Address 1</mat-label>
        <input
            matInput
            [ngModel]="line1"
            (ngModelChange)="handleLine1Changed($event)"
        />
    </mat-form-field>
</div>
<div>
    <mat-form-field class="full-width">
        <mat-label>Address 2</mat-label>
        <input
            matInput
            [ngModel]="line2"
            (ngModelChange)="handleLine2Changed($event)"
        />
    </mat-form-field>
</div>
<div>
    <mat-form-field class="full-width">
        <mat-label>Address 3</mat-label>
        <input
            matInput
            [ngModel]="line3"
            (ngModelChange)="handleLine3Changed($event)"
        />
    </mat-form-field>
</div>
<div class="flex">
    <button
        mat-raised-button
        (click)="handlePreviousStep()"
        style="flex-grow: 1"
    >
        Previous
    </button>
    <button
        mat-raised-button
        class="ml-1"
        (click)="handleNextStep()"
        style="flex-grow: 1"
    >
        Next
    </button>
</div>

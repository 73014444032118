import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubmissionDictionaryType } from '../../../store/submission/model';

@Component({
    selector: 'app-user-action',
    templateUrl: './user-action.component.html',
    styleUrls: ['./user-action.component.scss'],
})
export class UserActionComponent {
    @Input()
    public optionType: SubmissionDictionaryType | 'simple';

    @Input()
    public data: any;

    @Output()
    addOption: EventEmitter<{
        data: any;
    }> = new EventEmitter();

    constructor() {}

    handleSave($event: any) {
        this.addOption.emit({ data: $event });
    }
}

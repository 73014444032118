import { ComponentType } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';

export interface ConfirmableParams<ModalComponentType> {
    handler: () => any;
    modal: ComponentType<ModalComponentType>;
    dialogFactory: () => MatDialog;
}

export function Confirmable<ModalComponentType>(
    params: ConfirmableParams<ModalComponentType>
) {
    return (
        target: Object,
        propertyKey: string | symbol,
        descriptor: PropertyDescriptor
    ) => {
        const originalMethod: (r) => void = descriptor.value;
        descriptor.value = (...args) => {
            const dialogRef = params.dialogFactory().open(params.modal, {
                data: args[0],
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    originalMethod.apply(params.handler(), [result]);
                }
            });
        };

        return descriptor;
    };
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { customComponentPrepareToEdit } from '../store/custom-components/custom-components.action';

@Injectable()
export class CustomComponentBuilderResolver implements Resolve<any> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.store.dispatch(
            customComponentPrepareToEdit({
                componentId: route.paramMap.get('id'),
            })
        );
    }
}

<span>Specify Province</span>
<mat-form-field class="full-width">
    <mat-label>Region</mat-label>
    <mat-select
        [ngModel]="region"
        (ngModelChange)="handleRegionChanged($event)"
    >
        <mat-option></mat-option>
        <mat-option value="AB">AB</mat-option>
        <mat-option value="BC">BC</mat-option>
        <mat-option value="MB">MB</mat-option>
        <mat-option value="NB">NB</mat-option>
        <mat-option value="NL">NL</mat-option>
        <mat-option value="NT">NT</mat-option>
        <mat-option value="NS">NS</mat-option>
        <mat-option value="NU">NU</mat-option>
        <mat-option value="ON">ON</mat-option>
        <mat-option value="PE">PE</mat-option>
        <mat-option value="QC">QC</mat-option>
        <mat-option value="SK">SK</mat-option>
        <mat-option value="YT">YT</mat-option>
    </mat-select>
</mat-form-field>
<div class="flex">
    <button
        mat-raised-button
        (click)="handlePreviousStep()"
        style="flex-grow: 1"
    >
        Previous
    </button>
    <button
        mat-raised-button
        class="ml-1"
        (click)="handleNextStep()"
        style="flex-grow: 1"
    >
        Next
    </button>
</div>

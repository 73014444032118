import { Action, createReducer, on } from '@ngrx/store';
import {
    customComponentAppend,
    customComponentPrepareToEditSuccess,
    customComponentsGetAllSuccess,
    customComponentRemoveElement,
    customComponentDragStart,
    customComponentDragEnd,
    customComponentDeleteSuccess,
    customComponentsUpdate,
} from './custom-components.action';

export interface CustomComponentsState {
    components: any[];
    componentInternalData: any[];
    draggedComponentIndex?: number;
}

const initialState: CustomComponentsState = {
    components: [],
    componentInternalData: [],
};

const createCustomComponentsReducer = createReducer(
    initialState,
    on(customComponentsGetAllSuccess, (state, action) => {
        return {
            ...state,
            components: action.components,
        };
    }),
    on(customComponentAppend, (state, action) => {
        return {
            ...state,
            componentInternalData: [
                ...state.componentInternalData,
                action.newData,
            ],
        };
    }),
    on(customComponentPrepareToEditSuccess, (state, action) => {
        return {
            ...state,
            components: action.components,
            componentInternalData: [
                ...action?.components?.find((c) => c._id === action.componentId)
                    ?.data,
            ],
        };
    }),
    on(customComponentRemoveElement, (state, action) => {
        return {
            ...state,
            componentInternalData: [
                ...state.componentInternalData.filter(
                    (element, index) => index !== action.componentIndex
                ),
            ],
        };
    }),
    on(customComponentDragStart, (state, action) => {
        return {
            ...state,
            draggedComponentIndex: action.componentIndex,
        };
    }),
    on(customComponentDragEnd, (state, action) => {
        const sourceComponentIndex = state?.draggedComponentIndex;

        if (sourceComponentIndex) {
            const array = [...state.componentInternalData];

            const temp = array[sourceComponentIndex];
            array[sourceComponentIndex] = array[action.componentIndex];
            array[action.componentIndex] = temp;

            return {
                ...state,
                draggedComponentIndex: undefined,
                componentInternalData: array,
            };
        }

        return {
            ...state,
        };
    }),
    on(customComponentDeleteSuccess, (state, action) => {
        return {
            ...state,
            components: [
                ...state.components.filter((c) => c._id !== action.componentId),
            ],
        };
    }),
    on(customComponentsUpdate, (state, action) => {
        const updatedData = JSON.parse(
            JSON.stringify(state.componentInternalData)
        );
        const componentToUpdate = updatedData[action.componentIndex];
        const elementToUpdate = componentToUpdate.elements[action.elementIndex];
        elementToUpdate.defaultValue = action.value;

        return {
            ...state,
            componentInternalData: updatedData,
        };
    })
);

export function customComponentsReducer(
    state: CustomComponentsState | undefined,
    action: Action
) {
    return createCustomComponentsReducer(state, action);
}

<h1 mat-dialog-title>Save as...</h1>
<div mat-dialog-content>
    <mat-form-field>
        <input
            matInput
            [placeholder]="Component.formNamePlaceholder"
            [(ngModel)]="newFormName"
        />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-raised-button (click)="handleCancel()">
        {{ Component.cancelButtonLabel }}
    </button>
    <button mat-raised-button (click)="handleOk()" color="primary">
        {{ Component.okButtonLabel }}
    </button>
</div>

import { createAction, props } from '@ngrx/store';
import { FormComponentType } from '../../new-form/upsert-component/upsert-component.component';
import { UpdateComponentActionType } from '../types/update-component-action-type';
import {
    DeleteComponentActionProps,
    DeleteComponentActionType,
} from '../types/delete-component-action';
import { Section } from '../../models/section';
import { AdminState, DefaultDictionaries, OnboardingSchema } from './model';
import { ChangeStateOnUniqueIdActionType } from '../types/change-state-on-unique-id-action-type';

const ADD_NEW_SECTION = '[Sections] Add new';
const UPDATE_SECTION = '[Sections] Update';
const DELETE_SECTION = '[Sections] Delete';
const DRAG_SECTION_START = '[Sections] Drag Start';
const DROP_SECTION = '[Sections] Drop';

const ADD_NEW_COMPONENT = '[Sections] Add new component';
const UPDATE_COMPONENT = '[Sections] Update component';

const SELECT_COMPONENT = '[Palette] Select component';

export const CLEAR_FORM = '[Form] Clear';
const EDIT_FORM = '[Form] Edit';
const EDIT_FORM_SUCCESS = '[Form] Edit Success';
const SAVE_FORM = '[Form] Save';
const SAVE_AS_FORM = '[Form] Save as';
const SAVE_FORM_SUCCESS = '[Form] Save Success';

const DELETE_COMPONENT = '[Component] Delete';
const DRAG_START_COMPONENT = '[Component] Drag start';
const COMPONENT_DROP = '[Component] Drop';
const COMPONENT_CREATE_CUSTOM = '[Component] Create Custom';
const COMPONENT_CREATE_CUSTOM_SUCCESS = '[Component] Create Custom Success';

const UPLOAD_ONBOARDING_SCHEMA = '[Schema] Upload';
export const CHANGE_STATE_ON_UNIQUE_ID = '[Schema] Change UniqueId State';

export const NULL_ACTION = '[Null] Action';

const INIT_UNIQUE_ID_USAGE = '[Schema] Init UniqueId Usage';

export const ADD_VALUE_TO_DEFAULT_DICTIONARY = '[Default Dictionary] Add Value';
export const RESET_DEFAULT_DICTIONARY = '[Default Dictionary] Reset';

const SET_COMPONENT_DEFAULT_VALUE = '[Component] Set Default Value';

const SET_SECTION_TRIGGER = '[Sections] Set Trigger';
const SET_SECTION_TRIGGER_VALUE = '[Sections] Set Trigger Value';

const TOGGLE_ACCORDION = '[Accordion] Toggle';

export const addNewSection = createAction(
    ADD_NEW_SECTION,
    props<{
        parentSectionId?: string;
        sectionType?: 'repeater' | 'section';
        title?: string;
    }>()
);

export const updateSection = createAction(
    UPDATE_SECTION,
    props<{ newTitle: string; id: string }>()
);

export const selectComponent = createAction(
    SELECT_COMPONENT,
    props<{
        name: string;
        iconPosition: 'ltr' | 'rtl';
        isCustomComponent: boolean;
        label: string;
    }>()
);

export const addNewComponent = createAction(
    ADD_NEW_COMPONENT,
    props<{ sectionId: string; data: any }>()
);

export const updateComponent: UpdateComponentActionType = createAction(
    UPDATE_COMPONENT,
    props<{
        sectionId: string;
        componentIndex: number;
        elementIndex: number;
        value: string;
        isUniqueId: boolean;
        uniqueIdPath?: string[];
        oldUniqueId?: string;
        componentType?: string;
    }>()
);

export const saveForm = createAction(
    SAVE_FORM,
    props<{
        formName: string;
        data: Section[];
        formId: string;
        dictionaries: AdminState['newForm']['defaultDictionaries'];
    }>()
);

export const saveAsForm = createAction(
    SAVE_AS_FORM,
    props<{
        formName: string;
        data: Section[];
        dictionaries: AdminState['newForm']['defaultDictionaries'];
    }>()
);

export const editForm = createAction(EDIT_FORM, props<{ formId: string }>());

export const editFormSuccess = createAction(
    EDIT_FORM_SUCCESS,
    props<{ inputData: Section[]; dictionaries: DefaultDictionaries }>()
);

export const deleteComponent: DeleteComponentActionType = createAction(
    DELETE_COMPONENT,
    props<DeleteComponentActionProps>()
);

export const saveFormSuccess = createAction(SAVE_FORM_SUCCESS);

export const deleteSection = createAction(
    DELETE_SECTION,
    props<{ id: string }>()
);

export const sectionDragStart = createAction(
    DRAG_SECTION_START,
    props<{ section: Section; parentSectionId: string }>()
);

export const sectionDrop = createAction(
    DROP_SECTION,
    props<{ section: Section; parentSectionId: string }>()
);

export const componentDragStart = createAction(
    DRAG_START_COMPONENT,
    props<{
        sectionId: string;
        componentIndex: number;
        component: FormComponentType;
        parentSectionId: string;
    }>()
);

export const componentDrop = createAction(
    COMPONENT_DROP,
    props<{
        sectionId: string;
        componentIndex: number;
        component: FormComponentType;
        parentSectionId: string;
    }>()
);

export const createCustomComponent = createAction(
    COMPONENT_CREATE_CUSTOM,
    props<{ id: string }>()
);

export const createCustomComponentSuccess = createAction(
    COMPONENT_CREATE_CUSTOM_SUCCESS
);

export const saveOnboardingSchema = createAction(
    UPLOAD_ONBOARDING_SCHEMA,
    props<{ schema: OnboardingSchema }>()
);

export const changeStateOnUniqueId: ChangeStateOnUniqueIdActionType =
    createAction(
        CHANGE_STATE_ON_UNIQUE_ID,
        props<{
            uniqueId: string;
            uniqueIdPath: string[];
            componentType: string;
            sectionId: string;
            deleteUniqueId: boolean;
        }>()
    );

export const nullAction = createAction(NULL_ACTION);

export const clearForm = createAction(CLEAR_FORM);

export const initUniqueIdUsage = createAction(
    INIT_UNIQUE_ID_USAGE,
    props<{
        sectionId: string;
        componentIndex: number;
        elementIndex: number;
        uniqueIdPath: string[];
        componentType: string;
    }>()
);

export const addValueToDefaultDictionary = createAction(
    ADD_VALUE_TO_DEFAULT_DICTIONARY,
    props<{
        value: any;
        dictionaryType: 'address' | 'contact';
        sectionId: string;
        componentIndex: number;
    }>()
);

export const resetDefaultDictionary = createAction(
    RESET_DEFAULT_DICTIONARY,
    props<{
        sectionId: string;
        componentIndex: number;
        dictionaryType: 'address' | 'contact';
    }>()
);

export const setComponentDefaultValue = createAction(
    SET_COMPONENT_DEFAULT_VALUE,
    props<{ value: string; sectionId: string; componentIndex: number }>()
);

export const setSectionTrigger = createAction(
    SET_SECTION_TRIGGER,
    props<{
        sectionId: string;
        trigger: string;
    }>()
);

export const setSectionTriggerValue = createAction(
    SET_SECTION_TRIGGER_VALUE,
    props<{
        sectionId: string;
        triggerValue: string[];
    }>()
);

export const toggleAccordion = createAction(
    TOGGLE_ACCORDION,
    props<{ sectionId: string; componentIndex: number }>()
);

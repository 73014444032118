import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    getAllCustomComponents,
    GetAllCustomComponentsType,
} from '../../store/custom-components/custom-components.selector';

import {
    cloneRepeater,
    deleteRepeater,
    startObservingTrigger,
} from '../../store/submission/submission.action';
import {
    getSubmissionSectionById,
    GetSubmissionSectionByIdType,
    getTriggerForSection,
    GetTriggerForSectionType,
} from '../../store/submission/submission.selector';
import pluralize from 'pluralize';
import { ComponentElementsService } from '../../core/component-elements.service';
import { MenuComponent } from '../../enums/components';
import { Memoize } from '../../core/decorators/memoize.decorator';

@Component({
    selector: 'app-analyst-form-section',
    templateUrl: './analyst-form-section.component.html',
    styleUrls: ['./analyst-form-section.component.scss'],
})
export class AnalystFormSectionComponent implements OnInit {
    @Input()
    components: any[];

    @Input()
    number: string;

    @Input()
    name: string;

    @Input()
    sectionId: string;

    @Input()
    sectionUniqueId: string;

    @Input()
    repeaterSize?: number;

    @Input()
    readOnly?: boolean = false;

    @Input()
    clonedId: string;

    public customComponents$: Observable<GetAllCustomComponentsType>;
    public getTriggerForSection$: Observable<GetTriggerForSectionType>;
    public getSubmissionSectionById$: Observable<GetSubmissionSectionByIdType>;

    public isSectionAccordionCollapsed = false;

    constructor(
        private store: Store,
        private componentElementsService: ComponentElementsService
    ) {}

    ngOnInit(): void {
        this.customComponents$ = this.store.select(getAllCustomComponents);
        this.getTriggerForSection$ = this.store.select(
            getTriggerForSection(this.sectionId)
        );
        this.getSubmissionSectionById$ = this.store.select(
            getSubmissionSectionById(this.sectionId)
        );
        if (!this.readOnly) {
            this.store.dispatch(
                startObservingTrigger({ sectionId: this.sectionId })
            );
        }
    }

    cloneSection() {
        this.store.dispatch(
            cloneRepeater({
                sectionId: this.sectionId,
                clonedId: this.clonedId,
            })
        );
    }

    isRepeater() {
        return this.repeaterSize > 0;
    }

    tracker(index: number, item) {
        return `${index}`;
    }

    shouldBeVisible(
        getSubmissionSectionById: GetSubmissionSectionByIdType,
        getTriggerForSection: GetTriggerForSectionType
    ) {
        if (this.readOnly) {
            return true;
        }
        return (
            getSubmissionSectionById.section.trigger === undefined ||
            getSubmissionSectionById.section.triggerValue.includes(
                getTriggerForSection.currentValue.toString()
            )
        );
    }

    getSingularForm(name: string) {
        return pluralize.singular(name);
    }

    handleDeleteElement(index: number) {
        this.store.dispatch(
            deleteRepeater({
                sectionId: this.sectionId,
                clonedId: this.clonedId,
                index,
                repeaterSize: this.repeaterSize,
            })
        );
    }

    handleToggleSectionAccordion() {
        this.isSectionAccordionCollapsed = !this.isSectionAccordionCollapsed;
    }

    isBlankComponent(component: any) {
        if (component === null) {
            return true;
        }

        const uniqueId = this.componentElementsService.getUniqueId(component);

        if (!uniqueId && !component.uniqueId1) {
            return true;
        }

        if (
            [
                MenuComponent.Select,
                MenuComponent.FreeTextSelect,
                MenuComponent.RadioBox,
            ].includes(component.component)
        ) {
            const allowedValues =
                this.componentElementsService.getAllowedValues(component);

            return !allowedValues;
        }

        return false;
    }

    @Memoize()
    getTestId(sectionId: string, clonedId: string) {
        return `container-${clonedId ?? sectionId}`;
    }
}

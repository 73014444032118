import { Injectable } from '@angular/core';
import { CustomComponentsApiService } from '../../custom-components-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, map } from 'rxjs/operators';
import {
    customComponentDelete,
    customComponentDeleteSuccess,
    customComponentPrepareToEdit,
    customComponentPrepareToEditSuccess,
    customComponentSaveUpdated,
    customComponentSaveUpdatedSuccess,
    customComponentsGetAll,
    customComponentsGetAllSuccess,
} from './custom-components.action';

@Injectable()
export class CustomComponentsEffect {
    constructor(
        private actions$: Actions,
        private customComponentsApi: CustomComponentsApiService
    ) {}

    getAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(customComponentsGetAll),
            exhaustMap((a) => {
                return this.customComponentsApi.getCustomComponents().pipe(
                    map((response) =>
                        customComponentsGetAllSuccess({
                            components: response,
                        })
                    )
                );
            })
        )
    );

    saveUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(customComponentSaveUpdated),
            exhaustMap((a) => {
                return this.customComponentsApi
                    .updateCustomComponent(a.componentId, a.data)
                    .pipe(
                        map((response) =>
                            customComponentSaveUpdatedSuccess({
                                component: response,
                            })
                        )
                    );
            })
        )
    );

    prepareToEdit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(customComponentPrepareToEdit),
            exhaustMap((a) => {
                return this.customComponentsApi.getCustomComponents().pipe(
                    map((response) =>
                        customComponentPrepareToEditSuccess({
                            componentId: a.componentId,
                            components: response,
                        })
                    )
                );
            })
        )
    );

    deleteComponent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(customComponentDelete),
            exhaustMap((a) => {
                return this.customComponentsApi
                    .deleteCustomComponent(a.componentId)
                    .pipe(
                        map((response) =>
                            customComponentDeleteSuccess({
                                componentId: a.componentId,
                            })
                        )
                    );
            })
        )
    );
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { MatDialogConfig } from '@angular/material/dialog/dialog-config';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private matDialog: MatDialog) {}

    openModal<ModalType, ModalData, ModalResult>(
        modalType: ComponentType<ModalType>,
        data: ModalData,
        onClose: (result: ModalResult) => void,
        config?: Omit<MatDialogConfig<ModalData>, 'data'>
    ) {
        const dialogRef = this.matDialog.open<
            ModalType,
            ModalData,
            ModalResult
        >(modalType, { ...(config ?? {}), data });

        dialogRef.afterClosed().subscribe((result) => {
            onClose(result);
        });

        return dialogRef;
    }
}

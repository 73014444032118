import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    getAllDataStores,
    GetAllDataStoresType,
} from '../../store/data-store/data-store.selector';
import { DataStoreService } from '../../core/data-store.service';
import { first, map } from 'rxjs/operators';
import { Memoize } from '../../core/decorators/memoize.decorator';
import { ComponentElementsService } from '../../core/component-elements.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { FormValidatorService } from '../form-validator.service';

@Component({
    selector: 'app-analyst-free-text-select-box',
    templateUrl: './analyst-free-text-select-box.component.html',
    styleUrls: ['./analyst-free-text-select-box.component.scss'],
})
export class AnalystFreeTextSelectBoxComponent implements OnInit {
    @Input()
    public label: string;

    @Input()
    public allowedValues: string;

    @Input()
    public readOnly: boolean = false;

    @Input()
    public value: string;

    @Input()
    public isMultiSelectBox: boolean;

    @Input()
    public component: EditComponentModel;

    @Output()
    public valueChanged: EventEmitter<string> = new EventEmitter<string>();

    public getAllDataStores$: Observable<GetAllDataStoresType>;

    constructor(
        private store: Store,
        private dataStoreService: DataStoreService,
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit(): void {
        this.getAllDataStores$ = this.store.select(getAllDataStores);
    }

    onModelChanged($event: string) {
        this.valueChanged.emit($event);
    }

    getAllowedOptions(getAllDataStores: GetAllDataStoresType, allowedValues) {
        return this.dataStoreService.getAllowedValues(
            allowedValues,
            getAllDataStores.dataStores
        );
    }

    handleOtherValueChange($event: string) {
        this.valueChanged.emit($event);
    }

    @Memoize()
    getSelectValue(
        getAllDataStores: GetAllDataStoresType,
        value: string,
        allowedValues: string
    ) {
        if (value === undefined) {
            return of(undefined);
        }

        return this.getAllowedOptions(getAllDataStores, allowedValues).pipe(
            first(),
            map((options) => {
                return (
                    options.find((option) => option.key === value) ?? {
                        key: 'other',
                        value: 'other',
                    }
                );
            })
        );
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }
}

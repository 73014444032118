import { Component, Inject } from '@angular/core';
import { Section } from '../../../models/section';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PublicationPreviewModalComponentData {
    sections: Section[];
}

export interface PublicationPreviewModalComponentResult {
    markAsInvalid: boolean;
}

@Component({
    selector: 'app-pbl-publication-preview-modal',
    templateUrl: './publication-preview-modal.component.html',
    styleUrls: ['./publication-preview-modal.component.scss'],
})
export class PublicationPreviewModalComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dialogData: PublicationPreviewModalComponentData,
        private dialogRef: MatDialogRef<
            PublicationPreviewModalComponent,
            PublicationPreviewModalComponentResult
        >
    ) {}

    handleClick() {
        this.dialogRef.close({ markAsInvalid: true });
    }
}

import { createAction, props } from '@ngrx/store';
import { GeneralState } from './model';

const REFRESH_NEEDED = '[General] Refresh Needed';
const REFRESH_SUCCEDED = '[General] Refresh Succeeded';

const SET_FILTERS = '[General] Set filter';

export const refreshNeeded = createAction(REFRESH_NEEDED);
export const refreshSucceded = createAction(REFRESH_SUCCEDED);

export const setFilters = createAction(
    SET_FILTERS,
    props<{ group: keyof GeneralState['filters']; value: string }>()
);

<ng-container [ngSwitch]="true">
    <app-analyst-form-section
        *ngSwitchCase="component.component === menuComponent.Section"
        [components]="component.components"
        [number]="component.number"
        [name]="component.name"
        [sectionId]="component.id"
        [readOnly]="readOnly"
        [sectionUniqueId]="component.uniqueId1"
    ></app-analyst-form-section>
    <app-analyst-form-section
        *ngSwitchCase="component.component === menuComponent.Repeater"
        [components]="component.components"
        [number]="component.number"
        [name]="component.name"
        [repeaterSize]="
            component?.clonedSource?.length ?? component?.components?.length
        "
        [sectionId]="component.id"
        [readOnly]="readOnly"
        [sectionUniqueId]="component.uniqueId1"
        [clonedId]="component.clonedId"
    ></app-analyst-form-section>
    <app-analyst-form-text-box
        *ngSwitchCase="component.component === menuComponent.TextBox"
        [component]="component"
        [label]="getComponentLabel(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
    ></app-analyst-form-text-box>
    <app-analyst-form-checkbox
        *ngSwitchCase="component.component === menuComponent.Checkbox"
        [component]="component"
        [label]="getComponentLabel(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
    ></app-analyst-form-checkbox>
    <app-analyst-form-radio-button
        *ngSwitchCase="component.component === menuComponent.RadioBox"
        [component]="component"
        [label]="getComponentLabel(component)"
        [allowedValues]="getAllowedValues(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
    ></app-analyst-form-radio-button>
    <app-analyst-form-select-box
        *ngSwitchCase="component.component === menuComponent.Select"
        [label]="getComponentLabel(component)"
        [allowedValues]="getAllowedValues(component)"
        [shouldOtherOptionBeVisible]="getConstraint(component, 'Other')"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        (otherOptionsChanged)="handleOtherOptionChanged($event)"
        [readOnly]="readOnly"
        [isMultiSelectBox]="getIsMultipleSelectBox(component)"
        [otherOptions]="getOtherOptions(component)"
        [component]="component"
    ></app-analyst-form-select-box>
    <app-analyst-form-yes-no-button
        *ngSwitchCase="component.component === menuComponent.YesNo"
        [component]="component"
        [label]="getComponentLabel(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
    ></app-analyst-form-yes-no-button>
    <app-analyst-form-toggle-button
        *ngSwitchCase="component.component === menuComponent.Toggle"
        [component]="component"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
    ></app-analyst-form-toggle-button>
    <app-analyst-free-text-select-box
        *ngSwitchCase="component.component === menuComponent.FreeTextSelect"
        [label]="getComponentLabel(component)"
        [allowedValues]="getAllowedValues(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [isMultiSelectBox]="getIsMultipleSelectBox(component)"
        [component]="component"
    ></app-analyst-free-text-select-box>
    <app-analyst-options-map
        *ngSwitchCase="component.component === menuComponent.OptionsMap"
        [label]="getComponentLabel(component)"
        [options]="getOptionsMapContent(component)"
        [value]="component.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [sectionId]="sectionId"
        [clonedId]="clonedId"
    ></app-analyst-options-map>
    <app-analyst-form-address
        *ngSwitchCase="component.component === menuComponent.Address"
        [label]="getComponentLabel(component)"
        [value]="component?.value ?? component?.defaultValue?.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [uniqueId]="getUniqueId(component)"
        [readOnly]="readOnly"
        [dictionaryId]="getDictionaryId(component)"
        [component]="component"
    ></app-analyst-form-address>
    <app-analyst-form-contact
        *ngSwitchCase="component.component === menuComponent.Contact"
        [label]="getComponentLabel(component)"
        [value]="component?.value ?? component?.defaultValue?.value"
        (valueChanged)="handleComponentValueChanged($event)"
        [readOnly]="readOnly"
        [uniqueId]="getUniqueId(component)"
        [dictionaryId]="getDictionaryId(component)"
        [component]="component"
    ></app-analyst-form-contact>
    <div *ngSwitchDefault>
        <h3>{{ component.blockLabel }}</h3>
        <app-analyst-form-section-element
            *ngFor="
                let subelement of getCustomComponentElements(
                    component.component,
                    customComponents
                );
                let i = index
            "
            [component]="subelement"
            [customComponents]="customComponents"
            [sectionId]="sectionId"
            [clonedId]="clonedId"
            [componentIndex]="i"
            [readOnly]="readOnly"
            [sectionUniqueId]="sectionUniqueId"
        ></app-analyst-form-section-element>
    </div>
</ng-container>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ElementValueSelectorType } from '../types/get-section-element-selector';
import { Section } from '../../models/section';
import {
    componentTreeAsAnArray,
    isContainer,
} from '../../utils/component-tree';
import { AdminState, DefaultDictionaries, DefaultDictionary } from './model';
import {
    convertKebabCaseToPascalCase,
    convertKebabCaseToTitleCase,
} from '../../utils/text-utils';
import { getUniqueIdsFromPath } from './onboarding-schema-functions';
import { ComponentElementsService } from '../../core/component-elements.service';
import { Address } from '../../models/address';
import { Contact } from '../../models/contact';
import { ComponentElementService } from '../../core/component-element.service';
import { MenuComponent } from '../../enums/components';
import { Option } from '../../models/components/options-map';

const componentElementService = new ComponentElementService();

const componentElementsService: ComponentElementsService =
    new ComponentElementsService(componentElementService);

export const getAdminState = createFeatureSelector<AdminState>('admin');

export interface GetNewFormSectionsType {
    sections: Array<Section>;
}

export interface GetNewFormSelectedComponentType {
    name: string;
    iconPosition: 'ltr' | 'rtl';
    isCustom: boolean;
    label: string;
}

export interface GetSectionByIdType {
    section: Section;
}

export interface GetOnboardingSchemaType {
    schemaObjects: any;
}

export interface GetUniqueIdStateType {
    usedInSections: Array<string>;
}

export interface GetDefaultDictionariesType {
    dictionaries: DefaultDictionaries;
}

export interface GetDefaultDictionaryForTypeType {
    dictionary: DefaultDictionary<Address | Contact>;
}

export interface GetSectionTriggerType {
    trigger: string;
    triggerValue: string[];
    triggerType: string;
    allowedValues: string;
}

export const getNewFormSectionsSelect = createSelector<
    any,
    any[],
    GetNewFormSectionsType
>(getAdminState, (state: AdminState) => {
    const sections = JSON.parse(
        JSON.stringify(state?.newForm?.sections ?? [])
    ).sort((a, b) => a.sort - b.sort);
    return {
        sections,
    };
});

export const getNewFormSelectedComponent = createSelector<
    any,
    any[],
    GetNewFormSelectedComponentType
>(getAdminState, (state: AdminState) => state.newForm.selectedComponent);

function getAllSectionsAsArray(sections: Section[]): Section[] {
    return sections
        .flatMap((section) => {
            if (isContainer(section)) {
                return [
                    section,
                    ...getAllSectionsAsArray(section.components ?? []),
                ];
            }

            return null;
        })
        .filter((section) => section !== null);
}

export const getCanvasSectionElementValue: ElementValueSelectorType = (
    sectionId: string,
    componentIndex: number,
    elementIndex: number
) =>
    createSelector(getAdminState, (state: AdminState) => {
        const section = getAllSectionsAsArray(state.newForm.sections)?.find(
            (section) => section.id === sectionId
        );
        if (elementIndex < 0) {
            return {
                value: section ? section[`uniqueId${elementIndex * -1}`] : '',
            };
        }

        if (section?.components) {
            const currentComponent = section?.components[componentIndex];
            const allElements = currentComponent?.elements;
            const element =
                allElements !== undefined
                    ? allElements[elementIndex]
                    : { defaultValue: '' };

            const isLabelElement = componentElementService.isLabel(element);

            if (isLabelElement && element.autoValue) {
                return { value: '' };
            }

            return {
                value: element?.defaultValue,
            };
        }
        return { value: '' };
    });

function findSectionById(state: AdminState, sectionId: string): Section {
    return getAllSectionsAsArray(state.newForm.sections)?.find(
        (section) => section?.id === sectionId
    );
}

//Deprecated: use ComponentElementService to get label value instead
export const getComponentLabel = (
    sectionId: string,
    componentIndex: number
) => {
    return createSelector<any, any[], { label: string }>(
        getAdminState,
        (state: AdminState) => {
            const component = findSectionById(state, sectionId)?.components[
                componentIndex
            ];
            return {
                label:
                    component?.elements?.find((e) => e.name === 'label')
                        ?.defaultValue ?? component?.name,
            };
        }
    );
};

export const getSectionById = (sectionId: string) => {
    return createSelector<any, any[], GetSectionByIdType>(
        getAdminState,
        (state: AdminState) => ({
            section: findSectionById(state, sectionId),
        })
    );
};

export const getUniqueIdState = (
    uniqueId: string,
    uniqueIdPath: string[],
    sectionId: string
) =>
    createSelector<any, any[], GetUniqueIdStateType>(
        getAdminState,
        (state: AdminState) => {
            const uniqueIdName = convertKebabCaseToPascalCase(uniqueId);

            if (uniqueIdPath.length === 0) {
                return {
                    usedInSections:
                        state.onboardingSchema[uniqueIdName]?.usedInSections,
                };
            } else {
                const parentUniqueId = getUniqueIdsFromPath(
                    state.onboardingSchema,
                    uniqueIdPath.slice(0, uniqueIdPath.length - 1),
                    sectionId
                ).find(
                    (uniqueId) =>
                        uniqueId.key === uniqueIdPath[uniqueIdPath.length - 1]
                );

                const modelName = parentUniqueId.type.replace('[]', '');

                return {
                    usedInSections:
                        state.onboardingSchema.Models[modelName][uniqueIdName]
                            ?.usedInSections,
                };
            }
        }
    );

export const getDefaultDictionaries = createSelector<
    any,
    any[],
    GetDefaultDictionariesType
>(getAdminState, (state: AdminState) => {
    return {
        dictionaries: state.newForm.defaultDictionaries,
    };
});

export const getDefaultDictionaryForType = (
    sectionId: string,
    componentIndex: number,
    dictionaryType: 'address' | 'contact'
) =>
    createSelector<any, any[], GetDefaultDictionaryForTypeType>(
        getAdminState,
        getSectionById(sectionId),
        (state: AdminState, getSectionById: GetSectionByIdType) => {
            if (!getSectionById.section?.components) {
                return {
                    dictionary: [],
                };
            }
            const componentUniqueId = componentElementsService.getUniqueId(
                getSectionById.section.components[componentIndex]
            );

            const uniqueIdType =
                componentUniqueId ?? getSectionById.section.uniqueId1;
            const dict = state.newForm.defaultDictionaries[
                dictionaryType
            ] as DefaultDictionary<Address | Contact>;
            return {
                dictionary: dict.filter(
                    (a) => a.dictionaryUniqueIdType == uniqueIdType
                ),
            };
        }
    );

export const getSectionTrigger = (
    sectionId: string,
    uniqueIdPath: string[],
    parentSectionId: string
) =>
    createSelector<any, any[], GetSectionTriggerType>(
        getAdminState,
        (state: AdminState) => {
            const section = findSectionById(state, sectionId);

            if (section === undefined) {
                return undefined;
            }

            const { trigger, triggerValue } = section;
            let triggerType = '';
            let triggerSourceComponent;
            if (trigger) {
                const parentSection = findSectionById(state, parentSectionId);
                triggerSourceComponent = parentSection.components.find((c) => {
                    const componentUniqueId =
                        componentElementsService.getUniqueId(c);
                    return componentUniqueId === trigger;
                });
                const uniqueIdName = convertKebabCaseToPascalCase(trigger);

                if (uniqueIdPath.length === 0) {
                    triggerType = state.onboardingSchema[uniqueIdName].value;
                } else {
                    const parentUniqueId = getUniqueIdsFromPath(
                        state.onboardingSchema,
                        uniqueIdPath.slice(0, uniqueIdPath.length - 1),
                        sectionId
                    ).find(
                        (uniqueId) =>
                            uniqueId.key ===
                            uniqueIdPath[uniqueIdPath.length - 1]
                    );

                    const modelName = parentUniqueId.type.replace('[]', '');
                    triggerType =
                        state.onboardingSchema.Models[modelName][uniqueIdName]
                            .value;
                }
            }

            const selector: GetSectionTriggerType = {
                trigger,
                triggerValue,
                triggerType,
                allowedValues: triggerSourceComponent
                    ? componentElementsService.getAllowedValues(
                          triggerSourceComponent
                      )
                    : '',
            };
            return selector;
        }
    );

export const getComponentUniqueId = (
    sectionId: string,
    componentIndex: number
) =>
    createSelector(getAdminState, (state) => {
        const section = findSectionById(state, sectionId);
        const component = section.components[componentIndex];

        return componentElementsService.getUniqueId(component);
    });

export const getComponentDefaultValue = <T>(
    sectionId: string,
    componentIndex: number
) =>
    createSelector(getAdminState, (state) => {
        const section = findSectionById(state, sectionId);
        const component = section.components[componentIndex];
        return component?.defaultValue as T;
    });

export interface GetAllOptionsMapComponents {
    maps: Array<{ label: string; content: Array<Option> }>;
}

export const getAllOptionsMapComponents = createSelector<
    any,
    AdminState,
    GetAllOptionsMapComponents
>(getAdminState, (state) => {
    const optionMaps = componentTreeAsAnArray(state.newForm.sections, undefined)
        .filter((c) => {
            if (c.component !== MenuComponent.OptionsMap) {
                return false;
            }

            const mapContent = componentElementsService.getOptionsMapContent(c);
            return mapContent !== undefined && mapContent.length > 0;
        })
        .map((optionMap) => {
            const parentSection = findSectionById(
                state,
                optionMap.parentSectionId
            );

            return {
                label:
                    componentElementsService.getLabel(optionMap) ??
                    parentSection?.name,
                content:
                    componentElementsService.getOptionsMapContent(optionMap),
            };
        });

    return { maps: optionMaps };
});

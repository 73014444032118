import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AnalystFormTemplateResolver } from './resolvers/analyst-form-view-component-resolver.service';
import { AnalystFormViewSubmissionResolver } from './resolvers/analyst-form-view-submission-resolver.service';
import { AnalystFormViewComponent } from './form-filler/analyst-form-view/analyst-form-view.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'form-list',
        loadChildren: () =>
            import('./form-list/form-list.module').then(
                (m) => m.FormListModule
            ),
    },
    {
        path: 'analyst',
        children: [
            {
                path: 'form-list',
                loadChildren: () =>
                    import('./analyst-form-list/analyst-form-list.module').then(
                        (m) => m.AnalystFormListModule
                    ),
            },
            {
                path: 'template/:id',
                component: AnalystFormViewComponent,
                resolve: { data: AnalystFormTemplateResolver },
            },
            {
                path: 'form/:id',
                component: AnalystFormViewComponent,
                resolve: { data: AnalystFormViewSubmissionResolver },
            },
        ],
    },
    {
        path: 'form',
        redirectTo: 'form/',
        pathMatch: 'full',
    },
    {
        path: 'form',
        loadChildren: () =>
            import('./new-form/new-form.module').then((m) => m.NewFormModule),
    },
    {
        path: 'data-store',
        loadChildren: () =>
            import('./data-store/data-store.module').then(
                (m) => m.DataStoreModule
            ),
    },
    {
        path: 'publication',
        loadChildren: () =>
            import('./publication/publication.module').then(
                (m) => m.PublicationModule
            ),
    },
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

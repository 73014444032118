import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SelectOption {
    key: string;
    value: string;
    disabled: boolean;
}

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
    @Input()
    public label: string = '';

    @Input()
    public value: string | boolean;

    @Input()
    public options: Array<SelectOption>;

    @Input()
    public placeholder: string;

    @Output()
    public valueChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    handleValueChanged($event: any) {
        this.valueChanged.emit($event);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-new-address-step-region-general',
    templateUrl: './step-region-general.component.html',
    styleUrls: ['./step-region-general.component.scss'],
})
export class StepRegionGeneralComponent {
    @Input()
    public region: string;

    @Output()
    public regionChanged: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public nextStep: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public previousStep: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    handleRegionChanged($event: any) {
        this.regionChanged.emit($event);
    }

    handleNextStep() {
        this.nextStep.emit();
    }

    handlePreviousStep() {
        this.previousStep.emit();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    getFormsSelect,
    GetFormsSelect,
} from '../../../store/form-api/form-api.selector';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
    @Input()
    public activeFilterId: string = '';

    @Output()
    public filterClick: EventEmitter<string> = new EventEmitter<string>();

    public isContentVisible = false;
    public getForms$: Observable<GetFormsSelect>;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.getForms$ = this.store.select(getFormsSelect);
    }

    setContentVisible() {
        this.isContentVisible = true;
    }

    handleClick(id: string) {
        this.filterClick.emit(id);
    }
}

export enum MenuComponent {
    Title = 'title',
    Address = 'address',
    Contact = 'contact',
    Contacts = 'contacts',
    Origin = 'origin',
    Origins = 'origins',
    Origin2 = 'origin2',
    Origins2 = 'origins2',
    TextBox = 'text-box',
    Select = 'select',
    FreeTextSelect = 'free-text-select',
    Checkbox = 'checkbox',
    CompanyName = 'company-name',
    CompanyAddress = 'company-address',
    Destination = 'destination',
    Destinations = 'destinations',
    Service = 'service',
    Services = 'services',
    Options = 'options',
    RadioBox = 'radio-box',
    Section = 'Section',
    Repeater = 'Repeater',
    Toggle = 'Toggle',
    YesNo = 'YesNo',
    UniqueId = 'UniqueId',
    OptionsMap = 'OptionsMap',
    OptionsMapContent = 'OptionsMapContent',
}

export enum MenuConstraints {
    Required = 'required',
    MaxLength = 'maxlength',
    MinLength = 'minlength',
    Enabled = 'enabled',
}

export enum TitleValues {
    Title = 'title',
}

export enum AddressValues {
    Line = 'line',
    Line1 = 'line1',
    Line2 = 'line2',
    Line3 = 'line3',
    City = 'city',
    State = 'state',
    StateProv = 'stateprov',
    PostCode = 'postcode',
    Country = 'country',
}

export enum ContactValues {
    ContactType = 'contact-type',
    Name = 'name',
    Title = 'title',
    Phone = 'phone',
    Email = 'email',
}

export enum OriginValues {
    Nickname = 'nickname',
    Name = 'name',
    ShippingAddress = 'shippingAddress',
    ReturnAddress = 'returnAddress',
    CheckboxSame = 'checkboxSame',
}

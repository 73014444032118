import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { v4 } from 'uuid';
import { Value } from '../base-new-value';

export interface NewOptionModalWindowComponentDialogResult {
    value?: string;
    sort?: number;
    model?: any;
    optionId?: string;
    actionType: 'upsert' | 'delete';
}

export interface NewOptionModalWindowComponentDialogData {
    type: 'address' | 'contact' | 'simple';
}

@Component({
    selector: 'app-new-option-modal-window',
    templateUrl: './new-option-modal-window.component.html',
    styleUrls: ['./new-option-modal-window.component.scss'],
})
export class NewOptionModalWindowComponent {
    public selectedOptionModel: any;

    constructor(
        public dialogRef: MatDialogRef<
            NewOptionModalWindowComponent,
            NewOptionModalWindowComponentDialogResult
        >,
        @Inject(MAT_DIALOG_DATA)
        public data: NewOptionModalWindowComponentDialogData
    ) {}

    handleClose() {
        this.dialogRef.close();
    }

    handleChangeState($event: { data?: Value }) {
        this.dialogRef.close({
            value: $event.data.label,
            model: $event.data,
            optionId: v4(),
            actionType: 'upsert',
        });
    }
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { getForms } from '../store/form-api/form-api.actions';
import { clearForm, editForm } from '../store/admin/admin.action';
import { isFormApiStateInitialized } from '../store/form-api/form-api.selector';
import { retrieveDataStores } from '../store/data-store/data-store.action';

@Injectable()
export class NewFormResolver implements Resolve<any> {
    constructor(private store: Store) {}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.store.dispatch(retrieveDataStores());
        this.store
            .select(isFormApiStateInitialized)
            .subscribe((isInitialized) => {
                if (!isInitialized) {
                    this.store.dispatch(getForms({ formName: '' }));
                }

                if (route.params.id) {
                    this.store.dispatch(editForm({ formId: route.params.id }));
                } else {
                    this.store.dispatch(clearForm());
                }
            });
    }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-accordion-header',
    templateUrl: './accordion-header.component.html',
    styleUrls: ['./accordion-header.component.scss'],
})
export class AccordionHeaderComponent {
    @Input()
    public collapsed: boolean;

    @Input()
    public showBackground: boolean;

    @Input()
    public darkHeader: boolean;

    @Output()
    public toggle: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    handleCollapse() {
        this.toggle.emit();
    }
}

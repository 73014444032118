import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { PublicationDto } from './dto/publication.dto';
import { Section } from '../../models/section';

type PublicationPostRequestBody = Omit<
    PublicationDto,
    'id' | 'isActive' | 'submissionName' | 'createdAt'
>;

@Injectable({
    providedIn: 'root',
})
export class PublicationService {
    constructor(private httpService: HttpService) {}

    fetch(templateId: string = '') {
        let requestSender =
            this.httpService.get<PublicationDto[]>('publication');

        if (templateId !== '') {
            requestSender = requestSender.withQuery('templateId', templateId);
        }
        return requestSender.send();
    }

    create(
        submissionId: string,
        templateId: string,
        dictionaries: unknown,
        publication: unknown,
        additionalNotes: string,
        inputForm: Section[]
    ) {
        return this.httpService
            .post<PublicationDto, PublicationPostRequestBody>('publication')
            .withBody({
                submissionId,
                templateId,
                dictionaries,
                publication,
                additionalNotes,
                inputForm,
            })
            .send();
    }

    markAsInvalid(publicationId: string) {
        return this.httpService
            .put<PublicationDto, unknown>(
                `publication/${publicationId}/invalid`
            )
            .send();
    }
}

import { PublicationState } from './model';
import { Action, createReducer, on } from '@ngrx/store';
import {
    createPublicationSuccess,
    fetchAllPublicationsSuccess,
    markPublicationAsInvalidSuccess,
} from './publication.action';
import { act } from '@ngrx/effects';

const initialState: PublicationState = {
    publications: [],
};

const createPublicationReducer = createReducer(
    initialState,
    on(createPublicationSuccess, (state, action) => {
        return {
            ...state,
            publications: [
                ...state.publications,
                {
                    id: action.id,
                    isActive: action.isActive,
                    additionalNotes: action.additionalNotes,
                    submissionName: action.submissionName,
                    inputForm: action.inputForm,
                    createdAt: action.createdAt,
                },
            ],
        };
    }),
    on(fetchAllPublicationsSuccess, (state, action) => {
        return {
            ...state,
            publications: action.data,
        };
    }),
    on(markPublicationAsInvalidSuccess, (state, action) => {
        const publicationIndex = state.publications.findIndex(
            (publication) => publication.id === action.id
        );
        return {
            ...state,
            publications: [
                ...state.publications.slice(0, publicationIndex),
                {
                    id: action.id,
                    isActive: action.isActive,
                    additionalNotes: action.additionalNotes,
                    submissionName: action.submissionName,
                    inputForm: action.inputForm,
                    createdAt: action.createdAt,
                },
                ...state.publications.slice(publicationIndex + 1),
            ],
        };
    })
);

export function publicationReducer(
    state: PublicationState | undefined,
    action: Action
) {
    return createPublicationReducer(state, action);
}

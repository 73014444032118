import { MenuComponent } from '../../enums/components';
import { v4 } from 'uuid';
import { FormComponentType } from '../../new-form/upsert-component/upsert-component.component';
import { Section } from '../../models/section';
import { componentTreeAsAnArray } from '../../utils/component-tree';
import { AdminState } from './model';
import { convertKebabCaseToTitleCase } from '../../utils/text-utils';

export const defaultAccordionCollapsedValue = false;

// -- PRIVATE MEMBERS --

function deleteSectionById(sections: Section[], id: string): Section[] {
    let filteredSections = sections.filter((section) => section?.id !== id);
    if (filteredSections.length === sections.length) {
        filteredSections.forEach((section) => {
            if (section?.components?.length > 0) {
                section.components = deleteSectionById(section.components, id);
            }
        });
    }

    return filteredSections;
}

function createReorderedSection(
    s,
    parentNumber: string,
    currentNumber: number
) {
    const reorderedSection = {
        ...s,
        number: parentNumber
            ? `${parentNumber}.${currentNumber}`
            : currentNumber.toString(),
        components: changeSectionNumbers(
            s.components,
            currentNumber.toString()
        ),
    };
    return reorderedSection;
}

// -- EXPORTED FUNCTIONS --

export function buildTreeWithNewSubsection(
    sections: Section[],
    parentSectionId: string,
    sectionType: 'repeater' | 'section',
    title?: string
): Section[] {
    const writableSections = createWritableSectionListCopy(sections);
    const parentSection = getSectionById(writableSections, parentSectionId);

    const newSection: Section = {
        component:
            sectionType === 'section'
                ? MenuComponent.Section
                : MenuComponent.Repeater,
        id: v4(),
        name: title ?? '',
        components: [],
        sort: parentSection.components?.length + 1 ?? 0,
        accordionCollapsed: defaultAccordionCollapsedValue,
    };

    parentSection.components = [
        ...(parentSection.components ?? []),
        newSection,
    ];
    return writableSections;
}

export function buildTreeWithNewComponent(
    sections: Section[],
    data: any,
    sectionId: string
): Section[] {
    const newSections = createWritableSectionListCopy(sections);
    const section = getSectionById(newSections, sectionId);
    section.components = [...(section?.components ?? []), data];

    return newSections;
}

export function buildTreeWithUpdatedSection(
    sections: Section[],
    id: string,
    newTitle: string
) {
    const newSections = createWritableSectionListCopy(sections);
    let sectionToUpdate = getSectionById(newSections, id);
    if (newTitle === '') {
        sectionToUpdate.name = convertKebabCaseToTitleCase(
            sectionToUpdate.uniqueId1
        );
    } else {
        sectionToUpdate.name = newTitle;
    }

    return newSections;
}

export function buildTreeWithUpdatedComponent(
    sections: Section[],
    sectionId: string,
    componentIndex: number,
    elementIndex: number,
    value: string
) {
    const newSections = createWritableSectionListCopy(sections);
    let sectionToUpdate = getSectionById(newSections, sectionId);
    sectionToUpdate.components[componentIndex].elements[
        elementIndex
    ].defaultValue = value;
    return newSections;
}

export function buildTreeWithoutDeletedComponent(
    sections: Section[],
    sectionId: string,
    componentIndex: number
) {
    const newSections = createWritableSectionListCopy(sections);
    let sectionToUpdate = getSectionById(newSections, sectionId);
    sectionToUpdate.components = sectionToUpdate.components.filter(
        (c, index) => index !== componentIndex
    );
    return newSections;
}

export function buildTreeWithoutDeletedSection(
    sections: Section[],
    id: string
): Section[] {
    let newSections = createWritableSectionListCopy(sections);
    newSections = deleteSectionById(newSections, id);
    return newSections;
}

export function reorderSections(sourceParentSection: Section, action, state) {
    const sectionsToUpdate = sourceParentSection.components
        .filter(
            (s) =>
                s.sort >= action.section.sort &&
                s.id !== state.draggedSection.id
        )
        .sort((a, b) => a.sort - b.sort);
    const newSections: Section[] = JSON.parse(
        JSON.stringify(state.newForm.sections)
    );
    const treeAsArray = componentTreeAsAnArray(newSections, undefined);
    let sortNumber = action.section.sort;
    treeAsArray.find((s) => s.id === state.draggedSection.id).sort = sortNumber;
    sectionsToUpdate.forEach((sectionToUpdate) => {
        sortNumber++;
        treeAsArray.find((s) => s.id === sectionToUpdate.id).sort = sortNumber;
    });
    return newSections;
}

export function changeSectionNumbers(sections: any, parentNumber?: string) {
    let currentNumber = 1;
    const newSections = sections
        .sort((a, b) => a.sort - b.sort)
        .map((s) => {
            if (s.component === MenuComponent.Section) {
                const reorderedSection = createReorderedSection(
                    s,
                    parentNumber,
                    currentNumber
                );

                currentNumber++;
                return reorderedSection;
            } else {
                currentNumber++;
                return s;
            }
        });
    return newSections;
}

export function createSectionsWithDroppedComponent(
    sections: Section[],
    sourceComponentIndex: number,
    sourceComponentValue: FormComponentType,
    component: FormComponentType,
    componentIndex: number,
    sectionId: string
) {
    const newSections = createWritableSectionListCopy(sections);
    const sectionToUpdate = getSectionById(newSections, sectionId);

    const componentsWithoutDraggedOne = [
        ...sectionToUpdate.components.slice(0, sourceComponentIndex),
        ...sectionToUpdate.components.slice(sourceComponentIndex + 1),
    ];

    const droppedComponentIndex =
        sourceComponentIndex < componentIndex
            ? componentIndex - 1
            : componentIndex;

    sectionToUpdate.components = [
        ...componentsWithoutDraggedOne.slice(0, droppedComponentIndex),
        sourceComponentValue,
        component,
        ...componentsWithoutDraggedOne.slice(droppedComponentIndex + 1),
    ];

    return newSections;
}

export function getSectionById(writableSections, sectionId: string) {
    return componentTreeAsAnArray(writableSections, undefined).find(
        (section) => section?.id === sectionId
    );
}

export function createWritableSectionListCopy(sections: Section[]) {
    return JSON.parse(JSON.stringify(sections));
}

export function componentDragged(sectionId, componentIndex, state) {
    return (
        state?.draggedComponent?.sectionId === sectionId &&
        state?.draggedComponent?.componentIndex !== componentIndex
    );
}

export function sectionDragged(action, state) {
    const section = getSectionById(state.newForm.sections, action.sectionId);
    return (
        section?.components
            ?.filter((c) =>
                [MenuComponent.Repeater, MenuComponent.Section].includes(
                    c.component
                )
            )
            ?.find((c) => c.id === state?.draggedSection?.id) !== undefined
    );
}

export function getIndexOfDraggedSubsection(
    sections,
    parentSectionId,
    subsectionId
) {
    const section = getSectionById(
        createWritableSectionListCopy(sections),
        parentSectionId
    );

    return section.components.findIndex((c) => c.id === subsectionId);
}

export function updateContainerComponent(
    uniqueIdValue: string,
    sectionId: string,
    state: AdminState,
    elementIndex: number
): AdminState {
    let newSections = createWritableSectionListCopy(state.newForm.sections);
    let sectionToUpdate = getSectionById(newSections, sectionId);
    const oldUniqueIdTitle = convertKebabCaseToTitleCase(
        sectionToUpdate[`uniqueId${elementIndex * -1}`]
    );
    sectionToUpdate[`uniqueId${elementIndex * -1}`] = uniqueIdValue;

    if (
        [MenuComponent.Section, MenuComponent.Repeater].includes(
            sectionToUpdate.component
        )
    ) {
        const name = sectionToUpdate['name'];

        if (name === oldUniqueIdTitle || oldUniqueIdTitle === undefined) {
            sectionToUpdate['name'] =
                convertKebabCaseToTitleCase(uniqueIdValue);
        }
    }

    return {
        ...state,
        newForm: {
            ...state.newForm,
            sections: newSections,
        },
    };
}

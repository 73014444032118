import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

class HttpRequestSender<Res, Body> {
    constructor(
        private method: 'get' | 'post' | 'put' | 'delete',
        private http: HttpClient,
        private endpoint: string,
        private queryString: string,
        private body: Body
    ) {}

    send() {
        switch (this.method) {
            case 'get':
                return this.http.get<Res>(this.buildUrl());
            case 'post':
                return this.http.post<Res>(this.buildUrl(), this.body);
            case 'put':
                return this.http.put<Res>(this.buildUrl(), this.body);
            case 'delete':
                return this.http.delete<Res>(this.buildUrl());
        }
    }

    withBody(body: Body) {
        return new HttpRequestSender<Res, Body>(
            this.method,
            this.http,
            this.endpoint,
            this.queryString,
            body
        );
    }

    withQuery(key: string, value: string) {
        return new HttpRequestSender<Res, Body>(
            this.method,
            this.http,
            this.endpoint,
            this.queryString === ''
                ? `${key}=${value}`
                : `${this.queryString}&${key}=${value}`,
            this.body
        );
    }

    private buildUrl() {
        return `${environment.backendUrl}/${this.endpoint}${
            this.queryString !== '' ? `?${this.queryString}` : ''
        }`;
    }
}

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient) {}

    public get<Res>(endpoint: string) {
        return new HttpRequestSender<Res, undefined>(
            'get',
            this.http,
            endpoint,
            '',
            undefined
        );
    }

    public post<Res, Body>(endpoint: string) {
        return new HttpRequestSender<Res, Body>(
            'post',
            this.http,
            endpoint,
            '',
            undefined
        );
    }

    public put<Res, Body>(endpoint: string) {
        return new HttpRequestSender<Res, Body>(
            'put',
            this.http,
            endpoint,
            '',
            undefined
        );
    }

    public delete<Res>(endpoint: string) {
        return new HttpRequestSender<Res, undefined>(
            'delete',
            this.http,
            endpoint,
            '',
            undefined
        );
    }
}

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Option } from '../../../../models/components/options-map';

@Component({
    selector: 'app-analyst-options-map',
    templateUrl: './analyst-options-map.component.html',
    styleUrls: ['./analyst-options-map.component.scss'],
})
export class AnalystOptionsMapComponent implements OnInit, OnChanges {
    @Input()
    value: Array<Option>;

    @Input()
    public options: Array<Option>;

    @Input()
    readOnly: boolean;

    @Input()
    public label: string = '';

    @Input()
    sectionId: string;

    @Input()
    clonedId: string;

    public visibleOptions: Array<Option>;

    @Output()
    valueChanged: EventEmitter<Array<Option>> = new EventEmitter<
        Array<Option>
    >();

    constructor() {}

    ngOnInit(): void {
        this.visibleOptions = this.value ? this.value : this.options;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.value) {
            this.visibleOptions = changes.value.currentValue;
        }
    }

    handleTextValueChange($event: any, index: number) {
        this.valueChanged.emit([
            ...this.visibleOptions.slice(0, index),
            { ...this.visibleOptions[index], value: $event },
            ...this.visibleOptions.slice(index + 1),
        ]);
    }

    tracker(index, item) {
        return index;
    }

    isComponentRequired(option: Option) {
        return option.required ?? false;
    }

    isComponentValid(option: Option) {
        return option.value ?? option.defaultValue !== undefined;
    }
}

import { Action, createReducer, on } from '@ngrx/store';
import {
    addNewComponent,
    addNewSection,
    addValueToDefaultDictionary,
    changeStateOnUniqueId,
    clearForm,
    componentDragStart,
    componentDrop,
    deleteComponent,
    deleteSection,
    editFormSuccess,
    resetDefaultDictionary,
    saveOnboardingSchema,
    sectionDragStart,
    sectionDrop,
    selectComponent,
    setComponentDefaultValue,
    setSectionTrigger,
    setSectionTriggerValue,
    toggleAccordion,
    updateComponent,
    updateSection,
} from './admin.action';
import { v4 } from 'uuid';
import { MenuComponent } from '../../enums/components';
import {
    buildTreeWithNewComponent,
    buildTreeWithNewSubsection,
    buildTreeWithoutDeletedComponent,
    buildTreeWithoutDeletedSection,
    buildTreeWithUpdatedComponent,
    buildTreeWithUpdatedSection,
    changeSectionNumbers,
    componentDragged,
    createSectionsWithDroppedComponent,
    createWritableSectionListCopy,
    getIndexOfDraggedSubsection,
    getSectionById,
    reorderSections,
    sectionDragged,
    updateContainerComponent,
    defaultAccordionCollapsedValue,
} from './reducer-functions';
import { Section } from '../../models/section';
import { AdminState } from './model';
import { getUniqueIdsFromPath } from './onboarding-schema-functions';
import {
    convertKebabCaseToPascalCase,
    convertKebabCaseToTitleCase,
} from '../../utils/text-utils';
import { ComponentElementsService } from '../../core/component-elements.service';
import { ComponentElementService } from '../../core/component-element.service';
import { componentTreeAsAnArray } from '../../utils/component-tree';

const initialState: AdminState = {
    newForm: {
        sections: [],
        selectedComponent: {
            name: '',
            iconPosition: 'rtl',
            label: '',
            isCustom: false,
        },
        defaultDictionaries: {
            address: [],
            contact: [],
        },
    },
    customComponents: [],
};
const componentElementService = new ComponentElementService();
const componentElementsService = new ComponentElementsService(
    componentElementService
);

function createMainSection(state: AdminState): AdminState {
    const newSection: Section = {
        component: MenuComponent.Section,
        id: v4(),
        number: (state.newForm.sections.length + 1).toString(),
        name: '',
        components: [],
        sort: state.newForm.sections.length + 1,
        accordionCollapsed: defaultAccordionCollapsedValue,
    };

    return {
        ...state,
        newForm: {
            ...state.newForm,
            sections: [...state.newForm.sections, newSection],
        },
    };
}

function createSubsection(
    state,
    action: {
        parentSectionId?: string;
        sectionType?: 'repeater' | 'section';
        title?: string;
    }
) {
    return {
        ...state,
        newForm: {
            ...state.newForm,
            sections: buildTreeWithNewSubsection(
                state.newForm.sections,
                action.parentSectionId,
                action.sectionType ?? 'section',
                action.title
            ),
        },
    };
}

function setUniqueId(action, onboardingSchema, state, sectionId) {
    const { uniqueIdPath, value, oldUniqueId, componentType } = action;

    const uniqueIds = getUniqueIdsFromPath(
        onboardingSchema,
        uniqueIdPath,
        sectionId
    );

    const newUniqueIdToUpdate = uniqueIds.find(
        (uniqueId) => uniqueId.key === value
    );

    const oldUniqueIdToUpdate = uniqueIds.find(
        (uniqueId) => uniqueId.key === oldUniqueId
    );

    if (uniqueIdPath.length === 0) {
        onboardingSchema[
            convertKebabCaseToPascalCase(newUniqueIdToUpdate.key)
        ].usedInSections.push(sectionId);
        onboardingSchema[
            convertKebabCaseToPascalCase(newUniqueIdToUpdate.key)
        ].componentType = componentType;

        if (oldUniqueId && oldUniqueId !== value) {
            onboardingSchema[
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].usedInSections = onboardingSchema[
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].usedInSections.filter((section) => section !== sectionId);
            onboardingSchema[
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].componentType = undefined;
        }
    } else {
        const parentUniqueId = getUniqueIdsFromPath(
            state.onboardingSchema,
            uniqueIdPath.slice(0, uniqueIdPath.length - 1),
            sectionId
        ).find(
            (uniqueId) => uniqueId.key === uniqueIdPath[uniqueIdPath.length - 1]
        );
        const modelName = parentUniqueId.type.replace('[]', '');
        onboardingSchema.Models[modelName][
            convertKebabCaseToPascalCase(newUniqueIdToUpdate.key)
        ].usedInSections.push(sectionId);
        onboardingSchema.Models[modelName][
            convertKebabCaseToPascalCase(newUniqueIdToUpdate.key)
        ].componentType = componentType;

        if (oldUniqueId && oldUniqueId !== value) {
            onboardingSchema.Models[modelName][
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].usedInSections = onboardingSchema.Models[modelName][
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].usedInSections.filter((section) => section !== sectionId);
            onboardingSchema.Models[modelName][
                convertKebabCaseToPascalCase(oldUniqueIdToUpdate.key)
            ].componentType = componentType;
        }
    }
}

const createAdminReducer = createReducer(
    initialState,
    on(
        addNewSection,
        (
            state,
            action: {
                parentSectionId?: string;
                sectionType?: 'repeater' | 'section';
                title?: string;
            }
        ) => {
            if (action.parentSectionId) {
                return createSubsection(state, action);
            } else {
                return createMainSection(state);
            }
        }
    ),
    on(updateSection, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: buildTreeWithUpdatedSection(
                    state.newForm.sections,
                    action.id,
                    action.newTitle
                ),
            },
        };
    }),
    on(selectComponent, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                selectedComponent: {
                    name: action.name,
                    iconPosition: action.iconPosition,
                    label: action.label,
                    isCustom: action.isCustomComponent,
                },
            },
        };
    }),
    on(addNewComponent, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: buildTreeWithNewComponent(
                    state.newForm.sections,
                    action.data,
                    action.sectionId
                ),
            },
        };
    }),
    on(updateComponent, (state, action) => {
        if (action.isUniqueId && action.elementIndex >= 0) {
            const onboardingSchema = JSON.parse(
                JSON.stringify(state.onboardingSchema)
            );
            setUniqueId(action, onboardingSchema, state, action.sectionId);

            const sections = buildTreeWithUpdatedComponent(
                state.newForm.sections,
                action.sectionId,
                action.componentIndex,
                action.elementIndex,
                action.value
            );

            const currentComponent = componentTreeAsAnArray(
                sections,
                undefined
            ).find((section) => section?.id === action.sectionId).components[
                action.componentIndex
            ];

            const labelValue =
                componentElementsService.getLabel(currentComponent);

            if (
                labelValue === convertKebabCaseToTitleCase(action.oldUniqueId)
            ) {
                const labelElement = currentComponent.elements.find(
                    componentElementService.isLabel
                );
                labelElement.defaultValue = convertKebabCaseToTitleCase(
                    action.value
                );
                labelElement.autoValue = true;
            }
            return {
                ...state,
                newForm: {
                    ...state.newForm,
                    sections,
                },
                onboardingSchema,
            };
        }
        if (action.elementIndex < 0) {
            return updateContainerComponent(
                action.value,
                action.sectionId,
                state,
                action.elementIndex
            );
        }
        const sections = buildTreeWithUpdatedComponent(
            state.newForm.sections,
            action.sectionId,
            action.componentIndex,
            action.elementIndex,
            action.value
        );
        const sectionToUpdate = getSectionById(sections, action.sectionId);
        const currentComponent =
            sectionToUpdate.components[action.componentIndex];
        const currentElement = currentComponent.elements[action.elementIndex];

        if (componentElementService.isLabel(currentElement)) {
            if (action.value === '') {
                const uniqueIdValue =
                    componentElementsService.getUniqueId(currentComponent);

                currentElement.defaultValue =
                    convertKebabCaseToTitleCase(uniqueIdValue);
                currentElement.autoValue = true;
            } else {
                currentElement.autoValue = false;
            }
        }

        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections,
            },
        };
    }),
    on(editFormSuccess, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: action.inputData,
                defaultDictionaries: action.dictionaries,
            },
        };
    }),
    on(deleteComponent, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: buildTreeWithoutDeletedComponent(
                    state.newForm.sections,
                    action.sectionId,
                    action.componentIndex
                ),
            },
        };
    }),
    on(deleteSection, (state, action) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: buildTreeWithoutDeletedSection(
                    state.newForm.sections,
                    action.id
                ),
            },
        };
    }),
    on(sectionDragStart, (state, action) => {
        return {
            ...state,
            draggedSection: action.section,
        };
    }),
    on(sectionDrop, (state, action) => {
        const destinationParentSection = getSectionById(
            createWritableSectionListCopy(state.newForm.sections),
            action.parentSectionId
        );
        if (componentDragged(action.parentSectionId, -1, state)) {
            const sourceParentSection = getSectionById(
                createWritableSectionListCopy(state.newForm.sections),
                state.draggedComponent.sectionId
            );

            if (destinationParentSection.id === sourceParentSection.id) {
                const newSections = createSectionsWithDroppedComponent(
                    state.newForm.sections,
                    state.draggedComponent.componentIndex,
                    state.draggedComponent.value,
                    action.section,
                    getIndexOfDraggedSubsection(
                        state.newForm.sections,
                        action.parentSectionId,
                        action.section.id
                    ),
                    action.parentSectionId
                );
                return {
                    ...state,
                    draggedComponent: undefined,
                    newForm: {
                        ...state.newForm,
                        sections: newSections,
                    },
                };
            }
        }
        if (sectionDragged(action, state)) {
            const sourceParentSection = state.draggedSection;

            if (sourceParentSection.id === destinationParentSection.id) {
                let newSections = reorderSections(
                    sourceParentSection,
                    action,
                    state
                );
                newSections = changeSectionNumbers(newSections);
                return {
                    ...state,
                    newForm: {
                        ...state.newForm,
                        sections: newSections,
                    },
                    draggedSection: undefined,
                };
            }
            return {
                ...state,
                draggedSection: undefined,
            };
        }
        return {
            ...state,
        };
    }),
    on(componentDragStart, (state, action) => {
        return {
            ...state,
            draggedComponent: {
                value: action.component,
                componentIndex: action.componentIndex,
                sectionId: action.sectionId,
            },
        };
    }),
    on(componentDrop, (state, action) => {
        if (componentDragged(action.sectionId, action.componentIndex, state)) {
            return {
                ...state,
                draggedComponent: undefined,
                newForm: {
                    ...state.newForm,
                    sections: createSectionsWithDroppedComponent(
                        state.newForm.sections,
                        state.draggedComponent.componentIndex,
                        state.draggedComponent.value,
                        action.component,
                        action.componentIndex,
                        action.sectionId
                    ),
                },
            };
        }
        if (sectionDragged(action, state)) {
            return {
                ...state,
                draggedSection: undefined,
                newForm: {
                    ...state.newForm,
                    sections: createSectionsWithDroppedComponent(
                        state.newForm.sections,
                        getIndexOfDraggedSubsection(
                            state.newForm.sections,
                            action.sectionId,
                            state.draggedSection?.id
                        ),
                        state.draggedSection,
                        action.component,
                        action.componentIndex,
                        action.sectionId
                    ),
                },
            };
        }
        return {
            ...state,
        };
    }),
    on(saveOnboardingSchema, (state, action) => {
        return {
            ...state,
            onboardingSchema: action.schema,
        };
    }),
    on(changeStateOnUniqueId, (state, action) => {
        const newOnboardingSchema = JSON.parse(
            JSON.stringify(state.onboardingSchema)
        );
        const uniqueIdName = convertKebabCaseToPascalCase(action.uniqueId);

        if (action.uniqueIdPath.length === 0) {
            newOnboardingSchema[uniqueIdName].usedInSections =
                action.deleteUniqueId
                    ? [
                          ...newOnboardingSchema[
                              uniqueIdName
                          ].usedInSections.filter(
                              (section) => section !== action.sectionId
                          ),
                      ]
                    : [
                          ...newOnboardingSchema[uniqueIdName].usedInSections,
                          action.sectionId,
                      ];
            newOnboardingSchema[uniqueIdName].componentType =
                action.componentType;
        } else {
            const parentUniqueId = getUniqueIdsFromPath(
                state.onboardingSchema,
                action.uniqueIdPath.slice(0, action.uniqueIdPath.length - 1),
                action.sectionId
            ).find(
                (uniqueId) =>
                    uniqueId.key ===
                    action.uniqueIdPath[action.uniqueIdPath.length - 1]
            );

            const modelName = parentUniqueId.type.replace('[]', '');

            newOnboardingSchema.Models[modelName][uniqueIdName].usedInSections =
                action.deleteUniqueId
                    ? [
                          ...newOnboardingSchema.Models[modelName][
                              uniqueIdName
                          ].usedInSections.filter(
                              (section) => section !== action.sectionId
                          ),
                      ]
                    : [
                          ...newOnboardingSchema.Models[modelName][uniqueIdName]
                              .usedInSections,
                          action.sectionId,
                      ];

            newOnboardingSchema.Models[modelName][uniqueIdName].componentType =
                action.componentType;
        }

        return {
            ...state,
            onboardingSchema: newOnboardingSchema,
        };
    }),
    on(clearForm, (state) => {
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: [],
            },
        };
    }),
    on(addValueToDefaultDictionary, (state, action) => {
        const section = getSectionById(
            state.newForm.sections,
            action.sectionId
        );
        const component = section.components[action.componentIndex];

        return {
            ...state,
            newForm: {
                ...state.newForm,
                defaultDictionaries: {
                    ...state.newForm.defaultDictionaries,
                    [action.dictionaryType]: [
                        ...state.newForm.defaultDictionaries[
                            action.dictionaryType
                        ],
                        {
                            ...action.value,
                            dictionaryUniqueIdType:
                                componentElementsService.getUniqueId(
                                    component
                                ) ?? section.uniqueId1,
                        },
                    ],
                },
            },
        };
    }),
    on(setComponentDefaultValue, (state, action) => {
        const newSections = createWritableSectionListCopy(
            state.newForm.sections
        );
        const section = getSectionById(newSections, action.sectionId);
        const component = section.components[action.componentIndex];
        component.defaultValue = action.value;
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: newSections,
            },
        };
    }),
    on(setSectionTrigger, (state, action) => {
        const newSections = createWritableSectionListCopy(
            state.newForm.sections
        );
        const section = getSectionById(newSections, action.sectionId);
        section.trigger = action.trigger;
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: newSections,
            },
        };
    }),
    on(setSectionTriggerValue, (state, action) => {
        const newSections = createWritableSectionListCopy(
            state.newForm.sections
        );
        const section: Section = getSectionById(newSections, action.sectionId);
        section.triggerValue = action.triggerValue;
        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: newSections,
            },
        };
    }),
    on(resetDefaultDictionary, (state, action) => {
        const section = getSectionById(
            state.newForm.sections,
            action.sectionId
        );
        const component = section.components[action.componentIndex];

        const dictionaryUniqueIdType =
            componentElementsService.getUniqueId(component) ??
            section.uniqueId1;

        return {
            ...state,
            newForm: {
                ...state.newForm,
                defaultDictionaries: {
                    ...state.newForm.defaultDictionaries,
                    [action.dictionaryType]: state.newForm.defaultDictionaries[
                        action.dictionaryType
                        // @ts-ignore
                    ].filter(
                        (e) =>
                            e.dictionaryUniqueIdType !== dictionaryUniqueIdType
                    ),
                },
            },
        };
    }),
    on(toggleAccordion, (state, action) => {
        const newSections = createWritableSectionListCopy(
            state.newForm.sections
        );
        const section: Section = getSectionById(newSections, action.sectionId);

        if (action.componentIndex === undefined) {
            section.accordionCollapsed = !section.accordionCollapsed;
        } else {
            const componentToUpdate = section.components[action.componentIndex];
            componentToUpdate.accordionCollapsed =
                !componentToUpdate.accordionCollapsed;
        }

        return {
            ...state,
            newForm: {
                ...state.newForm,
                sections: newSections,
            },
        };
    })
);

export function adminReducer(state: AdminState | undefined, action: Action) {
    return createAdminReducer(state, action);
}

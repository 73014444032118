<div
    *ngIf="{ getAllDataStores: getAllDataStores$ | async } as state"
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <mat-form-field appearance="outline" class="full-width label-outside">
        <mat-label>{{ label }}</mat-label>
        <mat-select
            [ngModel]="selectedValue"
            (ngModelChange)="onModelChanged($event)"
            [disabled]="readOnly"
            [multiple]="isMultiSelectBox"
        >
            <mat-option *ngIf="!isMultiSelectBox" [value]=""></mat-option>
            <mat-option
                *ngFor="
                    let option of getAllowedOptions(
                        state.getAllDataStores,
                        otherOptions,
                        allowedValues
                    ) | async
                "
                [value]="option.key"
                >{{ option.value }}</mat-option
            >
            <mat-option *ngIf="shouldOtherOptionBeVisible" value="other"
                >Create new</mat-option
            >
        </mat-select>
    </mat-form-field>
</div>

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomComponentsState } from './custom-components.reducer';
import { ElementValueSelectorType } from '../types/get-section-element-selector';

export interface GetAllCustomComponentsType {
    components: any[];
}
export interface GetComponentDataToEditType {
    data: any[];
}

const getCustomComponentsState =
    createFeatureSelector<CustomComponentsState>('customComponents');

export const getAllCustomComponents = createSelector<
    any,
    any[],
    GetAllCustomComponentsType
>(getCustomComponentsState, (state) => ({ components: state.components }));

export const getCanvasCustomComponentElementValue: ElementValueSelectorType = (
    componentId: string,
    componentIndex: number,
    elementIndex: number
) =>
    createSelector(getCustomComponentsState, (state: CustomComponentsState) => {
        return {
            value:
                state.components.find((c) => c._id === componentId)?.data[
                    componentIndex
                ]?.elements[elementIndex]?.defaultValue ?? '',
        };
    });

export const getComponentDataToEdit = createSelector<
    any,
    any,
    GetComponentDataToEditType
>(getCustomComponentsState, (state: CustomComponentsState) => {
    return { data: state.componentInternalData };
});

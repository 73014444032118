import { Section } from '../models/section';
import { MenuComponent } from '../enums/components';

export function isContainer(section: Section) {
    return (
        section?.component === MenuComponent.Section ||
        section?.component === MenuComponent.Repeater
    );
}

export function componentTreeAsAnArray(
    sections: Section[],
    parentSectionId: string
) {
    return (sections ?? []).flatMap((section) => {
        if (isContainer(section)) {
            return [
                section,
                ...componentTreeAsAnArray(section.components, section.id),
            ];
        }

        return [{ ...section, parentSectionId }];
    });
}

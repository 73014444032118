import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    retrieveDataStores,
    retrieveDataStoresSuccess,
} from './data-store.action';
import { exhaustMap, map } from 'rxjs/operators';
import { DataSourceService } from '../../core/api/data-source.service';

@Injectable()
export class DataStoreEffect {
    constructor(
        private actions$: Actions,
        private dataStoreService: DataSourceService
    ) {}

    retireveDataStores$ = createEffect(() =>
        this.actions$.pipe(
            ofType(retrieveDataStores),
            exhaustMap((action) => {
                return this.dataStoreService.getAll().pipe(
                    map((response) => {
                        return retrieveDataStoresSuccess({
                            dataStores: response,
                        });
                    })
                );
            })
        )
    );
}

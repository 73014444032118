import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseNewValue, Value } from '../base-new-value';
import { SelectOption } from '../select/select.component';

class Contact extends Value {
    constructor(
        label: string,
        public contactType: string,
        public name: string,
        public phone: string,
        public email: string
    ) {
        super(label);
    }
}

@Component({
    selector: 'app-new-contact',
    templateUrl: './new-contact.component.html',
    styleUrls: ['./new-contact.component.scss'],
})
export class NewContactComponent
    extends BaseNewValue<Contact>
    implements OnInit
{
    public static LabelInputLabel = 'Label';
    public static ContactTypeInputLabel = 'Contact Type';
    public static NameInputLabel = 'Name';
    public static PhoneInputLabel = 'Phone';
    public static EmailInputLabel = 'Email';
    public static SaveButtonLabel = 'Save';

    public static MainContactTypeLabel = 'Main';
    public static ShippingContactTypeLabel = 'Shipping';
    public static DistroContactTypeLabel = 'Distro';

    Component = NewContactComponent;

    public contactTypeOptions: Array<SelectOption> = [
        {
            key: 'main',
            value: NewContactComponent.MainContactTypeLabel,
            disabled: false,
        },
        {
            key: 'shipping',
            value: NewContactComponent.ShippingContactTypeLabel,
            disabled: false,
        },
        {
            key: 'distro',
            value: NewContactComponent.DistroContactTypeLabel,
            disabled: false,
        },
    ];

    @Input()
    initialModel: Contact;

    @Output()
    public save: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public modelChanged: EventEmitter<Contact> = new EventEmitter<Contact>();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.model = this.initialModel ?? new Contact('', '', '', '', '');
    }

    handleSave() {
        this.save.emit();
    }

    handleModelChanged($event: string, field: string) {
        this.model = {
            ...this.model,
            [field]: $event,
        };
        this.modelChanged.emit(this.model);
    }
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { loadAnalystFormFromTemplate } from '../store/submission/submission.action';
import { retrieveDataStores } from '../store/data-store/data-store.action';

@Injectable()
export class AnalystFormTemplateResolver implements Resolve<any> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.store.dispatch(retrieveDataStores());
        this.store.dispatch(
            loadAnalystFormFromTemplate({ formId: route.params.id })
        );
    }
}

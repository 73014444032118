import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FormApiService } from '../../form-api.service';
import {
    changeStateOnUniqueId,
    createCustomComponent,
    createCustomComponentSuccess,
    deleteComponent,
    editForm,
    editFormSuccess,
    initUniqueIdUsage,
    nullAction,
    saveAsForm,
    saveForm,
    saveFormSuccess,
} from './admin.action';
import {
    catchError,
    concatMap,
    exhaustMap,
    first,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
    getCanvasSectionElementValue,
    getSectionById,
    getUniqueIdState,
} from './admin.selector';
import { NotificationService } from '../../notification.service';
import { refreshNeeded } from '../general/general.action';
import { StorageService } from '../../core/storage.service';
import { getTime } from 'ngx-bootstrap/chronos/utils/date-getters';

@Injectable()
export class AdminEffect {
    constructor(
        private actions$: Actions,
        private formApiService: FormApiService,
        private router: Router,
        private store: Store,
        private notificationService: NotificationService,
        private storageService: StorageService
    ) {}

    saveForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveForm),
            exhaustMap((a) => {
                let upsert: Observable<any>;
                if (a.formId) {
                    upsert = this.formApiService.updateFormV2(
                        a.formName,
                        a.data,
                        a.formId,
                        a.dictionaries
                    );
                } else {
                    upsert = this.formApiService.uploadForm2(
                        a.formName,
                        a.data,
                        a.dictionaries
                    );
                }
                return upsert.pipe(
                    map((response) => saveFormSuccess()),
                    tap(() => this.router.navigate(['form-list']))
                );
            })
        )
    );

    saveAsForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveAsForm),
            exhaustMap((a) => {
                return this.formApiService
                    .uploadForm2(a.formName, a.data, a.dictionaries)
                    .pipe(
                        map((response) => {
                            this.notificationService.showSuccess(
                                `Form ${a.formName} created successfully`
                            );
                            return saveFormSuccess();
                        })
                    );
            })
        )
    );

    createCustomComponent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createCustomComponent),
            exhaustMap((a) => {
                return this.store.pipe(
                    select(getSectionById(a.id)),
                    first(),
                    concatMap(({ section }) => {
                        return this.formApiService
                            .createCustomComponent(
                                section.name,
                                section.components
                            )
                            .pipe(
                                map((response) =>
                                    createCustomComponentSuccess()
                                )
                            );
                    })
                );
            })
        )
    );

    deleteComponent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteComponent),
            exhaustMap((a) => {
                if (a.uniqueId === undefined) {
                    return of(nullAction());
                }
                return of(
                    changeStateOnUniqueId({
                        uniqueId: a.uniqueId,
                        uniqueIdPath: a.uniqueIdPath,
                        componentType: undefined,
                        sectionId: a.sectionId,
                        deleteUniqueId: true,
                    })
                );
            })
        )
    );

    initUniqueIdUsage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(initUniqueIdUsage),
            switchMap((action) => {
                return this.store.pipe(
                    select(
                        getCanvasSectionElementValue(
                            action.sectionId,
                            action.componentIndex,
                            action.elementIndex
                        )
                    ),
                    first(),
                    concatMap((selector) => {
                        if ((selector?.value ?? '') === '') {
                            return of(nullAction());
                        }

                        return this.store.pipe(
                            select(
                                getUniqueIdState(
                                    selector.value,
                                    action.uniqueIdPath,
                                    action.sectionId
                                )
                            ),
                            first(),
                            map((uniqueIdState) => {
                                if (
                                    uniqueIdState.usedInSections ===
                                        undefined ||
                                    uniqueIdState.usedInSections.length > 0
                                ) {
                                    return nullAction();
                                }
                                return changeStateOnUniqueId({
                                    uniqueId: selector.value,
                                    uniqueIdPath: action.uniqueIdPath,
                                    componentType: action.componentType,
                                    sectionId: action.sectionId,
                                    deleteUniqueId: false,
                                });
                            })
                        );
                    })
                );
            })
        )
    );

    editForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(editForm),
            exhaustMap((action) => {
                return this.formApiService.getFormData('').pipe(
                    map((forms) => {
                        const selectedForm: any = forms.find(
                            (form: any) => form._id === action.formId
                        );
                        return editFormSuccess({
                            inputData: selectedForm.data,
                            dictionaries: selectedForm.dictionaries,
                        });
                    }),
                    catchError((error) => {
                        this.storageService.addValue(
                            {
                                timestamp: new Date().toISOString(),
                                errorMessage: error,
                            },
                            'error',
                            'forever'
                        );
                        return of(refreshNeeded());
                    })
                );
            })
        )
    );
}

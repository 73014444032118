<button
    mat-raised-button
    [disabled]="isDisabled"
    color="primary"
    class="action-button medium-button"
    (click)="handleSaveClick($event)"
>
    {{ Component.SaveFormButtonLabel }}
</button>
<button
    mat-raised-button
    [disabled]="isDisabled"
    color="primary"
    class="action-button medium-button"
    (click)="handleSaveAsClick($event)"
>
    {{ Component.SaveAsFormButtonLabel }}
</button>
<button
    mat-raised-button
    [disabled]="isDisabled"
    color="warn"
    class="action-button medium-button"
    (click)="handleCloseClick($event)"
>
    {{ Component.CloseFormButtonLabel }}
</button>
<!--<mat-menu
    #menu
    class="option-menu"
>
    -->
<!--    <menu-->
<!--        mat-menu-item-->
<!--        (click)="handleSaveAsClick($event)"-->
<!--        class="save-as-button"-->
<!--    >-->
<!--        Save as...-->
<!--    </menu>-->
<!--</mat-menu>-->
<!--</button>-->

<mat-dialog-content>
    <div class="buttons">
        <button mat-raised-button color="warn" (click)="handleClick()">
            Mark as invalid
        </button>
    </div>
    <div *ngFor="let section of dialogData.sections" class="section-preview">
        <app-analyst-form-section
            [components]="section.components"
            [number]="section.number"
            [name]="section.name"
            [sectionId]="section.id"
            [readOnly]="true"
        ></app-analyst-form-section>
    </div>
</mat-dialog-content>

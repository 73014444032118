<span>Specify State</span>
<mat-form-field class="full-width">
    <mat-label>Region</mat-label>
    <mat-select
        [ngModel]="region"
        (ngModelChange)="handleRegionChanged($event)"
    >
        <mat-option></mat-option>
        <mat-option value="AL">AL</mat-option>
        <mat-option value="AK">AK</mat-option>
        <mat-option value="AS">AS</mat-option>
        <mat-option value="AZ">AZ</mat-option>
        <mat-option value="AR">AR</mat-option>
        <mat-option value="CA">CA</mat-option>
        <mat-option value="CO">CO</mat-option>
        <mat-option value="CT">CT</mat-option>
        <mat-option value="DE">DE</mat-option>
        <mat-option value="DC">DC</mat-option>
        <mat-option value="FM">FM</mat-option>
        <mat-option value="FL">FL</mat-option>
        <mat-option value="GA">GA</mat-option>
        <mat-option value="GU">GU</mat-option>
        <mat-option value="HI">HI</mat-option>
        <mat-option value="ID">ID</mat-option>
        <mat-option value="IL">IL</mat-option>
        <mat-option value="IN">IN</mat-option>
        <mat-option value="IA">IA</mat-option>
        <mat-option value="KS">KS</mat-option>
        <mat-option value="KY">KY</mat-option>
        <mat-option value="LA">LA</mat-option>
        <mat-option value="ME">ME</mat-option>
        <mat-option value="MH">MH</mat-option>
        <mat-option value="MD">MD</mat-option>
        <mat-option value="MA">MA</mat-option>
        <mat-option value="MI">MI</mat-option>
        <mat-option value="MN">MN</mat-option>
        <mat-option value="MS">MS</mat-option>
        <mat-option value="MO">MO</mat-option>
        <mat-option value="MT">MT</mat-option>
        <mat-option value="NE">NE</mat-option>
        <mat-option value="NV">NV</mat-option>
        <mat-option value="NH">NH</mat-option>
        <mat-option value="NJ">NJ</mat-option>
        <mat-option value="NM">NM</mat-option>
        <mat-option value="NY">NY</mat-option>
        <mat-option value="NC">NC</mat-option>
        <mat-option value="ND">ND</mat-option>
        <mat-option value="MP">MP</mat-option>
        <mat-option value="OH">OH</mat-option>
        <mat-option value="OK">OK</mat-option>
        <mat-option value="OR">OR</mat-option>
        <mat-option value="PW">PW</mat-option>
        <mat-option value="PA">PA</mat-option>
        <mat-option value="PR">PR</mat-option>
        <mat-option value="RI">RI</mat-option>
        <mat-option value="SC">SC</mat-option>
        <mat-option value="SD">SD</mat-option>
        <mat-option value="TN">TN</mat-option>
        <mat-option value="TX">TX</mat-option>
        <mat-option value="UT">UT</mat-option>
        <mat-option value="VT">VT</mat-option>
        <mat-option value="VI">VI</mat-option>
        <mat-option value="VA">VA</mat-option>
        <mat-option value="WA">WA</mat-option>
        <mat-option value="WV">WV</mat-option>
        <mat-option value="WI">WI</mat-option>
        <mat-option value="WY">WY</mat-option>
    </mat-select>
</mat-form-field>
<div class="flex">
    <button
        mat-raised-button
        (click)="handlePreviousStep()"
        style="flex-grow: 1"
    >
        Previous
    </button>
    <button
        mat-raised-button
        class="ml-1"
        (click)="handleNextStep()"
        style="flex-grow: 1"
    >
        Next
    </button>
</div>

<mat-form-field appearance="outline" class="full-width label-outside">
    <mat-label>{{ label }}</mat-label>
    <input
        matInput
        [ngModel]="value ?? defaultValue"
        (ngModelChange)="handleModelChanged($event)"
        [disabled]="readOnly"
        [attr.data-cy]="
            getInputTestId(clonedId, sectionId, label, optionsMapLabel)
        "
    />
</mat-form-field>

import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuComponent } from '../../../enums/components';
import { GetCustomComponentResponseBody } from '../../../custom-components-api.service';
import { ComponentElementsService } from '../../../core/component-elements.service';
import {
    storeComponentAdditionalValue,
    storeComponentValue,
} from 'src/app/store/submission/submission.action';

@Component({
    selector: 'app-analyst-form-section-element',
    templateUrl: './analyst-form-section-element.component.html',
    styleUrls: ['./analyst-form-section-element.component.scss'],
})
export class AnalystFormSectionElementComponent {
    @Input()
    public component: any;

    @Input()
    public customComponents: any;

    @Input()
    public sectionId: any;

    @Input()
    public sectionUniqueId: string;

    @Input()
    public clonedId: string;

    @Input()
    public componentIndex: number;

    @Input()
    public readOnly: boolean = false;

    public menuComponent = MenuComponent;

    constructor(
        private store: Store,
        private componentElementService: ComponentElementsService
    ) {}

    getComponentLabel(componentData: any) {
        return this.componentElementService.getLabel(componentData);
    }

    getAllowedValues(componentData: any) {
        return this.componentElementService.getAllowedValues(componentData);
    }

    getConstraint(componentData: any, name: string): boolean {
        return this.componentElementService.getConstraint(componentData, name);
    }

    getIsMultipleSelectBox(componentData: any) {
        const uniqueId =
            this.componentElementService.getUniqueId(componentData);

        return uniqueId === 'service-types';
    }

    getCustomComponentElements(
        id: string,
        customComponents: GetCustomComponentResponseBody
    ) {
        const components = customComponents?.find((c) => c._id === id)?.data;
        return components;
    }

    handleComponentValueChanged($event: any) {
        this.store.dispatch(
            storeComponentValue({
                sectionId: this.sectionId,
                componentIndex: this.componentIndex,
                value: $event,
                clonedId: this.clonedId,
            })
        );
    }

    getOptionsMapContent(component: any) {
        return this.componentElementService.getOptionsMapContent(component);
    }

    getUniqueId(component) {
        return (
            this.componentElementService.getUniqueId(component) ??
            this.sectionUniqueId
        );
    }

    getDictionaryId(component: any) {
        return component?.defaultValue?.type === 'data-store'
            ? component?.defaultValue?.storeId
            : undefined;
    }

    handleOtherOptionChanged($event: string) {
        this.store.dispatch(
            storeComponentAdditionalValue({
                sectionId: this.sectionId,
                componentIndex: this.componentIndex,
                additionalValue: $event,
                clonedId: this.clonedId,
            })
        );
    }

    getOtherOptions(component: any) {
        return component?.additionalValue;
    }
}

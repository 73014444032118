<div
    *ngIf="{ getAllDataStores: getAllDataStores$ | async } as state"
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <div class="flex gap10">
        <mat-form-field appearance="outline" class="full-width label-outside">
            <mat-label>{{ label }}</mat-label>
            <mat-select
                [ngModel]="
                    (
                        getSelectValue(
                            state.getAllDataStores,
                            value,
                            allowedValues
                        ) | async
                    )?.key
                "
                (ngModelChange)="onModelChanged($event)"
                [disabled]="readOnly"
                [multiple]="isMultiSelectBox"
            >
                <mat-option *ngIf="!isMultiSelectBox"></mat-option>
                <mat-option
                    *ngFor="
                        let option of getAllowedOptions(
                            state.getAllDataStores,
                            allowedValues
                        ) | async
                    "
                    [value]="option.key"
                    >{{ option.value }}</mat-option
                >
                <mat-option value="other">Other</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            appearance="outline"
            style="width: 80%"
            *ngIf="
                (
                    getSelectValue(state.getAllDataStores, value, allowedValues)
                    | async
                )?.key === 'other'
            "
            class="label-outside"
        >
            <input
                [ngModel]="value?.replace('other', '')"
                (ngModelChange)="handleOtherValueChange($event)"
                matInput
            />
        </mat-form-field>
    </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent {
    @Input()
    public label: string;

    @Input()
    public value: string;

    @Input()
    public placeholder: string;

    @Input()
    public errorMessage: string = '';

    @Input()
    public isReadOnly: boolean = false;

    @Output()
    public valueChanged: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    handleValueChanged($event: string) {
        this.valueChanged.emit($event);
    }

    getPlaceholder(value: string) {
        return `--placeholder: '${value ? '' : this.placeholder ?? ''}'`;
    }
}

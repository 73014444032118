import { Component, Inject, OnInit } from '@angular/core';
import { Section } from '../../models/section';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminState } from '../../store/admin/model';
import { SubmissionsState, Trigger } from '../../store/submission/model';
import { GetTriggersType } from '../../store/submission/submission.selector';
import { SubmissionStatus } from '../../submission.service';

export interface SaveAsModalDataResult<T> {
    confirmer: SaveAsConfirmer<T>;
    formName: string;
}

export interface SaveAsTemplateModalDataResult
    extends SaveAsModalDataResult<SaveAsTemplateModalDataResult> {
    sections: Array<Section>;
    dictionaries: AdminState['newForm']['defaultDictionaries'];
}

export interface SaveAsSubmissionModalDataResult
    extends SaveAsModalDataResult<SaveAsSubmissionModalDataResult> {
    sections: Array<Section>;
    dictionaries: SubmissionsState['dictionaries'];
    triggers: Trigger[];
    templateName: string;
    status: SubmissionStatus;
    templateId: string;
}

interface SaveAsConfirmer<T> {
    confirm: (newFormName: string, data: T) => T;
}

export class SaveAsTemplateConfirmer
    implements SaveAsConfirmer<SaveAsTemplateModalDataResult>
{
    confirm(
        newFormName: string,
        data: SaveAsTemplateModalDataResult
    ): SaveAsTemplateModalDataResult {
        return {
            confirmer: this,
            formName: newFormName,
            sections: data.sections,
            dictionaries: data.dictionaries,
        };
    }
}

export class SaveAsSubmissionConfirmer
    implements SaveAsConfirmer<SaveAsSubmissionModalDataResult>
{
    confirm(
        newFormName: string,
        data: SaveAsSubmissionModalDataResult
    ): SaveAsSubmissionModalDataResult {
        return {
            confirmer: this,
            formName: newFormName,
            sections: data.sections,
            dictionaries: data.dictionaries,
            triggers: data.triggers,
            templateName: data.templateName,
            status: data.status,
            templateId: data.templateId,
        };
    }
}

@Component({
    selector: 'app-save-as-modal',
    templateUrl: './save-as-modal.component.html',
    styleUrls: ['./save-as-modal.component.scss'],
})
export class SaveAsModalComponent<
    DataResult extends SaveAsModalDataResult<DataResult>
> implements OnInit
{
    public newFormName = '';

    public static formNamePlaceholder: string = 'Form Name';
    public static cancelButtonLabel: string = 'Cancel';
    public static okButtonLabel: string = 'OK';
    Component = SaveAsModalComponent;

    constructor(
        public dialogRef: MatDialogRef<
            SaveAsModalComponent<DataResult>,
            DataResult
        >,
        @Inject(MAT_DIALOG_DATA) public data: DataResult
    ) {}

    ngOnInit(): void {
        this.newFormName = this.data.formName;
    }

    handleCancel() {
        this.dialogRef.close();
    }

    handleOk() {
        this.dialogRef.close(
            this.data.confirmer.confirm(this.newFormName, this.data)
        );
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-new-address-step-address-lines',
    templateUrl: './step-address-lines.component.html',
    styleUrls: ['./step-address-lines.component.scss'],
})
export class StepAddressLinesComponent {
    @Input()
    public line1: string;
    @Input()
    public line2: string;
    @Input()
    public line3: string;

    @Output()
    public line1Changed: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public line2Changed: EventEmitter<string> = new EventEmitter<string>();
    @Output()
    public line3Changed: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public nextStep: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public previousStep: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    handleNextStep() {
        this.nextStep.emit();
    }

    handleLine3Changed($event: any) {
        this.line3Changed.emit($event);
    }

    handleLine2Changed($event: any) {
        this.line2Changed.emit($event);
    }

    handleLine1Changed($event: any) {
        this.line1Changed.emit($event);
    }

    handlePreviousStep() {
        this.previousStep.emit();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
    getAllDataStores,
    GetAllDataStoresType,
} from '../../store/data-store/data-store.selector';
import { DataStoreService } from '../../core/data-store.service';
import { ComponentElementsService } from '../../core/component-elements.service';
import { FormValidatorService } from '../form-validator.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { Memoize } from '../../core/decorators/memoize.decorator';
import { convertTitleCaseToKebabCase } from '../../utils/text-utils';

@Component({
    selector: 'app-analyst-form-radio-button',
    templateUrl: './analyst-form-radio-button.component.html',
    styleUrls: ['./analyst-form-radio-button.component.scss'],
})
export class AnalystFormRadioButtonComponent implements OnInit {
    @Input()
    label: string;

    @Input()
    allowedValues: string;

    @Input()
    value: string;

    @Input()
    readOnly: boolean = false;

    @Input()
    sectionId: string;

    @Input()
    clonedId: string;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter<string>();

    public getAllDataStores$: Observable<GetAllDataStoresType>;

    constructor(
        private store: Store,
        private dataStoreService: DataStoreService,
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit(): void {
        this.getAllDataStores$ = this.store.select(getAllDataStores);
    }

    handleModelChanged($event: string) {
        this.valueChanged.emit($event);
    }

    getAllowedOptions(getAllDataStores: GetAllDataStoresType) {
        return this.dataStoreService.getAllowedValues(
            this.allowedValues,
            getAllDataStores.dataStores
        );
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }

    @Memoize()
    getRadioButtonTestId(
        sectionId: string,
        clonedId: string,
        label: string,
        key: string
    ) {
        return `${clonedId ?? sectionId}_${convertTitleCaseToKebabCase(
            label
        )}_${key.replace(' ', '-')}`;
    }
}

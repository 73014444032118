import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { SubmissionDictionaryType } from '../../../../store/submission/model';
import { BaseNewValue, Value } from '../../../base-new-value';

@Component({
    selector: 'app-upsert-state',
    templateUrl: './upsert-state.component.html',
    styleUrls: ['./upsert-state.component.scss'],
})
export class UpsertStateComponent {
    @Input()
    public optionType: SubmissionDictionaryType | 'simple';

    @Input()
    public initialModel: any;

    @Output()
    public save: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public cancel: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('newComponent')
    newComponentForm: BaseNewValue<Value>;

    showConfirmButton: boolean = true;

    constructor() {}

    handleSave() {
        this.save.emit(this.newComponentForm.model);
    }

    handleShowConfirmButton($event: boolean) {
        this.showConfirmButton = $event;
    }
}

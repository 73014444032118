import { Component, Input } from '@angular/core';
import pluralize from 'pluralize';
import {
    GetFormForAnalystSelectType,
    GetTriggersType,
} from '../../../store/submission/submission.selector';
import { MenuComponent } from '../../../enums/components';
import { Section } from '../../../models/section';
import { FormValidatorService } from '../../form-validator.service';

@Component({
    selector: 'app-filler-preview',
    templateUrl: './filler-preview.component.html',
    styleUrls: ['./filler-preview.component.scss'],
})
export class FillerPreviewComponent {
    @Input()
    public submission: GetFormForAnalystSelectType;

    @Input()
    public triggers: GetTriggersType;

    public visibleSectionPreview: string = undefined;

    constructor(private formValidatorService: FormValidatorService) {}

    nestedPreviewVisible(components: any) {
        return this.getAllContainers(components)?.length > 0;
    }

    getAllContainers(sections: any) {
        return sections?.filter((component) =>
            [MenuComponent.Repeater, MenuComponent.Section].includes(
                component.component
            )
        );
    }

    isRepeater(container: any) {
        return container.component === MenuComponent.Repeater;
    }

    getContainerName(container: any) {
        return container.name;
    }

    repeaterInstancesSize(container: any) {
        if (!container.clonedSource) {
            return [{ start: 0, end: container.components.length }];
        }

        return Array(
            container.components.length / container.clonedSource.length
        )
            .fill('')
            .map((_, i) => ({
                start: i * container.clonedSource.length,
                end: (i + 1) * container.clonedSource.length,
            }));
    }

    getRepeaterInstanceName(container: any) {
        return pluralize.singular(this.getContainerName(container));
    }

    scrollToSection(container: any) {
        this.scrollTo(`${container.clonedId ?? container.id}`);
    }

    scrollToRepeaterInstance(container: any, $index: number) {
        this.scrollTo(`${container.clonedId ?? container.id}-${$index + 1}`);
    }

    private scrollTo(elementId: string) {
        document.getElementById(elementId).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        });
    }

    isSectionPreviewCollapsed(section: Section, index: number) {
        if (this.visibleSectionPreview === undefined) {
            return index !== 0;
        }

        return this.visibleSectionPreview !== section.id;
    }

    togglePreviewAccordion(section: Section) {
        this.visibleSectionPreview = section.id;
    }

    requiredFieldsAreFilledIn(components: any[]) {
        return this.formValidatorService.requiredFieldsAreFilledIn(components);
    }

    getVisiblePreviewSections(components: any) {
        return components.filter((component) => {
            if (!component.id) {
                return true;
            }
            const sectionTrigger = this.triggers.triggers.find(
                (trigger) => trigger.sectionId === component.id
            );

            if (!sectionTrigger) {
                return true;
            }

            return component?.triggerValue?.includes(sectionTrigger.value);
        });
    }
}

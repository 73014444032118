import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Memoize } from '../../../core/decorators/memoize.decorator';
import {
    FormListRow,
    TableColumn,
} from '../../../shared/table/table.component';
import { Publication } from '../../../store/publication/model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-publication-list',
    templateUrl: './publication-list.component.html',
    styleUrls: ['./publication-list.component.scss'],
})
export class PublicationListComponent implements OnInit, OnChanges {
    @Input()
    public publications: Publication[];

    @Output()
    public publicationClick: EventEmitter<string> = new EventEmitter<string>();

    public dataSource;

    private datePipe: DatePipe = new DatePipe('en-US');

    constructor() {}

    ngOnInit(): void {
        this.dataSource = this.publications.map((publication) => ({
            ...publication,
            extraData: publication.id,
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.publications?.currentValue) {
            this.dataSource = changes.publications.currentValue.map(
                (publication) => ({
                    ...publication,
                    extraData: publication.id,
                })
            );
        }
    }

    @Memoize()
    getColumns(): Array<TableColumn> {
        return [
            {
                key: 'submissionName',
                visibleName: 'Form Name',
                visibleValue: (item: Publication) => item.submissionName,
            },
            {
                key: 'additionalNotes',
                visibleName: 'Additional Notes',
                visibleValue: (item: Publication) => item.additionalNotes,
            },
            {
                key: 'createdAt',
                visibleName: 'Created On',
                visibleValue: (item: Publication) => {
                    return this.datePipe.transform(
                        item.createdAt,
                        'dd MMM YYYY HH:mm'
                    );
                },
            },
            {
                key: 'isActive',
                visibleName: 'Is Active',
                visibleValue: (item: Publication) =>
                    item.isActive ? 'YES' : 'NO',
            },
        ];
    }

    handleItemClick($event: FormListRow<string>) {
        this.publicationClick.emit($event.extraData);
    }
}

import { Injectable } from '@angular/core';
import { v4 } from 'uuid';
import * as _ from 'lodash';
import { ObjectService } from '../../core/object.service';
import { convertKebabCaseToPascalCase } from '../../utils/text-utils';
import { ComponentElementsService } from '../../core/component-elements.service';
import { SubmissionsState, Trigger } from '../../store/submission/model';
import { MenuComponent } from '../../enums/components';
import { Component, Section } from '../../models/section';

@Injectable({
    providedIn: 'root',
})
export class FormFillerService {
    constructor(
        private objectService: ObjectService,
        private componentElementService: ComponentElementsService
    ) {}

    public getDataFromSection(
        section: Section,
        triggers: SubmissionsState['triggers']
    ) {
        const traversedSection = this.traverseSection(section, triggers);
        return this.cleanupObject(traversedSection);
    }

    private traverseSection(
        section: Section,
        triggers: SubmissionsState['triggers']
    ) {
        if (section.triggerValue) {
            const triggerSection = triggers.find(
                (trigger) => trigger.sectionId === section.id
            );
            if (!triggerSection) {
                return undefined;
            }
            if (
                !section.triggerValue.includes(
                    triggerSection?.value?.toString() ?? 'false'
                )
            ) {
                return undefined;
            }
        }

        const ret = section.components.map((component) => {
            let objectValue;
            if (component.component === MenuComponent.OptionsMap) {
                objectValue = this.mapOptionsMapToSubmission(
                    component,
                    section.uniqueId1
                );
            } else if (component.component === MenuComponent.Section) {
                objectValue = this.mapSectionToSubmission(component, triggers);
            } else if (component.component === MenuComponent.Repeater) {
                objectValue = this.mapRepeaterToSubmission(component, triggers);
            } else if (component.component === MenuComponent.Toggle) {
                objectValue = this.mapToggleToSubmission(component);
            } else if (
                component.component === MenuComponent.Address ||
                component.component === MenuComponent.Contact
            ) {
                const uniqueId = convertKebabCaseToPascalCase(
                    this.componentElementService.getUniqueId(component)
                );
                if (uniqueId !== '') {
                    objectValue = {
                        key: uniqueId,
                        value: component.value ?? component.defaultValue.value,
                    };
                } else {
                    objectValue = [
                        component.value ?? component.defaultValue.value,
                    ];
                }
            } else {
                objectValue = {
                    key: `${
                        component.uniqueId1 ??
                        this.componentElementService.getUniqueId(component)
                    }_${v4()}`,
                    value: component.value,
                };
            }
            return { obj: objectValue, component: component.component };
        });
        return ret
            .filter((r) => r)
            .reduce((acc, item, index, array) => {
                const itemKey = item.obj?.key;
                const itemValue = item.obj?.value;
                if (itemValue && !itemKey.startsWith('undefined')) {
                    const keyType = itemKey.split('_')[0];
                    const elementIndex = array.findIndex((e) =>
                        e.obj.key.startsWith(keyType)
                    );
                    if (elementIndex === index) {
                        return {
                            ...acc,
                            [convertKebabCaseToPascalCase(keyType)]: {
                                itemValue,
                                component: item.component,
                            },
                        };
                    } else {
                        const existingField =
                            acc[convertKebabCaseToPascalCase(keyType)];

                        delete acc[convertKebabCaseToPascalCase(keyType)];
                        return {
                            ...acc,
                            [convertKebabCaseToPascalCase(keyType)]: [
                                ...(Array.isArray(existingField)
                                    ? existingField
                                    : [existingField]),
                                { itemValue, component: item.component },
                            ],
                        };
                    }
                }
                return {
                    ...acc,
                    ...({
                        itemValue: itemValue ?? item.obj,
                        component: item.component,
                    } ?? item),
                };
            }, {});
    }

    private mapRepeaterToSubmission(
        component: Component,
        triggers: Array<Trigger>
    ) {
        const clonedSourceLength =
            (component as any).clonedSource?.length ??
            component.components.length;
        const repeaterValues = [];
        for (
            let i = 0;
            i < component.components.length / clonedSourceLength;
            i++
        ) {
            const repeaterComponents = component.components.slice(
                i * clonedSourceLength,
                (i + 1) * clonedSourceLength
            );
            const repeaterValue = this.traverseSection(
                {
                    ...component,
                    components: repeaterComponents,
                },
                triggers
            );
            if (Object.keys(repeaterValue)[0] === '0') {
                repeaterValues.push(Object.values(repeaterValue));
            } else {
                repeaterValues.push(repeaterValue);
            }
        }

        return {
            key: `${component.uniqueId1}_${v4()}`,
            value: repeaterValues,
        };
    }

    private mapSectionToSubmission(
        component: Component,
        triggers: Array<Trigger>
    ) {
        const value = this.traverseSection(component, triggers);
        if (component.uniqueId1 && value !== undefined) {
            return { key: `${component.uniqueId1}_${v4()}`, value };
        }
        return value;
    }

    private mapOptionsMapToSubmission(
        component: Component,
        defaultKey: string
    ) {
        const defaultValues =
            this.componentElementService.getDefaultValues(component);

        const key =
            `${this.componentElementService.getUniqueId(component)}_${v4()}` ??
            `${defaultKey}_${v4()}`;

        const defaults = defaultValues.reduce((acc, current) => ({
            ...acc,
            ...current,
        }));

        const values = component?.value
            ?.map((v) => ({
                [v.name]: v.value ?? v.defaultValue,
            }))
            .reduce((acc, current) => ({
                ...acc,
                ...current,
            }));

        return {
            key,
            value: values ?? defaults,
        };
    }

    private mapToggleToSubmission(component: Component) {
        return {
            key: `${this.componentElementService.getUniqueId(
                component
            )}_${v4()}`,
            value: (!!component.value).toString(),
        };
    }

    private cleanupObject(
        traversedSection:
            | { [p: string]: { component: string; itemValue: any } }
            | { [p: string]: any[] }
            | { component: string; itemValue: any }
    ) {
        if (
            traversedSection.itemValue &&
            _.every(traversedSection.itemValue, _.isString)
        ) {
            return traversedSection.itemValue;
        }

        return Object.keys(traversedSection)
            .filter((key) => key !== 'component')
            .map((key) => {
                if (Array.isArray(traversedSection[key])) {
                    return this.cleanupSectionArray(traversedSection, key);
                }
                if (typeof traversedSection[key] === 'object') {
                    return this.cleanupSectionObject(traversedSection, key);
                }
                return traversedSection[key];
            })
            .reduce((acc, curr) => {
                return {
                    ...acc,
                    ...curr,
                };
            }, {});
    }

    private cleanupSectionObject(
        traversedSection:
            | { [p: string]: { component: string; itemValue: any } }
            | { [p: string]: any[] }
            | { component: string },
        key: string
    ) {
        if (
            [MenuComponent.Section, MenuComponent.Repeater].includes(
                traversedSection[key].component
            )
        ) {
            return {
                [key]: this.cleanupObject(traversedSection[key]).itemValue,
            };
        } else {
            return {
                [key]:
                    traversedSection[key].itemValue ??
                    this.cleanupObject(traversedSection[key]),
            };
        }
    }

    private cleanupSectionArray(
        traversedSection:
            | { [p: string]: { component: string; itemValue: any } }
            | { [p: string]: any[] }
            | { component: string },
        key: string
    ) {
        const array = traversedSection[key] as Array<any>;
        if (array[0].component === MenuComponent.OptionsMap) {
            return {
                [key]: array.reduce((acc, curr) => {
                    return {
                        ...acc,
                        ...curr?.itemValue,
                    };
                }, {}),
            };
        }

        return {
            [key]: array.flatMap((element) => {
                const elementKeys = Object.keys(element);
                if (
                    elementKeys.find((key) => key === 'itemValue') &&
                    Object.keys(element?.itemValue ?? {}).length === 0
                ) {
                    if (elementKeys.length === 2) {
                        return {};
                    }
                    const newElement = { ...element };
                    delete newElement['itemValue'];
                    return this.cleanupObject(newElement);
                }
                const traversedObject = this.cleanupObject(element);
                return traversedObject?.itemValue ?? traversedObject;
            }),
        };
    }
}

<div
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <div class="button-label">{{ label }}</div>
    <mat-button-toggle-group
        [ngModel]="value"
        (ngModelChange)="handleModelChange($event)"
        class="yes-no-button"
        [disabled]="readOnly"
    >
        <mat-button-toggle value="yes">Yes</mat-button-toggle>
        <mat-button-toggle value="no">No</mat-button-toggle>
    </mat-button-toggle-group>
</div>

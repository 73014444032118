<div class="topbar-container" *ngIf="isTopNavBarVisible">
    <div class="flex aligned-center flex-grow1">
        <div *ngIf="isBackButtonVisible">
            <button
                mat-stroked-button
                class="only-icon"
                (click)="navigateBack()"
            >
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div class="topbar-title">{{ title | async }}</div>
    </div>
    <div class="topnav-menu flex-grow1">
        <span
            class="pointer"
            [routerLink]="['/data-store']"
            [ngClass]="{ active: isLinkActive('data-store') }"
            *ngIf="isFormBuilderLinkShouldBeVisible()"
            >Data Stores</span
        >
        <span
            class="pointer"
            [routerLink]="['/form-list/form-builder']"
            [ngClass]="{ active: isLinkActive('form-builder') }"
            *ngIf="isFormBuilderLinkShouldBeVisible()"
            >Form Builder</span
        >
        <span
            class="pointer"
            [routerLink]="['/analyst/form-list']"
            [ngClass]="{ active: isLinkActive('form-filler') }"
            *ngIf="isFormFillerLinkShouldBeVisible()"
            >Form Filler</span
        >
        <span
            class="pointer"
            [routerLink]="['/publication']"
            [ngClass]="{ active: isLinkActive('publication') }"
            *ngIf="isPublicationLinkShouldBeVisible()"
            >Publications</span
        >
    </div>
</div>

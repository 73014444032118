import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import {
    NewOptionModalWindowComponent,
    NewOptionModalWindowComponentDialogData,
    NewOptionModalWindowComponentDialogResult,
} from '../../shared/new-option-modal-window/new-option-modal-window.component';
import {
    getAllDataStores,
    GetAllDataStoresType,
} from '../../store/data-store/data-store.selector';
import { DataStoreService } from '../../core/data-store.service';
import { ModalService } from '../../shared/modal.service';
import { Memoize } from '../../core/decorators/memoize.decorator';
import { ComponentElementsService } from '../../core/component-elements.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { FormValidatorService } from '../form-validator.service';

@Component({
    selector: 'app-analyst-form-select-box',
    templateUrl: './analyst-form-select-box.component.html',
    styleUrls: ['./analyst-form-select-box.component.scss'],
})
export class AnalystFormSelectBoxComponent implements OnInit {
    @Input()
    public label: string;

    @Input()
    allowedValues: string;

    @Input()
    shouldOtherOptionBeVisible: boolean;

    @Input()
    value: string;

    @Input()
    public readOnly: boolean;

    @Input()
    public isMultiSelectBox: boolean;

    @Input()
    public otherOptions: string | undefined;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    otherOptionsChanged: EventEmitter<string> = new EventEmitter<string>();

    public getAllDataStores$: Observable<GetAllDataStoresType>;

    public selectedValue: string = '';
    // public additionalOptions: string;
    private tempValue = 1;
    constructor(
        private store: Store,
        private dataStoreService: DataStoreService,
        private modalService: ModalService,
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit(): void {
        this.getAllDataStores$ = this.store.select(getAllDataStores);
        this.selectedValue = this.value;
    }

    onModelChanged($event: any) {
        if ($event === 'other') {
            this.handleChangingToOtherOption();
            this.selectedValue = `${this.tempValue++}`;
            return;
        }
        this.valueChanged.emit($event);
    }

    private handleChangingToOtherOption() {
        this.modalService.openModal<
            NewOptionModalWindowComponent,
            NewOptionModalWindowComponentDialogData,
            NewOptionModalWindowComponentDialogResult
        >(NewOptionModalWindowComponent, { type: 'simple' }, (data) => {
            if (this.otherOptions) {
                this.otherOptionsChanged.emit(
                    `${this.otherOptions},${data.value}`
                );
            } else {
                this.otherOptionsChanged.emit(data.value);
            }
        });
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }

    @Memoize()
    getAllowedOptions(
        getAllDataStores: GetAllDataStoresType,
        additionalOptions: string,
        allowedValues: string
    ) {
        return zip(
            this.dataStoreService.getAllowedValues(
                allowedValues,
                getAllDataStores.dataStores
            ),
            of(
                additionalOptions
                    ?.split(',')
                    ?.map((e) => ({ key: e, value: e })) ?? []
            )
        ).pipe(
            map(([sourceOptions, otherOptions]) => {
                return [...sourceOptions, ...otherOptions];
            })
        );
    }
}

import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { MenuComponent } from 'src/app/enums/components';
import {
    getDictionaries,
    getFormForAnalystSelect,
    GetFormForAnalystSelectType,
    getTriggers,
    GetTriggersType,
} from '../../store/submission/submission.selector';
import { SubmissionsState } from '../../store/submission/model';

@Component({
    selector: 'app-analyst-form-view',
    templateUrl: './analyst-form-view.component.html',
    styleUrls: ['./analyst-form-view.component.scss'],
})
export class AnalystFormViewComponent implements OnInit {
    public menuComponent = MenuComponent;
    public getFormForAnalystSelect$: Observable<GetFormForAnalystSelectType>;
    public getDictionaries$: Observable<SubmissionsState['dictionaries']>;
    public formId: string;
    public getTriggers$: Observable<GetTriggersType>;
    public formName: string;

    constructor(private store: Store, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.formId = this.route.snapshot.paramMap.get('id');
        this.getFormForAnalystSelect$ = this.store.select(
            getFormForAnalystSelect(this.formId)
        );
        this.getDictionaries$ = this.store.select(getDictionaries);
        this.getTriggers$ = this.store.select(getTriggers);
    }

    tracker(index, item) {
        return `${item.id}`;
    }
}

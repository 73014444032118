import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { FormApiService } from '../../form-api.service';
import * as formActions from './form-api.actions';
import { ActivatedRoute } from '@angular/router';
import { SubmissionService } from '../../submission.service';

@Injectable()
export class FormEffects {
    constructor(
        private actions$: Actions,
        private formApiService: FormApiService,
        private activatedRoute: ActivatedRoute,
        private submissionService: SubmissionService
    ) {}

    getForms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.getForms),
            exhaustMap((action) =>
                this.formApiService.getFormData(action.formName).pipe(
                    map((response: any) => {
                        return formActions.getFormsSuccess({
                            response: response.map((d) => ({
                                id: d._id,
                                formName: d.formName,
                                data: d.data,
                                createdAt: d.createdAt,
                                updatedAt: d.updatedAt,
                                dictionaries: d.dictionaries,
                                formId: this.activatedRoute.snapshot.children[0]
                                    .children[0].params.id,
                            })),
                        });
                    }),
                    catchError((error: any) =>
                        of(formActions.getFormsFailure(error))
                    )
                )
            )
        )
    );

    deleteForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.deleteForm),
            exhaustMap((action) =>
                this.formApiService.deleteForm(action.formId).pipe(
                    map((response) =>
                        formActions.deleteFormSuccess({
                            formId: action.formId,
                        })
                    )
                )
            )
        )
    );

    uploadForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.uploadFormJSON),
            exhaustMap((action) => {
                let observable: Observable<Object>;

                if (action.id) {
                    observable = this.formApiService.editFormJSON(
                        action.id,
                        action.form
                    );
                } else {
                    observable = this.formApiService.uploadFormJSON(
                        action.form
                    );
                }

                return observable.pipe(
                    map((response) =>
                        formActions.uploadFormJSONSuccess(response)
                    ),
                    catchError((error: any) =>
                        of(formActions.uploadFormJSONFailure(error))
                    )
                );
            })
        )
    );

    getCustomComponents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.getCustomComponents),
            exhaustMap((action) =>
                this.formApiService.getCustomComponentData().pipe(
                    map((response) =>
                        formActions.getCustomComponentsSuccess({ response })
                    ),
                    catchError((error: any) =>
                        of(formActions.getCustomComponentsFailure(error))
                    )
                )
            )
        )
    );

    uploadCustomComponent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.uploadCustomComponentJSON),
            exhaustMap((action) =>
                this.formApiService.uploadCustomComponentJSON(action).pipe(
                    map((response) =>
                        formActions.uploadCustomComponentJSONSuccess(response)
                    ),
                    catchError((error: any) =>
                        of(formActions.uploadCustomComponentJSONFailure(error))
                    )
                )
            )
        )
    );

    deleteCustomComponent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.deleteCustomComponentJSON),
            exhaustMap((action) =>
                this.formApiService.deleteCustomComponentJSON(action).pipe(
                    map((response) =>
                        formActions.deleteCustomComponentJSONSuccess(response)
                    ),
                    catchError((error: any) =>
                        of(formActions.deleteCustomComponentJSONFailure(error))
                    )
                )
            )
        )
    );

    //TODO: Move to submission.effects
    submitForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formActions.submitForm),
            exhaustMap((action) =>
                this.submissionService.submitForm(action).pipe(
                    map((response) => {
                        return formActions.submitFormSuccess(response);
                    })
                )
            )
        )
    );
}

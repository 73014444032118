<mat-form-field class="full-width" style="margin-top: 10px">
    <mat-label>Type value here</mat-label>
    <input matInput [(ngModel)]="model.label" />
</mat-form-field>
<div class="flex">
    <button
        mat-raised-button
        (click)="handleSave()"
        class="mr-2"
        style="flex-grow: 1"
    >
        OK
    </button>
    <button mat-raised-button (click)="handleCancel()" style="flex-grow: 1">
        Cancel
    </button>
</div>

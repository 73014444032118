import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import {
    loadAnalystFormFromSubmission,
    loadSubmissions,
} from '../store/submission/submission.action';
import { isSubmissionStateInitialized } from '../store/submission/submission.selector';
import { retrieveDataStores } from '../store/data-store/data-store.action';

@Injectable({
    providedIn: 'root',
})
export class AnalystFormViewSubmissionResolver implements Resolve<any> {
    constructor(private store: Store) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        this.store.dispatch(retrieveDataStores());
        this.store
            .select(isSubmissionStateInitialized)
            .subscribe((isInitialized) => {
                if (!isInitialized) {
                    this.store.dispatch(loadSubmissions());
                } else {
                    this.store.dispatch(
                        loadAnalystFormFromSubmission({
                            formId: route.params.id,
                        })
                    );
                }
            });
    }
}

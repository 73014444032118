import { Injectable } from '@angular/core';
import { HttpService } from './core/http.service';
import { Section } from './models/section';
import { SubmissionsState } from './store/submission/model';
import { Observable } from 'rxjs';

export type SubmissionStatus = 'draft' | 'ready-for-review' | 'approved';

export interface SubmissionApiResponse {
    formName: string;
    createdAt: Date;
    formId: string;
    submission: unknown;
    updatedAt: Date;
    _id: string;
    __v: number;
    status: SubmissionStatus;
}

interface UpsertSubmissionApiRequestBody {
    formName: string;
    formId: string;
    submission: unknown;
    inputForm: Section[];
    inputDictionaries: SubmissionsState['dictionaries'];
    templateName: string;
    status: SubmissionStatus;
}

export interface GetAllSubmissionsResponse {
    forms: Array<{
        id: string;
        formId: string;
        submission: unknown;
        inputForm: Section[];
        inputDictionaries: unknown;
        status: SubmissionStatus;
    }>;
}

@Injectable({
    providedIn: 'root',
})
export class SubmissionService {
    constructor(private httpService: HttpService) {}

    submitForm(data) {
        return this.httpService.post('submission').withBody(data).send();
    }

    getAll(): Observable<GetAllSubmissionsResponse> {
        return this.httpService
            .get<GetAllSubmissionsResponse>('submission')
            .send();
    }

    getSubmissionByStatus(status: SubmissionsState['filters']['status']) {
        return this.httpService
            .get<GetAllSubmissionsResponse>(`submission?status=${status}`)
            .send();
    }
    getSubmissionByFormId(formId: string) {
        return this.httpService.get<{
            inputForm: Section[];
            inputDictionaries: SubmissionsState['dictionaries'];
            createdAt: string;
            updatedAt: string;
        }>(`submission/${formId}`);
    }

    createSubmission(
        formName: string,
        data: unknown,
        templateId: string,
        inputForm: Section[],
        inputDictionaries: SubmissionsState['dictionaries'],
        templateName: string,
        status: SubmissionStatus
    ) {
        return this.httpService
            .post<SubmissionApiResponse, UpsertSubmissionApiRequestBody>(
                'submission'
            )
            .withBody({
                formName,
                submission: data,
                formId: templateId,
                inputForm,
                inputDictionaries,
                templateName,
                status,
            })
            .send();
    }

    updateSubmission(
        formName: string,
        data: unknown,
        formId: string,
        inputForm: Section[],
        inputDictionaries: SubmissionsState['dictionaries'],
        templateId: string,
        templateName: string,
        status: SubmissionStatus
    ) {
        return this.httpService
            .put<SubmissionApiResponse, UpsertSubmissionApiRequestBody>(
                `submission/${formId}`
            )
            .withBody({
                formName,
                submission: data,
                formId: templateId,
                inputForm,
                inputDictionaries,
                templateName,
                status,
            })
            .send();
    }

    deleteSubmission(submissionId: string) {
        return this.httpService
            .delete<SubmissionApiResponse>(`submission/${submissionId}`)
            .send();
    }
}

<div mat-dialog-content>
    <div>Do you want to save the form and publish results?</div>
    <mat-form-field appearance="outline" class="full-width label-outside my-2">
        <mat-label>Publisher additional notes:</mat-label>

        <textarea
            matInput
            [(ngModel)]="additionalNotes"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
        ></textarea>
    </mat-form-field>
    <div class="flex gap20">
        <button
            mat-raised-button
            class="full-width"
            color="primary"
            (click)="handleOkClick()"
        >
            OK
        </button>
        <button
            mat-raised-button
            class="full-width"
            color="warn"
            (click)="handleCancelClick()"
        >
            Cancel
        </button>
    </div>
</div>

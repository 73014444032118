import { Injectable } from '@angular/core';
import { HttpService } from './core/http.service';
import { Observable } from 'rxjs';
import { AdminState } from './store/admin/model';

@Injectable({
    providedIn: 'root',
})
export class FormApiService {
    constructor(private httpService: HttpService) {}

    getFormData(formName: string): Observable<unknown[]> {
        return this.httpService
            .get<unknown[]>(`forms${formName ? `?formName=${formName}` : ''}`)
            .send();
    }

    uploadFormJSON(form) {
        return this.httpService.post('forms').withBody(form).send();
    }

    uploadForm2(formName, data, dictionaries) {
        return this.httpService
            .post<
                any,
                {
                    formName: string;
                    data: any;
                    dictionaries: AdminState['newForm']['defaultDictionaries'];
                }
            >('forms/v2')
            .withBody({ formName, data, dictionaries })
            .send();
    }

    updateFormV2(formName, data, id, dictionaries) {
        return this.httpService
            .put<
                any,
                {
                    formName: string;
                    data: any;
                    dictionaries: AdminState['newForm']['defaultDictionaries'];
                }
            >(`forms/v2/${id}`)
            .withBody({ formName, data, dictionaries })
            .send();
    }

    editFormJSON(id: string, form: any) {
        return this.httpService.put(`forms/${id}`).withBody(form).send();
    }

    deleteForm(id: string) {
        return this.httpService.delete(`forms/${id}`).send();
    }

    deleteFormV2(id: string) {
        return this.httpService.delete(`forms/v2/${id}`).send();
    }

    getCustomComponentData() {
        return this.httpService.get('customs').send();
    }

    uploadCustomComponentJSON(form) {
        return this.httpService.post('customs').withBody(form).send();
    }

    deleteCustomComponentJSON(component) {
        return this.httpService.delete(`customs/${component._id}`).send();
    }

    createCustomComponent(componentName: string, componentData: any[]) {
        return this.httpService
            .post('components')
            .withBody({
                componentName,
                componentData,
            })
            .send();
    }
}

import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { v4 } from 'uuid';

export type StorageKeyType = 'token' | 'remember-me' | 'error';
export type KeepTime = 'forever' | 'temporary';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private cookieService: CookieService) {}

    addValue(
        value: any,
        key: StorageKeyType,
        keep: KeepTime = 'temporary'
    ): void {
        const valueToAdd = JSON.stringify(value);
        if (key === 'error') {
            this.addPrimitiveValue(
                valueToAdd,
                `${key}-${v4()}` as StorageKeyType,
                keep
            );
        } else {
            this.addPrimitiveValue(valueToAdd, key, keep);
        }
    }

    addPrimitiveValue(
        value: string,
        key: StorageKeyType,
        keep: KeepTime = 'temporary'
    ): void {
        if (keep === 'temporary') {
            sessionStorage.setItem(key, value);
            return;
        }

        this.cookieService.set(key, value, { expires: 7 });
    }

    getPrimitiveValue(
        key: StorageKeyType,
        keep: KeepTime = 'temporary'
    ): string {
        if (keep === 'temporary') {
            return sessionStorage.getItem(key) ?? '';
        }

        return this.cookieService.get(key);
    }

    getValue<T>(key: StorageKeyType, keep: KeepTime = 'temporary'): T | null {
        const stringValue = this.getPrimitiveValue(key, keep);

        if (stringValue) {
            return JSON.parse(stringValue) as T;
        }
        return null;
    }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormApiState, Forms } from './form-api.reducer';

interface GetTemplateInformationSelect {
    formName: string;
}

export interface GetFormsSelect {
    forms: Forms;
}

export const getformState = createFeatureSelector<FormApiState>('forms');
const getForms = (state: FormApiState) => {
    return {
        forms: state.forms,
        isLoading: state.isLoading,
        isLoadingSuccess: state.isLoadingSuccess,
    };
};
const getCurrentForm = (state: FormApiState) => {
    return state.currentForm;
};
const getUploadJSONStatus = (state: FormApiState) => {
    return {
        isUploadingJSON: state.isUploadingJSON,
        isUploadingJSONSuccess: state.isUploadingJSONSuccess,
        isUploadingJSONFailure: state.isUploadingJSONFailure,
        uploadJSONResult: state.uploadJSONResult,
    };
};
const getCustomComponents = (state: FormApiState) => {
    return {
        customComponents: state.customComponents,
        customComponentError: state.customComponentError,
        isCustomComponentLoading: state.isCustomComponentLoading,
        isCustomComponentLoadingSuccess: state.isCustomComponentLoadingSuccess,
        isCustomComponentLoadingFailure: state.isCustomComponentLoadingFailure,
    };
};
const deleteCustomComponent = (state: FormApiState) => {
    return {
        isCustomComponentDeleteSuccess: state.isCustomComponentDeleteSuccess,
        isCustomComponentDeleteFailure: state.isCustomComponentDeleteFailure,
    };
};
const getPrepopulatedData = (state: FormApiState) => {
    return {
        prepopulatedData: state.prepopulatedData,
    };
};
const getEditedForm = (state: FormApiState) => ({
    editedForm: state.editedForm,
});
export const getFormsSelect = createSelector<any, any[], GetFormsSelect>(
    getformState,
    getForms
);
export const getCurrentFormSelect = createSelector(
    getformState,
    getCurrentForm
);
export const getUploadJSONStatusSelect = createSelector(
    getformState,
    getUploadJSONStatus
);
export const getCustomComponentsSelect = createSelector(
    getformState,
    getCustomComponents
);
export const deleteCustomComponentSelect = createSelector(
    getformState,
    deleteCustomComponent
);
export const getPrepopulatedDataSelect = createSelector(
    getformState,
    getPrepopulatedData
);
export const getEditedFormSelect = createSelector(getformState, getEditedForm);

export const getTemplateInformation = (id: string) =>
    createSelector<any, any[], GetTemplateInformationSelect>(
        getformState,
        (state: FormApiState) => {
            return {
                formName: state?.forms?.find((form) => form._id === id)
                    ?.formName,
            };
        }
    );

export const isFormApiStateInitialized = createSelector(
    getformState,
    (state) => {
        return state.isInitialized;
    }
);

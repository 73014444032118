import { Action, createReducer, on } from '@ngrx/store';
import { DataStoreState } from './model';
import { retrieveDataStoresSuccess } from './data-store.action';

const initialState: DataStoreState = {
    dataStores: [],
};

const createDataStoreReducer = createReducer(
    initialState,
    on(retrieveDataStoresSuccess, (state, action) => {
        return { ...state, dataStores: action.dataStores };
    })
);

export function dataStoreReducer(
    state: DataStoreState = initialState,
    action: Action
) {
    return createDataStoreReducer(state, action);
}

<span>Specify Zip Code</span>
<mat-form-field class="full-width">
    <mat-label>Zip</mat-label>
    <input
        matInput
        [textMask]="{ mask: mask }"
        [ngModel]="zipCode"
        (ngModelChange)="handleZipCodeChanged($event)"
    />
</mat-form-field>

<div class="flex">
    <button
        mat-raised-button
        (click)="handlePreviousStep()"
        style="flex-grow: 1"
    >
        Previous
    </button>
    <button
        mat-raised-button
        class="ml-1"
        (click)="handleNextStep()"
        style="flex-grow: 1"
    >
        Next
    </button>
</div>

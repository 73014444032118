import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseNewValue, Value } from '../../../../base-new-value';

class Address extends Value {
    constructor(
        label: string,
        public line1: string,
        public line2: string,
        public line3: string,
        public city: string,
        public region: string,
        public zipCode: string,
        public country: string
    ) {
        super(label);
    }
}

type Step =
    | 'stepLabel'
    | 'stepCountry'
    | 'stepRegionUS'
    | 'stepRegionCA'
    | 'stepRegionGeneral'
    | 'stepZipCode'
    | 'stepCity'
    | 'stepAddressLines';

@Component({
    selector: 'app-new-address',
    templateUrl: './new-address.component.html',
    styleUrls: ['./new-address.component.scss'],
})
export class NewAddressComponent
    extends BaseNewValue<Address>
    implements OnInit
{
    @Input()
    initialModel: any;

    @Output()
    public save: EventEmitter<any> = new EventEmitter<any>();

    public currentStep: Step = 'stepLabel';

    ngOnInit(): void {
        this.model =
            this.initialModel ?? new Address('', '', '', '', '', '', '', '');
    }

    handleLabelChanged($event: string) {
        this.model.label = $event;
    }

    moveToStep(step: Step) {
        this.currentStep = step;
    }

    handleCountryChanged($event: string) {
        this.model.country = $event;
    }

    moveToStepRegion() {
        const mappings: Array<{ country: string; nextStep: () => Step }> = [
            { country: 'US', nextStep: () => 'stepRegionUS' },
            { country: 'CA', nextStep: () => 'stepRegionCA' },
        ];
        this.currentStep =
            mappings
                .find((mapper) => mapper.country === this.model.country)
                ?.nextStep() ?? 'stepRegionGeneral';
    }

    handleRegionChanged($event: string) {
        this.model.region = $event;
    }

    handleZipCodeChanged($event: string) {
        this.model.zipCode = $event;
    }

    handleCityChanged($event: string) {
        this.model.city = $event;
    }

    handleLine3Changed($event: string) {
        this.model.line3 = $event;
    }

    handleLine2Changed($event: string) {
        this.model.line2 = $event;
    }

    handleLine1Changed($event: string) {
        this.model.line1 = $event;
    }

    handleSave() {
        this.save.emit();
    }

    getZipCodeMask(): any[] {
        if (this.model.country === 'US') {
            return [/\d/, /\d/, /\d/, /\d/, /\d/];
        }
        if (this.model.country === 'CA') {
            return [/[A-Z]/, /\d/, /[A-Z]/, ' ', /\d/, /[A-Z]/, /\d/];
        }

        return undefined;
    }
}

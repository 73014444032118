<div
    *ngIf="(getDictionaryByType$ | async).dictionary as dictionary"
    [ngClass]="{
        'component-required': isComponentRequired(),
        'component-valid': isComponentValid()
    }"
>
    <div class="flex column">
        <div class="flex aligned-center gap10">
            <mat-form-field
                appearance="outline"
                class="full-width label-outside"
            >
                <mat-label>{{ label }}</mat-label>
                <mat-select
                    [disabled]="readOnly"
                    [ngModel]="value"
                    (ngModelChange)="handleModelChange($event, dictionary)"
                >
                    <mat-option
                        *ngFor="let item of getAllOptions(dictionary)"
                        [value]="item.id"
                    >
                        {{ item.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-mini-fab color="primary" (click)="addNewContact()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div *ngIf="selectedContact?.model" class="flex column">
            <div class="flex gap10">
                <mat-form-field
                    appearance="outline"
                    class="full-width flex-grow1 label-outside"
                >
                    <mat-label>Name</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="selectedContact.model.name"
                    />
                </mat-form-field>
                <mat-form-field appearance="outline" class="label-outside">
                    <mat-label>Shipping Type</mat-label>
                    <input
                        matInput
                        disabled="true"
                        [value]="getContactType()"
                    />
                </mat-form-field>
            </div>
            <div class="flex gap10">
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>Phone</mat-label>
                    <input
                        matInput
                        [value]="selectedContact.model.phone"
                        disabled="true"
                    />
                </mat-form-field>
                <mat-form-field
                    appearance="outline"
                    class="flex-grow1 full-width label-outside"
                >
                    <mat-label>Email</mat-label>
                    <input
                        matInput
                        [value]="selectedContact.model.email"
                        disabled="true"
                    />
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

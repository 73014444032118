import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    getDictionaryByType,
    GetDictionaryByTypeType,
} from '../../store/submission/submission.selector';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
    loadDictionary,
    updateDictionary,
} from '../../store/submission/submission.action';
import { Address } from '../../models/address';
import {
    NewOptionModalWindowComponent,
    NewOptionModalWindowComponentDialogData,
    NewOptionModalWindowComponentDialogResult,
} from '../../shared/new-option-modal-window/new-option-modal-window.component';
import { DefaultDictionary } from '../../store/admin/model';
import { first } from 'rxjs/operators';
import { ComponentElementsService } from '../../core/component-elements.service';
import { EditComponentModel } from '../../models/db-schema.model';
import { FormValidatorService } from '../form-validator.service';

@Component({
    selector: 'app-analyst-form-address',
    templateUrl: './analyst-form-address.component.html',
    styleUrls: ['./analyst-form-address.component.scss'],
})
export class AnalystFormAddressComponent implements OnInit {
    @Input()
    public label: string;

    @Input()
    public value: string;

    @Input()
    public readOnly: boolean;

    @Input()
    public uniqueId: string;

    @Input()
    public dictionaryId: string;

    @Input()
    public component: EditComponentModel;

    @Output()
    valueChanged: EventEmitter<string> = new EventEmitter<string>();

    public getDictionaryByType$: Observable<GetDictionaryByTypeType<Address>>;

    public selectedAddress: DefaultDictionary<Address>[0];

    private subscription: Subscription;

    constructor(
        private store: Store,
        private matDialog: MatDialog,
        private componentElementsService: ComponentElementsService,
        private formValidatorService: FormValidatorService
    ) {}

    ngOnInit(): void {
        if (this.dictionaryId) {
            this.store.dispatch(
                loadDictionary({
                    dictionaryExternalId: this.dictionaryId,
                    dictionaryId: this.uniqueId,
                    dictionaryType: 'address',
                })
            );
        }
        this.subscription = this.store
            .select(getDictionaryByType('address'))
            .pipe(first())
            .subscribe((data) => {
                const addressData = data as GetDictionaryByTypeType<Address>;
                if (this.value) {
                    this.selectedAddress = this.getAllOptions(
                        addressData.dictionary
                    ).find((address) => address.id === this.value);
                }
            });
        this.getDictionaryByType$ = this.store.select(
            getDictionaryByType('address')
        );
    }

    addNewAddress() {
        const dialogRef = this.matDialog.open<
            NewOptionModalWindowComponent,
            NewOptionModalWindowComponentDialogData,
            NewOptionModalWindowComponentDialogResult
        >(NewOptionModalWindowComponent, {
            data: {
                type: 'address',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.store.dispatch(
                    updateDictionary({
                        dictionaryType: 'address',
                        value: result.model,
                        label: result.value,
                        dictionaryUniqueIdType: this.uniqueId,
                    })
                );
            }
        });
    }

    handleModelChange($event: string, dictionary: DefaultDictionary<Address>) {
        this.selectedAddress = this.getAllOptions(dictionary).find(
            (address) => address.id === $event
        );
        this.valueChanged.emit($event);
    }

    getAllOptions(dictionary: DefaultDictionary<Address>) {
        return dictionary.filter(
            (item) => item.dictionaryUniqueIdType === this.uniqueId
        );
    }

    getStreetAddress() {
        return `${this.selectedAddress.model?.line1} ${this.selectedAddress.model?.line2} ${this.selectedAddress.model?.line3}`;
    }

    isComponentRequired() {
        return this.componentElementsService.getConstraint(
            this.component,
            'Required'
        );
    }

    isComponentValid() {
        return this.formValidatorService.requiredFieldsAreFilledIn([
            this.component,
        ]);
    }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store/root.reducer';
import { FormEffects } from './store/form-api/form-api.effects';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { ToolBarComponent } from './side-navbar/tool-bar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TopbarComponent } from './topbar/topbar.component';
import { CoreModule } from './core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AdminEffect } from './store/admin/admin.effect';
import { SharedModule } from './shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { PrepopulatedDataEffect } from './store/prepopulated-data/prepopulated-data.effect';
import { MatCardModule } from '@angular/material/card';
import { TextMaskModule } from 'angular2-text-mask';
import { AnalystFormTemplateResolver } from './resolvers/analyst-form-view-component-resolver.service';
import { CustomComponentsEffect } from './store/custom-components/custom-components.effect';
import { CustomComponentBuilderResolver } from './resolvers/custom-component-builder-resolver';
import { NewFormResolver } from './resolvers/new-form-resolver';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FormFillerModule } from './form-filler/form-filler.module';
import { SubmissionEffect } from './store/submission/submission.effect';
import { DataStoreEffect } from './store/data-store/data-store.effect';
import { PublicationEffect } from './store/publication/publication.effect';
import { PublicationModule } from './publication/publication.module';

@NgModule({
    declarations: [
        AppComponent,
        MainLayoutComponent,
        ToolBarComponent,
        TopbarComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatGridListModule,
        MatListModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot([
            FormEffects,
            AdminEffect,
            PrepopulatedDataEffect,
            CustomComponentsEffect,
            SubmissionEffect,
            DataStoreEffect,
            PublicationEffect,
        ]),
        MatToolbarModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        CoreModule,
        MatButtonModule,
        MatIconModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot(),
        SharedModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatCardModule,
        TextMaskModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormFillerModule,
        PublicationModule,
    ],
    providers: [
        AnalystFormTemplateResolver,
        CustomComponentBuilderResolver,
        NewFormResolver,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
